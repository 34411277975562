import React from "react";
import { Modal, Box, Typography } from "@mui/material";

const NewUser = ({ open, handleClose }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          width: 400,
          bgcolor: "background.paper",
          borderRadius: "15px",
          boxShadow: 24,
          p: 3,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Typography fontSize="20px">
          Please contact your admin for access.
        </Typography>
      </Box>
    </Modal>
  );
};

export default NewUser;
