import React, { useState } from "react";
import { Modal, Box, Typography, TextField, Button } from "@mui/material";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, firestoredb } from "../../firebaseConfig";
import { collection, doc, setDoc } from "firebase/firestore";

const SignUpModal = ({ open, handleClose }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);

  const handleEmailChange = (value) => {
    setEmail(value);
    setIsEmailValid(value.endsWith("@megaworldcorp.com"));
  };

  const handlePasswordChange = (value) => {
    setPassword(value);
    setIsPasswordMatch(value === confirmPassword);
  };

  const handleRePasswordChange = (value) => {
    setConfirmPassword(value);
    setIsPasswordMatch(value === password);
  };

  const handleCreate = async () => {
    if (!isEmailValid) {
      setError("Please use your company email.");
      return;
    } else if (!isPasswordMatch) {
      setError("Passwords do not match");
      return;
    }

    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      );
      const user = userCredential.user;
      // Save user details to Firestore
      const displayName = `${firstName} ${lastName}`;
      const userDocRef = doc(collection(firestoredb, "users"), user.uid);
      await setDoc(userDocRef, {
        uid: user.uid,
        email: user.email,
        firstName: firstName,
        lastName: lastName,
        displayName: displayName,
        role: "User (New)",
        // Add more user details as needed
      });

      // Reset form fields and error state
      setFirstName("");
      setLastName("");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setError("");

      // Close the modal
      handleClose();
    } catch (error) {
      console.error("Error creating user:", error.message);
      setError("Error creating user. Please try again later.");
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        position="absolute"
        top="25%"
        left="35%"
        transform="translate(-50%, -50%)"
        maxWidth="500px"
        bgcolor="background.paper"
        boxShadow={24}
        borderRadius="15px"
        p="5px"
      >
        <Box borderBottom="0.5px solid #000" padding="15px">
          <Typography variant="h3" fontWeight={600} align="center">
            Create Your Account
          </Typography>
        </Box>

        <Box p="20px">
          <form>
            <TextField
              variant="outlined"
              label="First Name"
              value={firstName}
              required
              onChange={(e) => setFirstName(e.target.value)}
              sx={{ width: "47%", mb: "20px" }}
            />
            <TextField
              variant="outlined"
              label="Last Name"
              value={lastName}
              required
              onChange={(e) => setLastName(e.target.value)}
              sx={{ width: "47%", mb: "20px", ml: "6%" }}
            />
            <TextField
              variant="outlined"
              fullWidth
              label="E-mail Address"
              value={email}
              required
              onChange={(e) => handleEmailChange(e.target.value)}
              onBlur={(e) => handleEmailChange(e.target.value)}
              error={!isEmailValid && email !== ""}
              helperText={
                !isEmailValid && email !== ""
                  ? "Please use your company e-mail"
                  : ""
              }
              sx={{ mb: "20px" }}
            />
            <TextField
              variant="outlined"
              fullWidth
              type="password"
              label="Password"
              required
              value={password}
              onChange={(e) => handlePasswordChange(e.target.value)}
              onBlur={(e) => handlePasswordChange(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              type="password"
              value={confirmPassword}
              label="Re-type Password"
              required
              onChange={(e) => handleRePasswordChange(e.target.value)}
              onBlur={(e) => handleRePasswordChange(e.target.value)}
              error={
                !isPasswordMatch && password !== "" && confirmPassword !== ""
              }
              helperText={
                password !== "" && confirmPassword !== "" && !isPasswordMatch
                  ? "Passwords do not match"
                  : ""
              }
            />
            {error && (
              <Typography color="error" variant="caption">
                {error}
              </Typography>
            )}
            <Box textAlign="center">
              <Button
                type="button"
                variant="contained"
                onClick={handleCreate}
                style={{ backgroundColor: "#036c8c" }}
                sx={{ width: "50%", mt: "30px", mb: "10px" }}
              >
                Create New Account
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  );
};

export default SignUpModal;
