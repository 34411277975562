import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import SecondTopBar from "../SecondTopBar";
import { firestoredb } from "../../firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import { PartnerMapping } from "../../Data/PartnerMapping";

import GiteIcon from '@mui/icons-material/Gite';
import HardwareIcon from '@mui/icons-material/Hardware';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import BackpackIcon from '@mui/icons-material/Backpack';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import PedalBikeIcon from '@mui/icons-material/PedalBike';
import WheelchairPickupIcon from '@mui/icons-material/WheelchairPickup';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import TakeoutDiningIcon from '@mui/icons-material/TakeoutDining';
import RiceBowlIcon from '@mui/icons-material/RiceBowl';
import SanitizerIcon from '@mui/icons-material/Sanitizer';
import SnowshoeingIcon from '@mui/icons-material/Snowshoeing';
import RedeemIcon from '@mui/icons-material/Redeem';

const DashPartnership = () => {
  //Three Main
  const [totalpartners, settotalpartners] = useState(0);
  const [totalbeneficiaries, settotalbeneficiaries] = useState(0);
  const [totalfinancialsupports, settotalfinancialsupports] = useState(0.0);
  //First set of Box
  const [classroombuilt, setclassroombuilt] = useState(0);
  const [classroomrefurbished, setclassroomrefurbished] = useState(0);
  const [librariesbuilt, setlibrariesbuilt] = useState(0);
  const [books, setbooks] = useState("");
  const [schoolsupplies, setschoolsupplies] = useState("");
//second set of Box
  const [housesbuilt, sethousebuilt] = useState(0);
  const [livelihoodbeneficiaries, setlivelihoodbeneficiaries] = useState(0);
  const [bikes, setbikes] = useState(0);
  const [wheelchairs, setwheelchairs] = useState(0);
  const [solarlamps, setsolarlamps] = useState(0);
  //third set of Box
  const [reliefpackages, setreliefpackages] = useState(0);
  const [kilosofrice, setkilosofrice] = useState(0);
  const [hygienekits, sethygienekits] = useState(0);
  const [pairofslippers, setpairofslippers] = useState(0);
  const [christmasgifts, setchristmasgifts] = useState(0);
  //filter
  const [yearfrom, setYearfrom] = useState("");
  const [yearto, setYearto] = useState("");

  useEffect(() => {
    const getEventData = async () => {
      try {
        const collections = [
          { collectionName: 'PartnershipActivities', ref: collection(firestoredb, 'PartnershipActivities') },
          { collectionName: 'PartnershipDonation', ref: collection(firestoredb, 'PartnershipDonation') },
          { collectionName: 'PartnershipLivelihood', ref: collection(firestoredb, 'PartnershipLivelihood') },
        ];
        const volunteerismCollection = collection(firestoredb,"VolunteerismActivities",);
        const snapshotVolunteerism = await getDocs(volunteerismCollection);
        const snapshots = await Promise.all(collections.map(c => getDocs(c.ref)));

        let totalPartners = 0;
        let totalBeneficiaries = 0;
        let totalFinancial = 0;
        let totalClassroomBuilt = 0;
        let totalClassroomRefurbished = 0;
        let totalLibrariesBuilt = 0;
        let totalBooks = 0;
        let totalSchoolSupplies = 0;
        let totalHouses = 0;
        let totalLivelihoodBeneficiaries = 0;
        let totalBikes = 0;
        let totalWheelchairs = 0;
        let totalSolarLamps = 0;
        let totalRelief = 0;
        let totalKilos = 0;
        let totalHygiene = 0;
        let totalSlippers = 0;
        let totalChristmasGifts = 0;

        let uniquePartners = new Set();
        snapshotVolunteerism.forEach((doc)=>{
          const docVol = doc.data();
          const year = docVol.year;
          if (
            (!yearfrom || yearfrom === "" || year >= yearfrom) &&
            (!yearto || yearto === "" || year <= yearto)
          ) {
            docVol.locationFields.forEach((locationField) => {
              //Activities
              if (locationField.CAgifts) {
                const CAgiftsnumber= parseInt(locationField.CAgifts || 0);
                totalChristmasGifts+= CAgiftsnumber;
              }})
          }
        })
        const processSnapshot = (snapshot, collectionName) => {
          snapshot.forEach((doc) => {
            const data = doc.data();
            const year = data.year;
            if (
              (!yearfrom || yearfrom === '' || year >= yearfrom) &&
              (!yearto || yearto === '' || year <= yearto)
            ) {
              data.locationFields.forEach((locationField) => {
                if (locationField.partners) {
                  locationField.partners.forEach((partner) => {
                    if (PartnerMapping.includes(partner)) {
                      uniquePartners.add(partner);
                    }
                  });
                }
                const addCount = (field) => parseInt(locationField[field] || 0);

                if (collectionName === 'PartnershipLivelihood') {
                  totalLivelihoodBeneficiaries += addCount('beneficiaries');
                }
                totalBeneficiaries += addCount('beneficiaries');
                totalFinancial += addCount('expenses');
                totalClassroomBuilt += addCount('ClassroomsBuilt');
                totalClassroomRefurbished += addCount('ClassroomsRefurb');
                totalLibrariesBuilt += addCount('LibrariesBuilt');
                totalBooks += addCount('DonatedBooks');
                totalSchoolSupplies += addCount('SuppliesDistrib');
                totalHouses += addCount('HousesBuilt');
                totalBikes += addCount('BikesDistrib');
                totalWheelchairs += addCount('WheelchairDonated');
                totalSolarLamps += addCount('SolarLamp');
                totalRelief += addCount('ROtotalRelief');
                totalKilos += addCount('RiceDonated');
                totalHygiene += addCount('ROHygiene');
                totalSlippers += addCount('PairSlippers');
                totalChristmasGifts += addCount('packed');
              });
            }
          });
        };

        snapshots.forEach((snapshot, index) => processSnapshot(snapshot, collections[index].collectionName));

        totalPartners = uniquePartners.size;
        settotalpartners(totalPartners);
        settotalbeneficiaries(totalBeneficiaries);
        settotalfinancialsupports(totalFinancial);
        setclassroombuilt(totalClassroomBuilt);
        setclassroomrefurbished(totalClassroomRefurbished);
        setlibrariesbuilt(totalLibrariesBuilt);
        setbooks(totalBooks);
        setschoolsupplies(totalSchoolSupplies);
        sethousebuilt(totalHouses);
        setlivelihoodbeneficiaries(totalLivelihoodBeneficiaries);
        setbikes(totalBikes);
        setwheelchairs(totalWheelchairs);
        setsolarlamps(totalSolarLamps);
        setreliefpackages(totalRelief);
        setkilosofrice(totalKilos);
        sethygienekits(totalHygiene);
        setpairofslippers(totalSlippers);
        setchristmasgifts(totalChristmasGifts);
      } catch (error) {
        console.error('Error fetching Volunteerism Activities:', error);
      }
    };

    // Call the function to fetch data
    getEventData();
  }, [yearfrom, yearto]);
  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear(); // Get the current year
    const startYear = 1997; // Start year
    const yearOptions = [];

    // Loop from the start year to the current year
    for (let i = currentYear; i >= startYear; i--) {
      yearOptions.push(i.toString()); // Push each year as a string to the options array
    }

    return yearOptions; // Return the array of year options
  };
  const handleYearFromChange = (e) => {
    const selectedYearFrom = e.target.value;

    // Set yearfrom based on the selected value
    setYearfrom(selectedYearFrom);

    // If "Overall" is selected, clear both yearfrom and yearto
    if (selectedYearFrom === "") {
      setYearto(""); // Clear the value in yearto
    } else if (yearto && selectedYearFrom > yearto) {
      // If the selected value of "Year To" is smaller than "Year From", update "Year To" to match "Year From"
      setYearto(selectedYearFrom);
    }
  };

  // Event handler for "Year To" change
  const handleYearToChange = (e) => {
    // If the selected value of "Year To" is smaller than "Year From", prevent updating "Year To"
    setYearto(e.target.value);
    if (yearfrom === "") {
      setYearto("2024");
      return;
    }
    if (yearfrom && e.target.value < yearfrom) {
      return;
    }
  };

 

  return (
    <Box>
      <SecondTopBar />
      <Box
        borderTop="15px solid #4B6856"
        maxHeight="750px"
        height="calc(100vh - 25px)"
        width="auto%"
      >
        <Box
          marginRight="25px"
          marginTop="15px"
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <FormControl style={{ width: "100px", height: "25px" }}>
            <InputLabel>Year From:</InputLabel>
            <Select
              style={{ height: "35px" }}
              label="Year From"
              value={yearfrom}
              MenuProps={{
                MenuListProps: {
                  style: {
                    maxHeight: 150, // Set the maximum height of the dropdown
                  },
                },
              }}
              onChange={handleYearFromChange}
            >
              <MenuItem value="">Overall</MenuItem>
              {generateYearOptions().map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {yearfrom && yearfrom !== "" && (
            <FormControl
              style={{ marginLeft: "20px", width: "100px", height: "25px" }}
            >
              <InputLabel>Year to</InputLabel>
              <Select
                style={{ height: "35px" }}
                label="Year to"
                value={yearto}
                MenuProps={{
                  MenuListProps: {
                    style: {
                      maxHeight: 150, // Set the maximum height of the dropdown
                    },
                  },
                }}
                onChange={handleYearToChange}
              >
                {generateYearOptions().map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Box>
        
          <Box margin="20px" display="flex">
          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #174A55"
            maxWidth=" 600px"
            width="100%"
            height="120px"
            backgroundColor="#174A55"
            borderRadius="15px"
            paddingLeft="10px"
            marginRight="25px"
            display="flex"
            flexDirection="column"
            justifyContent="center" // Center items vertically
          >
            <Typography
              fontSize="45px"
              fontWeight="900"
              color="#f6f6f6"
              marginBottom="-15px"
            >
              {totalpartners.toLocaleString()}
            </Typography>
            <Typography fontSize="25px" fontWeight="700" color="#f6f6f6">
               partner institutions
            </Typography>
          </Box>
          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #4B6856"
            maxWidth=" 600px"
            width="100%"
            height="120px"
            backgroundColor="#174A55"
            borderRadius="15px"
            paddingLeft="10px"
            marginRight="25px"
            display="flex"
            flexDirection="column"
            justifyContent="center" // Center items vertically
          >
            <Typography
              fontSize="45px"
              fontWeight="900"
              color="#f6f6f6"
              marginBottom="-15px"
            >
              {totalbeneficiaries.toLocaleString()}
            </Typography>
            <Typography fontSize="25px" fontWeight="700" color="#f6f6f6">
              beneficiaries
            </Typography>
          </Box>
          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #174A55"
            maxWidth=" 600px"
            width="100%"
            height="120px"
            backgroundColor="#174A55"
            borderRadius="15px"
            paddingLeft="10px"
            paddingBottom="0px"
            display="flex"
            flexDirection="column"
            justifyContent="center" // Center items vertically
          >
            <Typography
              fontSize="45px"
              fontWeight="900"
              color="#f6f6f6"
              marginBottom="-15px"
            >
            <span>P </span>
              {totalfinancialsupports.toLocaleString()}
            </Typography>
            <Typography fontSize="25px" fontWeight="700" color="#f6f6f6">
              financial support
            </Typography>
          </Box>
        </Box>
        {/* Main Boxes*/}
        <Box margin="20px" mt ="30px" display="flex">
        <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #4B6856"
            maxWidth=" 400px"
            width="100%"
            height="120px"
            backgroundColor="#4B6856"
            borderRadius="15px"
            paddingLeft="10px"
            paddingBottom="0px"
            marginRight="25px"
            display="flex"
            flexDirection="column"
            justifyContent="center" // Center items vertically
          >
            <Typography
              fontSize="45px"
              fontWeight="900"
              color="#f6f6f6"
              marginBottom="-15px"
            >
              {classroombuilt.toLocaleString()}
            </Typography>
            <Typography fontSize="25px" fontWeight="700" color="#f6f6f6">
              <span style={{ display: 'flex', alignItems: 'center' }}>
                  <GiteIcon fontSize="large" sx={{ verticalAlign: "middle",marginRight:"5px"}} /> classrooms built
              </span>
          </Typography>

          </Box>
          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #58827E"
            maxWidth=" 400px"
            width="100%"
            height="120px"
            backgroundColor="#4B6856"                                                                 
            borderRadius="15px"
            paddingLeft="10px"
            paddingBottom="0px"
            marginRight="25px"
            display="flex"
            flexDirection="column"
            justifyContent="center" // Center items vertically
          >
            <Typography
              fontSize="45px"
              fontWeight="900"
              color="#f6f6f6"
              marginBottom="-15px"
            >
              {classroomrefurbished.toLocaleString()}
            </Typography>
            <Typography fontSize="24px" fontWeight="700" color="#f6f6f6">
              <span style={{ display: 'flex', alignItems: 'center' }}>
                  <HardwareIcon fontSize="large" sx={{ verticalAlign: "middle"}} /> classrooms refurbished
              </span>
          </Typography>
            
          </Box>
          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #58827E"
            maxWidth=" 400px"
            width="100%"
            height="120px"
            backgroundColor="#4B6856"
            borderRadius="15px"
            paddingLeft="10px"
            paddingBottom="0px"
            marginRight="25px"
            display="flex"
            flexDirection="column"
            justifyContent="center" // Center items vertically
          >
            <Typography
              fontSize="45px"
              fontWeight="900"
              color="#f6f6f6"
              marginBottom="-15px"
            >
              {librariesbuilt.toLocaleString()}
            </Typography>
            <Typography fontSize="25px" fontWeight="700" color="#f6f6f6">
              <span style={{ display: 'flex', alignItems: 'center' }}>
                  <LocalLibraryIcon fontSize="large" sx={{ verticalAlign: "middle", marginRight: "5px"}} /> libraries built
              </span>
          </Typography>
          </Box>
          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #58827E"
            maxWidth=" 400px"
            width="100%"
            height="120px"
            backgroundColor="#4B6856"
            borderRadius="15px"
            paddingLeft="10px"
            paddingBottom="0px"
            marginRight="25px"
            display="flex"
            flexDirection="column"
            justifyContent="center" // Center items vertically
          >
            <Typography
              fontSize="45px"
              fontWeight="900"
              color="#f6f6f6"
              marginBottom="-15px"
            >
              {books.toLocaleString()}
            </Typography>
            <Typography fontSize="25px" fontWeight="700" color="#f6f6f6">
              <span style={{ display: 'flex', alignItems: 'center' }}>
                  <AutoStoriesIcon fontSize="large" sx={{ verticalAlign: "middle" , marginRight: "5px"}} />  books
              </span>
          </Typography>
          </Box>
          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #58827E"
            maxWidth=" 400px"
            width="100%"
            height="120px"
            backgroundColor="#4B6856"
            borderRadius="15px"
            paddingLeft="10px"
            paddingBottom="0px"
            display="flex"
            flexDirection="column"
            justifyContent="center" // Center items vertically
          >
            <Typography
              fontSize="45px"
              fontWeight="900"
              color="#f6f6f6"
              marginBottom="-15px"
            >
              {schoolsupplies.toLocaleString()}
            </Typography>
            <Typography fontSize="25px" fontWeight="700" color="#f6f6f6">
              <span style={{ display: 'flex', alignItems: 'center' }}>
                  <BackpackIcon fontSize="large" sx={{ verticalAlign: "middle" , marginRight: "5px"}} /> school supplies
              </span>
            </Typography>
            
          </Box>
        </Box>
          {/*First Boxes*/}
        <Box margin="20px" mt ="30px" display="flex">
        <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #4B6856"
            maxWidth=" 400px"
            width="100%"
            height="120px"
            backgroundColor="#4B6856"
            borderRadius="15px"
            paddingLeft="10px"
            paddingBottom="0px"
            marginRight="25px"
            display="flex"
            flexDirection="column"
            justifyContent="center" // Center items vertically
          >
            <Typography
              fontSize="45px"
              fontWeight="900"
              color="#f6f6f6"
              marginBottom="-15px"
            >
              {housesbuilt.toLocaleString()}
            </Typography>
            <Typography fontSize="25px" fontWeight="700" color="#f6f6f6">
              <span style={{ display: 'flex', alignItems: 'center' }}>
                  <HolidayVillageIcon fontSize="large" sx={{ verticalAlign: "middle" , marginRight: "5px"}} /> houses built
              </span>
            </Typography>
          </Box>
          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #58827E"
            maxWidth=" 400px"
            width="100%"
            height="120px"
            backgroundColor="#4B6856"
            borderRadius="15px"
            paddingLeft="10px"
            paddingBottom="0px"
            marginRight="25px"
            display="flex"
            flexDirection="column"
            justifyContent="center" // Center items vertically
          >
            <Typography
              fontSize="45px"
              fontWeight="900"
              color="#f6f6f6"
              marginBottom="-15px"
            >
              {livelihoodbeneficiaries.toLocaleString()}
            </Typography>
            <Typography fontSize="23px" fontWeight="700" color="#f6f6f6">
              <span style={{ display: 'flex', alignItems: 'center' }}>
                  <Diversity2Icon fontSize="large" sx={{ verticalAlign: "middle" , marginRight: "5px"}} /> livelihood beneficiaries
              </span>
            </Typography>
          </Box>
          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #58827E"
            maxWidth=" 400px"
            width="100%"
            height="120px"
            backgroundColor="#4B6856"
            borderRadius="15px"
            paddingLeft="10px"
            paddingBottom="0px"
            marginRight="25px"
            display="flex"
            flexDirection="column"
            justifyContent="center" // Center items vertically
          >
            <Typography
              fontSize="45px"
              fontWeight="900"
              color="#f6f6f6"
              marginBottom="-15px"
            >
              {bikes.toLocaleString()}
            </Typography>
            <Typography fontSize="25px" fontWeight="700" color="#f6f6f6">
              <span style={{ display: 'flex', alignItems: 'center' }}>
                  <PedalBikeIcon fontSize="large" sx={{ verticalAlign: "middle" , marginRight: "5px"}} /> bikes
              </span>
            </Typography>
          </Box>
          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #58827E"
            maxWidth=" 400px"
            width="100%"
            height="120px"
            backgroundColor="#4B6856"
            borderRadius="15px"
            paddingLeft="10px"
            paddingBottom="0px"
            marginRight="25px"
            display="flex"
            flexDirection="column"
            justifyContent="center" // Center items vertically
          >
            <Typography
              fontSize="45px"
              fontWeight="900"
              color="#f6f6f6"
              marginBottom="-15px"
            >
              {wheelchairs.toLocaleString()}
            </Typography>
            <Typography fontSize="25px" fontWeight="700" color="#f6f6f6">
              <span style={{ display: 'flex', alignItems: 'center' }}>
                  <WheelchairPickupIcon fontSize="large" sx={{ verticalAlign: "middle" , marginRight: "5px"}} /> wheelchairs
              </span>
            </Typography>
          </Box>
          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #58827E"
            maxWidth=" 400px"
            width="100%"
            height="120px"
            backgroundColor="#4B6856"
            borderRadius="15px"
            paddingLeft="10px"
            paddingBottom="0px"
            display="flex"
            flexDirection="column"
            justifyContent="center" // Center items vertically
          >
            <Typography
              fontSize="45px"
              fontWeight="900"
              color="#f6f6f6"
              marginBottom="-15px"
            >
              {solarlamps.toLocaleString()}
            </Typography>
            <Typography fontSize="25px" fontWeight="700" color="#f6f6f6">
              <span style={{ display: 'flex', alignItems: 'center' }}>
                  <SolarPowerIcon fontSize="large" sx={{ verticalAlign: "middle" , marginRight: "5px"}} />solar lamps
              </span>
            </Typography> 
          </Box>
        </Box>
        {/*Second Boxes */}
        <Box margin="20px" mt ="30px" display="flex">
        <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #4B6856"
            maxWidth=" 400px"
            width="100%"
            height="120px"
            backgroundColor="#4B6856"
            borderRadius="15px"
            paddingLeft="10px"
            paddingBottom="0px"
            marginRight="25px"
            display="flex"
            flexDirection="column"
            justifyContent="center" // Center items vertically
          >
            <Typography
              fontSize="45px"
              fontWeight="900"
              color="#f6f6f6"
              marginBottom="-15px"
            >
              {reliefpackages.toLocaleString()}
            </Typography>
            <Typography fontSize="25px" fontWeight="700" color="#f6f6f6">
              <span style={{ display: 'flex', alignItems: 'center' }}>
                  <TakeoutDiningIcon fontSize="large" sx={{ verticalAlign: "middle" , marginRight: "5px"}} />relief packages
              </span>
            </Typography> 
          </Box>
          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #58827E"
            maxWidth=" 400px"
            width="100%"
            height="120px"
            backgroundColor="#4B6856"
            borderRadius="15px"
            paddingLeft="10px"
            paddingBottom="0px"
            marginRight="25px"
            display="flex"
            flexDirection="column"
            justifyContent="center" // Center items vertically
          >
            <Typography
              fontSize="45px"
              fontWeight="900"
              color="#f6f6f6"
              marginBottom="-15px"
            >
              {kilosofrice.toLocaleString()}
            </Typography>
            <Typography fontSize="25px" fontWeight="700" color="#f6f6f6">
              <span style={{ display: 'flex', alignItems: 'center' }}>
                  <RiceBowlIcon fontSize="large" sx={{ verticalAlign: "middle" , marginRight: "5px"}} />kilos of rice
              </span>
            </Typography> 
          </Box>
          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #58827E"
            maxWidth=" 400px"
            width="100%"
            height="120px"
            backgroundColor="#4B6856"
            borderRadius="15px"
            paddingLeft="10px"
            paddingBottom="0px"
            marginRight="25px"
            display="flex"
            flexDirection="column"
            justifyContent="center" // Center items vertically
          >
            <Typography
              fontSize="45px"
              fontWeight="900"
              color="#f6f6f6"
              marginBottom="-15px"
            >
              {hygienekits.toLocaleString()}
            </Typography>
            <Typography fontSize="25px" fontWeight="700" color="#f6f6f6">
              <span style={{ display: 'flex', alignItems: 'center' }}>
                  <SanitizerIcon fontSize="large" sx={{ verticalAlign: "middle" , marginRight: "5px"}} />hygiene kits
              </span>
            </Typography> 
          </Box>
          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #58827E"
            maxWidth=" 400px"
            width="100%"
            height="120px"
            backgroundColor="#4B6856"
            borderRadius="15px"
            paddingLeft="10px"
            paddingBottom="0px"
            marginRight="25px"
            display="flex"
            flexDirection="column"
            justifyContent="center" // Center items vertically
          >
            <Typography
              fontSize="45px"
              fontWeight="900"
              color="#f6f6f6"
              marginBottom="-15px"
            >
              {pairofslippers.toLocaleString()}
            </Typography>
            <Typography fontSize="25px" fontWeight="700" color="#f6f6f6">
              <span style={{ display: 'flex', alignItems: 'center' }}>
                  <SnowshoeingIcon fontSize="large" sx={{ verticalAlign: "middle" , marginRight: "5px"}} />pairs of slippers
              </span>
            </Typography> 
          </Box>
          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #58827E"
            maxWidth=" 400px"
            width="100%"
            height="120px"
            backgroundColor="#4B6856"
            borderRadius="15px"
            paddingLeft="10px"
            paddingBottom="0px"
            display="flex"
            flexDirection="column"
            justifyContent="center" // Center items vertically
          >
            <Typography
              fontSize="45px"
              fontWeight="900"
              color="#f6f6f6"
              marginBottom="-15px"
            >
              {christmasgifts.toLocaleString()}
            </Typography>
            <Typography fontSize="25px" fontWeight="700" color="#f6f6f6">
              <span style={{ display: 'flex', alignItems: 'center' }}>
                  <RedeemIcon fontSize="large" sx={{ verticalAlign: "middle" , marginRight: "5px"}} />christmas gifts
              </span>
            </Typography> 
          </Box>
        </Box>
        {/*Third Boxes */}
      </Box>
    </Box>
  );
};

export default DashPartnership;
