import React, {useState, useEffect}from 'react';
import { Box, Typography, Select, MenuItem,FormControl, InputLabel ,Accordion, AccordionSummary, AccordionDetails} from '@mui/material';
import SecondTopBar from '../SecondTopBar';
import { firestoredb } from '../../firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import BusinessIcon from '@mui/icons-material/Business';

const DashScholarship = () => {

  const [yearfrom, setYearfrom] = useState('');
  const [yearto, setYearto] = useState('');
  //const [scholars, setScholars] = useState(0);
  const [graduated, setGraduated] = useState(0);
  const [noScholars, setnoScholars] = useState(0);
  //const [annualTargetScholar, setannualTargetScholar] = useState(0);
  //const [scholarsFTY, setscholarsFTY] = useState(0);
  const [graduatedWithHonor, setgraduatedWithHonor] = useState(0);
  const [graduateWhoAreBoardPasser, setgraduateWhoAreBoardPasser] = useState(0);
  const [scholarshipGrants, setscholarshipGrants] = useState(0);
  const [partnerSchools, setpartnerSchools] = useState(0);
  const [totalHiredScholars, settotalHiredScholars] = useState(0);


  const [megaworld,setMeg] = useState(0);
  const [asiaAffinity,setAA] = useState(0);
  const [empireEast,setEE] = useState(0);
  const [fopmi,setFOPMI] = useState(0);
  const [geri,setGERI] = useState(0);
  const [megHotels,setMegHotels] = useState(0);
  const [mreit,setMREIT] = useState(0);
  const [suntrust,setSuntrust] = useState(0);
  const [pickaroo,setPickaroo] = useState(0);
  const [travellers,setTravellers] = useState(0);
  const [emperador,setEmperador] = useState(0);
  const [progreen,setProgreen] = useState(0);
  const [angloWatsons,setAnglo] = useState(0);
  //const [cgbi,setCGBI] = useState(0);
  const [infracorp,setInfracorp] = useState(0);
  const [expanded, setExpanded] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
  });


  
  useEffect(() => {
    const getEventData = async () => {
        try {
            const ScholarshipCollection = collection(firestoredb, 'ScholarshipData');
            const snapshot = await getDocs(ScholarshipCollection);

            //let scholarsCount = 0;
            let graduatedCount = 0;
            let noScholarsCount = 0;
           // let annualTargetScholarCount = 0;
            //let scholarsFTYCount = 0;
            let graduatedWithHonorCount = 0;
            let graduateWhoAreBoardPasserCount = 0;
            let scholarshipGrantsCount = 0;
            let partnerSchoolsCount = 0;
            let totalHiredScholarsCount = 0;
            //let companyFieldsCount = 0;
            //let remarksCount = 0;
            let megaworldCount = 0;
            let asiaAffinityCount = 0;
            let empireEastCount = 0;
            let fopmiCount = 0;
            let geriCount = 0;
            let megHotelsCount = 0;
            let mreitCount = 0;
            let suntrustCount = 0;
            let pickarooCount = 0 ;
            let travellersCount = 0;
            let emperadorCount = 0;
            let progreenCount = 0;
            let angloWatsonsCount = 0;
            let cgbiCount = 0;
            let infracorpCount = 0;

            snapshot.forEach((doc) => {
                const data = doc.data();
                const year = data.year;

                // Check if the data has locationFields and if the year is within the specified range
                if ((!yearfrom || yearfrom === '' || year >= yearfrom) && (!yearto || yearto === '' || year <= yearto)) {

                  //No of Scholars
                  const noOfScholarsCount = parseInt(data.noScholar || 0, 10);
                  noScholarsCount += noOfScholarsCount;

                  //Grants
                  const totalscholarshipGrantsCount = parseInt(data.scholarshipGrants || 0, 10);
                  scholarshipGrantsCount += totalscholarshipGrantsCount;

                  //Graduated
                  const totalgraduatedCount = parseInt(data.scholars.graduated || 0, 10);
                  graduatedCount += totalgraduatedCount;  
                                  
                  //With Honors
                  const totalgraduatedWithHonorCount = parseInt(data.graduatedWithHonor || 0, 10);
                  graduatedWithHonorCount += totalgraduatedWithHonorCount;

                  //Board Passers
                  const totalgraduateWhoAreBoardPasserCount = parseInt(data.graduateWhoAreBoardPasser || 0, 10);
                  graduateWhoAreBoardPasserCount += totalgraduateWhoAreBoardPasserCount;

                  //Hired Scholars
                  const totalofHiredScholarsCount = parseInt(data.totalHiredScholars || 0, 10);
                  totalHiredScholarsCount += totalofHiredScholarsCount;

                  //Partner Schools
                  const totalpartnerSchoolsCount = parseInt(data.partnerSchools || 0, 10);
                  partnerSchoolsCount += totalpartnerSchoolsCount;

                   //accordions
                   data.companyFields.forEach((companyField)=>{
                    if (companyField.company === 'Asia Affinity Property Management, Inc.') {
                      const aaCount = parseInt(companyField.companyHired || 0, 10);
                      asiaAffinityCount +=  aaCount;
                    } 
                    if (companyField.company === 'Empire East Land Holdings, Inc.') {
                      const eeCount = parseInt(companyField.companyHired || 0, 10);
                      empireEastCount +=  eeCount;
                    } 
                    if (companyField.company === 'First Oceanic Property Management, Inc.') {
                      const firstoceanicCount = parseInt(companyField.companyHired || 0, 10);
                      fopmiCount +=  firstoceanicCount;
                    } 
                    if (companyField.company === 'Global-Estate Resorts, Inc.') {
                      const globalestateCount = parseInt(companyField.companyHired || 0, 10);
                      geriCount +=  globalestateCount;
                    } 
                    if (companyField.company === 'Megaworld Corporation') {
                      const megCount = parseInt(companyField.companyHired || 0, 10);
                      megaworldCount +=  megCount;
                    } 
                    if (companyField.company === 'Megaworld Hotels & Resorts') {
                      const mhrCount = parseInt(companyField.companyHired || 0, 10);
                      megHotelsCount +=  mhrCount;
                    } 
                    if (companyField.company === 'MREIT, Inc.') {
                      const merCount = parseInt(companyField.companyHired || 0, 10);
                      mreitCount +=  merCount;
                    } 
                    if (companyField.company === 'Pickaroo') {
                      const pickCount = parseInt(companyField.companyHired || 0, 10);
                      pickarooCount +=  pickCount;
                    } 
                    if (companyField.company === 'Suntrust Properties, Inc.') {
                      const suntCount = parseInt(companyField.companyHired || 0, 10);
                      suntrustCount +=  suntCount;
                    } 
                    if (companyField.company === 'Travellers International Hotel Group, Inc.') {
                      const travCount = parseInt(companyField.companyHired || 0, 10);
                      travellersCount +=  travCount;
                    } 
                    if (companyField.company === 'Anglo Watsons Glass, Inc.') {
                      const anglowatCount = parseInt(companyField.companyHired || 0, 10);
                      angloWatsonsCount +=  anglowatCount;
                    } 
                    if (companyField.company === 'Emperador, Inc.') {
                      const empCount = parseInt(companyField.companyHired || 0, 10);
                      emperadorCount +=  empCount;
                    } 
                    if (companyField.company === 'ProGreen Agricorp., Inc.') {
                      const progCount = parseInt(companyField.companyHired || 0, 10);
                      progreenCount +=  progCount;
                    } 
                    if (companyField.company === 'Golden Arches Development, Inc.') {
                      const goldenarchCount = parseInt(companyField.companyHired || 0, 10);
                      cgbiCount +=  goldenarchCount;
                    } 
                    if (companyField.company === 'Infracorp Development Inc.') {
                      const infraCount = parseInt(companyField.companyHired || 0, 10);
                      infracorpCount +=  infraCount;
                    } 
                   })

              }

          });

          
            // Set the state variables
            setnoScholars(noScholarsCount);
            setscholarshipGrants(scholarshipGrantsCount);
            setGraduated(graduatedCount);
            setgraduatedWithHonor(graduatedWithHonorCount);
            setgraduateWhoAreBoardPasser(graduateWhoAreBoardPasserCount);
            settotalHiredScholars(totalHiredScholarsCount);
            setpartnerSchools(partnerSchoolsCount);
            setMeg(megaworldCount);
            setAA(asiaAffinityCount);
            setEE(empireEastCount);
            setFOPMI(fopmiCount);
            setGERI(geriCount);
            setMeg(megaworldCount);
            setMegHotels(megHotelsCount);
            setMREIT(mreitCount);
            setPickaroo(pickarooCount);
            setSuntrust(suntrustCount);
            setTravellers(travellersCount);
            setEmperador(emperadorCount);
            setAnglo(angloWatsonsCount);
            setEmperador(emperadorCount);
            setProgreen(progreenCount);
            setInfracorp(infracorpCount);



        } catch (error) {
            console.error('Error fetching Scholarship Data:', error);
        }
    };

    // Call the function to fetch data
    getEventData();
}, [yearfrom, yearto]); 
  
  

const generateYearOptions = () => {
  const currentYear = new Date().getFullYear(); // Get the current year
  const startYear = 1997; // Start year
  const yearOptions = [];
  
  // Loop from the start year to the current year
  for (let i = currentYear; i >= startYear; i--) {
    yearOptions.push(i.toString()); // Push each year as a string to the options array
  }
  
  return yearOptions; // Return the array of year options
};
const handleYearFromChange = (e) => {
  const selectedYearFrom = e.target.value;
  
  // Set yearfrom based on the selected value
  setYearfrom(selectedYearFrom);

  // If "Overall" is selected, clear both yearfrom and yearto
  if (selectedYearFrom === "") {
    setYearto(""); // Clear the value in yearto
  } else if (yearto && selectedYearFrom > yearto) {
    // If the selected value of "Year To" is smaller than "Year From", update "Year To" to match "Year From"
    setYearto(selectedYearFrom);
  }
};

// Event handler for "Year To" change
const handleYearToChange = (e) => {
  // If the selected value of "Year To" is smaller than "Year From", prevent updating "Year To"
  setYearto(e.target.value);
  if (yearfrom ===""){
  setYearto("2024")
    return;
  }
  if (yearfrom && e.target.value < yearfrom) {
    return;
  }
};

const handleChangeExp = (index) => {
  setExpanded((prevExpanded) => ({
    ...prevExpanded,
    [index]: !prevExpanded[index],
  }));
};

  
  return (
   <Box >
    <SecondTopBar/>
    <Box borderTop = "15px solid #849f77" maxHeight="750px"   height="calc(100vh - 25px)" width="auto%" >
            <Box marginRight ="25px" marginTop ="15px"  display="flex" justifyContent="flex-end" alignItems="flex-end">
            <FormControl style={{ width: '100px', height: '25px' }}>
                    <InputLabel >Year From:</InputLabel> 
                    <Select
                      style={{ height : '35px'}}
                      label="Year From"
                      value={yearfrom}
                      MenuProps={{
                        MenuListProps: {
                          style: {
                            maxHeight: 150, // Set the maximum height of the dropdown
                          },
                        },
                      }}
                      onChange={handleYearFromChange}
                    >
                      <MenuItem value="">Overall</MenuItem>
                      {generateYearOptions().map((option) => (
                        <MenuItem key={option} value={option}>{option}</MenuItem>
                      ))}
                    </Select>

                  </FormControl>

                  {yearfrom && yearfrom !== "" && (
                    <FormControl style={{ marginLeft: '20px', width: '100px', height: '25px' }}>
                      <InputLabel>Year to</InputLabel>
                      <Select
                        style={{ height: '35px' }}
                        label="Year to"
                        value={yearto}
                        MenuProps={{
                          MenuListProps: {
                            style: {
                              maxHeight: 150, // Set the maximum height of the dropdown
                            },
                          },
                        }}
                        onChange={handleYearToChange}
                      >
                        {generateYearOptions().map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
            </Box>
      <Box margin ="25px"display="flex" >
            
            <Box 
              boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
              border="1px solid #849f77" 
              maxWidth =" 230px" 
              width = "100%"
              height="120px"
              backgroundColor="#849f77"
              borderRadius = "15px"
              paddingLeft ="10px"
              marginRight = "25px"  
              display="flex"
              flexDirection="column"
              justifyContent="center" // Center items vertically
            >
                <Typography  fontSize = "50px" fontWeight="900" color = "#f6f6f6" marginBottom="-15px">
                  {noScholars.toLocaleString()}
                </Typography>
                <Typography fontSize = "25px" fontWeight="700" color = "#f6f6f6" >
                  total scholars
                </Typography>
            </Box>
            <Box 
              boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
              border="1px solid #849f77" 
              maxWidth =" 230px" 
              width = "100%"
              height="120px" 
              backgroundColor="#849f77"
              borderRadius = "15px"
              paddingLeft ="10px"
              marginRight = "25px"  
              display="flex"
              flexDirection="column"
              justifyContent="center" // Center items vertically
            >
                <Typography  fontSize = "50px" fontWeight="900" color = "#f6f6f6" marginBottom="-15px">
                  {scholarshipGrants.toLocaleString()}
                </Typography>
                <Typography fontSize = "25px" fontWeight="700" color = "#f6f6f6"  lineHeight="1">
                  scholarship grants
                </Typography>
            </Box>

            <Box 
              boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
              border="1px solid #849f77"
              maxWidth =" 250px" 
              width = "100%"
              height="120px"
              backgroundColor="#849f77"
              borderRadius = "15px"
              paddingLeft ="10px"
              paddingBottom ="10px"
              marginRight = "25px"  
              display="flex"
              flexDirection="column"
              justifyContent="center" // Center items vertically
            >
                <Typography  fontSize = "50px" fontWeight="900" color = "#f6f6f6" marginBottom="-8px">
                  {graduated.toLocaleString()}
                </Typography>
                <Typography fontSize = "25px" fontWeight="700" color = "#f6f6f6" lineHeight="1">
                  graduated
                </Typography>
            </Box>
            <Box 
              boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
              border="1px solid #849f77"
              maxWidth =" 250px" 
              width = "100%"
              height="120px"
              backgroundColor="#849f77"
              borderRadius = "15px"
              paddingLeft ="10px"
              paddingBottom ="10px"
              marginRight = "25px"  
              display="flex"
              flexDirection="column"
              justifyContent="center" // Center items vertically
            >
                <Typography  fontSize = "50px" fontWeight="900" color = "#f6f6f6" marginBottom="-8px">
                  {graduatedWithHonor.toLocaleString()}
                </Typography>
                <Typography fontSize = "25px" fontWeight="700" color = "#f6f6f6" lineHeight="1">
                  with honors
                </Typography>
            </Box>
            <Box 
              boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
              border="1px solid #849f77"
              maxWidth =" 250px" 
              width = "100%"
              height="120px"
              backgroundColor="#849f77"
              borderRadius = "15px"
              paddingLeft ="10px"
              paddingBottom ="10px"
              marginRight = "25px"  
              display="flex"
              flexDirection="column"
              justifyContent="center" // Center items vertically
            >
                <Typography  fontSize = "50px" fontWeight="900" color = "#f6f6f6" marginBottom="-8px">
                  {graduateWhoAreBoardPasser.toLocaleString()}
                </Typography>
                <Typography fontSize = "25px" fontWeight="700" color = "#f6f6f6" lineHeight="1">
                  board passers
                </Typography>
            </Box>
            <Box 
              boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
              border="1px solid #849f77"
              maxWidth =" 250px" 
              width = "100%"
              height="120px"
              backgroundColor="#849f77"
              borderRadius = "15px"
              paddingLeft ="10px"
              paddingBottom ="10px"
              marginRight = "25px"  
              display="flex"
              flexDirection="column"
              justifyContent="center" // Center items vertically
            >
                <Typography  fontSize = "50px" fontWeight="900" color = "#f6f6f6" marginBottom="-8px">
                  {totalHiredScholars.toLocaleString()}
                </Typography>
                <Typography fontSize = "25px" fontWeight="700" color = "#f6f6f6" lineHeight="1">
                  hired scholars
                </Typography>
            </Box>
            <Box 
              boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
              border="1px solid #849f77"
              maxWidth =" 250px" 
              width = "100%"
              height="120px"
              backgroundColor="#849f77"
              borderRadius = "15px"
              paddingLeft ="10px"
              paddingBottom ="10px"  
              display="flex"
              flexDirection="column"
              justifyContent="center" // Center items vertically
            >
                <Typography  fontSize = "50px" fontWeight="900" color = "#f6f6f6" marginBottom="-8px">
                  {partnerSchools.toLocaleString()}
                </Typography>
                <Typography fontSize = "25px" fontWeight="700" color = "#f6f6f6" lineHeight="1">
                  partner schools
                </Typography>
            </Box>

          
        </Box>

        <Box display="flex"  marginLeft ="25px" marginTop ="40px" >
          <Box maxHeight="50px" maxWidth="75px">
            <img src="agi-logo.png" alt="AGI LOGO" style={{ width: '100%', height: '120%' }} />
            </Box>

        <Box display="flex"  marginLeft="30px" >
            <Typography fontSize="40px" fontWeight="900" color="#849f77" marginTop="5px" marginBottom="20px">
             Employed Scholars under Alliance Global Group, Inc.
            </Typography>
          </Box>
          </Box>

        <Box marginLeft="25px" display="flex" flexDirection="row">
                <Accordion
                    expanded={expanded[0]}
                    onChange={() => handleChangeExp(0)}
                    style={{
                    maxWidth: "380px",
                    width: "100%",
                    maxHeight: "200px",
                    height: "100%",
                    borderRadius: "15px",
                    marginRight: "25px",
                    backgroundColor: "#849f77",
                    borderTop:"1px solid #849f77"
                    }}
                >
                    <AccordionSummary style={{ height: "20px" }}>
                    <Typography
                        fontSize="20px"
                        fontWeight="700"
                        color="#f6f6f6"
                        display="flex"
                        alignItems="center"
                    >
                        <BusinessIcon style={{ marginRight: "10px"}} /> Megaworld
                    </Typography>
                     </AccordionSummary>
                  
                    <AccordionDetails
                   style={{
                    minHeight: expanded ? "100px" : "50px", // Adjust the minHeight here
                    display: "flex",
                    alignItems:"center",
                    backgroundColor: "#849f77",
                    borderRadius: '0px 0px 15px 15px',
                    marginTop:"-30px",
                }}
                >
                    <Typography fontSize="30px" fontWeight="900" color="#f6f6f6" >
                        {asiaAffinity.toLocaleString()}
                    </Typography>
                    <Typography
                        fontSize="15px"
                        fontWeight="700"
                        color="#f6f6f6"
                        marginLeft="15px"
                        lineHeight='1'
                    >
                        Asia Affinity Property Management, Inc.
                    </Typography>
                    </AccordionDetails>

                    <AccordionDetails
                    style={{
                        minHeight: expanded ? "100px" : "50px", // Adjust the minHeight here
                        display: "flex",
                        alignItems:"center",
                        backgroundColor: "#849f77",
                        borderRadius: '0px 0px 15px 15px',
                        marginTop:"-50px",
                    }}
                    >
                    <Typography fontSize="30px" fontWeight="900" color="#f6f6f6" >
                        {empireEast.toLocaleString()}
                    </Typography>
                    <Typography
                        fontSize="15px"
                        fontWeight="700"
                        color="#f6f6f6"
                        marginLeft="15px"
                        lineHeight="1"
                    >
                        Empire East Land Holdings, Inc.
                    </Typography>
                    </AccordionDetails>

                    <AccordionDetails
                    style={{
                        minHeight: expanded ? "100px" : "50px", // Adjust the minHeight here
                        display: "flex",
                        alignItems:"center",
                        backgroundColor: "#849f77",
                        borderRadius: '0px 0px 15px 15px',
                        marginTop:"-50px",
                    }}
                    >
                    <Typography fontSize="30px" fontWeight="900" color="#f6f6f6" >
                        {fopmi.toLocaleString()}
                    </Typography>
                    <Typography
                        fontSize="15px"
                        fontWeight="700"
                        color="#f6f6f6"
                        marginLeft="15px"
                        lineHeight="1"
                    >
                        First Oceanic Property Management, Inc.
                    </Typography>
                    </AccordionDetails>

                    <AccordionDetails
                    style={{
                        minHeight: expanded ? "100px" : "50px", // Adjust the minHeight here
                        display: "flex",
                        alignItems:"center",
                        backgroundColor: "#849f77",
                        borderRadius: '0px 0px 15px 15px',
                        marginTop:"-50px",
                    }}
                    >
                    <Typography fontSize="30px" fontWeight="900" color="#f6f6f6" >
                        {geri.toLocaleString()}
                    </Typography>
                    <Typography
                        fontSize="15px"
                        fontWeight="700"
                        color="#f6f6f6"
                        marginLeft="15px"
                        lineHeight="1"
                    >
                        Global-Estate Resorts, Inc.
                    </Typography>
                    </AccordionDetails>

                    <AccordionDetails
                    style={{
                        minHeight: expanded ? "100px" : "50px", // Adjust the minHeight here
                        display: "flex",
                        alignItems:"center",
                        backgroundColor: "#849f77",
                        borderRadius: '0px 0px 15px 15px',
                        marginTop:"-50px",
                    }}
                    >
                    <Typography fontSize="30px" fontWeight="900" color="#f6f6f6" >
                        {megaworld.toLocaleString()}
                    </Typography>
                    <Typography
                        fontSize="15px"
                        fontWeight="700"
                        color="#f6f6f6"
                        marginLeft="15px"
                        lineHeight="1"
                    >
                        Megaworld Corporation
                    </Typography>
                    </AccordionDetails>

                    <AccordionDetails
                    style={{
                        minHeight: expanded ? "100px" : "50px", // Adjust the minHeight here
                        display: "flex",
                        alignItems:"center",
                        backgroundColor: "#849f77",
                        borderRadius: '0px 0px 15px 15px',
                        marginTop:"-50px",
                    }}
                    >
                    <Typography fontSize="30px" fontWeight="900" color="#f6f6f6" >
                        {megHotels.toLocaleString()}
                    </Typography>
                    <Typography
                        fontSize="15px"
                        fontWeight="700"
                        color="#f6f6f6"
                        marginLeft="15px"
                        lineHeight="1"
                    >
                        Megaworld Hotels & Resorts
                    </Typography>
                    </AccordionDetails>

                    <AccordionDetails
                    style={{
                        minHeight: expanded ? "100px" : "50px", // Adjust the minHeight here
                        display: "flex",
                        alignItems:"center",
                        backgroundColor: "#849f77",
                        borderRadius: '0px 0px 15px 15px',
                        marginTop:"-50px",
                    }}
                    >
                    <Typography fontSize="30px" fontWeight="900" color="#f6f6f6" >
                        {mreit.toLocaleString()}
                    </Typography>
                    <Typography
                        fontSize="15px"
                        fontWeight="700"
                        color="#f6f6f6"
                        marginLeft="15px"
                        lineHeight="1"
                    >
                        MREIT, Inc.
                    </Typography>
                    </AccordionDetails>

                    <AccordionDetails
                    style={{
                        minHeight: expanded ? "100px" : "50px", // Adjust the minHeight here
                        display: "flex",
                        alignItems:"center",
                        backgroundColor: "#849f77",
                        borderRadius: '0px 0px 15px 15px',
                        marginTop:"-50px",
                    }}
                    >
                    <Typography fontSize="30px" fontWeight="900" color="#f6f6f6" >
                        {pickaroo.toLocaleString()}
                    </Typography>
                    <Typography
                        fontSize="15px"
                        fontWeight="700"
                        color="#f6f6f6"
                        marginLeft="15px"
                        lineHeight="1"
                    >
                        Pickaroo
                    </Typography>
                    </AccordionDetails>

                    <AccordionDetails
                    style={{
                        minHeight: expanded ? "70px" : "50px", // Adjust the minHeight here
                        display: "flex",
                        alignItems:"center",
                        backgroundColor: "#849f77",
                        borderRadius: '0px 0px 15px 15px',
                        marginTop:"-35px",
                        
                        
                    }}
                    >
                    <Typography fontSize="30px" fontWeight="900" color="#f6f6f6" >
                        {suntrust.toLocaleString()}
                    </Typography>
                    <Typography
                        fontSize="15px"
                        fontWeight="700"
                        color="#f6f6f6"
                        marginLeft="15px"
                        lineHeight="1"
                    >
                        Suntrust Properties, Inc.
                    </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion
                   
                   expanded={expanded[2]}
                   onChange={() => handleChangeExp(2)}
                   style={{
                   maxWidth: "300px",
                   width: "100%",
                   maxHeight: "200px",
                   margin:"0px",
                   height: "100%",
                   borderRadius: "15px",
                   marginRight: "25px",
                   backgroundColor: "#849f77",
                   }}
               >
                   <AccordionSummary style={{ height: "20px" }}>
                   <Typography
                      fontSize="20px"
                       fontWeight="700"
                       color="#f6f6f6"
                       display="flex"
                       alignItems="center"
                       
                   >
                       <BusinessIcon style={{ marginRight: "10px" }} /> Emperador
                   </Typography>
                    </AccordionSummary>
                 
                    <AccordionDetails
                   style={{
                       minHeight: expanded ? "150px" : "50px", // Adjust the minHeight here
                       display: "flex",
                       alignItems:"center",
                       backgroundColor: "#849f77",
                       borderRadius: '0px 0px 15px 15px',
                       marginTop:"-30px",
                   }}
                   >
                   <Typography fontSize="30px" fontWeight="900" color="#f6f6f6" >
                      {angloWatsons.toLocaleString()}
                   </Typography>
                   <Typography
                       fontSize="15px"
                       fontWeight="700"
                       color="#f6f6f6"
                       marginLeft="15px"
                       lineHeight='1'
                   >
                       Anglo Watsons Glass, Inc.
                   </Typography>
                   </AccordionDetails>

                   <AccordionDetails
                   style={{
                    minHeight: expanded ? "150px" : "50px", // Adjust the minHeight here
                    display: "flex",
                    alignItems:"center",
                    backgroundColor: "#849f77",
                    borderRadius: '0px 0px 15px 15px',
                    marginTop:"-80px",
                }}
                >
                    <Typography fontSize="30px" fontWeight="900" color="#f6f6f6" >
                        {emperador.toLocaleString()}
                    </Typography>
                    <Typography
                        fontSize="15px"
                        fontWeight="700"
                        color="#f6f6f6"
                        marginLeft="15px"
                        lineHeight='1'
                    >
                        Emperador, Inc.
                    </Typography>
                    </AccordionDetails>

                    <AccordionDetails
                   style={{
                    minHeight: expanded ? "150px" : "50px", // Adjust the minHeight here
                    display: "flex",
                    alignItems:"center",
                    backgroundColor: "#849f77",
                    borderRadius: '0px 0px 15px 15px',
                    marginTop:"-80px",
                }}
                >
                    <Typography fontSize="30px" fontWeight="900" color="#f6f6f6" >
                        {progreen.toLocaleString()}
                    </Typography>
                    <Typography
                        fontSize="15px"
                        fontWeight="700"
                        color="#f6f6f6"
                        marginLeft="15px"
                        lineHeight='1'
                    >
                        ProGreen Agricorp., Inc.
                    </Typography>
                    </AccordionDetails>
               </Accordion>


                <Accordion
                   
                   expanded={expanded[1]}
                   onChange={() => handleChangeExp(1)}
                   style={{
                   maxWidth: "300px",
                   width: "100%",
                   maxHeight: "200px",
                   margin:"0px",
                   height: "100%",
                   borderRadius: "15px",
                   marginRight: "25px",
                   backgroundColor: "#849f77",
                   }}
               >
                   <AccordionSummary style={{ height: "20px" }}>
                   <Typography
                      fontSize="20px"
                       fontWeight="700"
                       color="#f6f6f6"
                       display="flex"
                       alignItems="center"
                       
                   >
                       <BusinessIcon style={{ marginRight: "10px" }} /> Travellers
                   </Typography>
                    </AccordionSummary>
                 
                    <AccordionDetails
                   style={{
                       minHeight: expanded ? "170px" : "50px", // Adjust the minHeight here
                       display: "flex",
                       alignItems:"center",
                       backgroundColor: "#849f77",
                       borderRadius: '0px 0px 15px 15px',
                       marginTop:"-30px",
                   }}
                   >
                   <Typography fontSize="30px" fontWeight="900" color="#f6f6f6" >
                      {travellers.toLocaleString()}
                   </Typography>
                   <Typography
                       fontSize="15px"
                       fontWeight="700"
                       color="#f6f6f6"
                       marginLeft="15px"
                       lineHeight='1'
                   >
                       Hired
                   </Typography>
                   </AccordionDetails>
                 
               </Accordion>

               <Accordion
                   
                   expanded={expanded[3]}
                   onChange={() => handleChangeExp(3)}
                   style={{
                   maxWidth: "300px",
                   width: "100%",
                   maxHeight: "200px",
                   margin:"0px",
                   height: "100%",
                   borderRadius: "15px",
                   marginRight: "25px",
                   backgroundColor: "#849f77",
                   }}
               >
                   <AccordionSummary style={{ height: "20px" }}>
                   <Typography
                      fontSize="20px"
                       fontWeight="700"
                       color="#f6f6f6"
                       display="flex"
                       alignItems="center"
                       
                   >
                       <BusinessIcon style={{ marginRight: "10px" }} /> Golden Arches
                   </Typography>
                    </AccordionSummary>
                 
                    <AccordionDetails
                   style={{
                       minHeight: expanded ? "170px" : "50px", // Adjust the minHeight here
                       display: "flex",
                       alignItems:"center",
                       backgroundColor: "#849f77",
                       borderRadius: '0px 0px 15px 15px',
                       marginTop:"-30px",
                   }}
                   >
                   <Typography fontSize="30px" fontWeight="900" color="#f6f6f6" >
                      {travellers.toLocaleString()}
                   </Typography>
                   <Typography
                       fontSize="15px"
                       fontWeight="700"
                       color="#f6f6f6"
                       marginLeft="15px"
                       lineHeight='1'
                   >
                       Hired
                   </Typography>
                   </AccordionDetails>
                 
               </Accordion>

               <Accordion
                   
                   expanded={expanded[4]}
                   onChange={() => handleChangeExp(4)}
                   style={{
                   maxWidth: "300px",
                   width: "100%",
                   maxHeight: "200px",
                   margin:"0px",
                   height: "100%",
                   borderRadius: "15px",
                   marginRight: "25px",
                   backgroundColor: "#849f77",
                   }}
               >
                   <AccordionSummary style={{ height: "20px" }}>
                   <Typography
                      fontSize="20px"
                       fontWeight="700"
                       color="#f6f6f6"
                       display="flex"
                       alignItems="center"
                   >
                       <BusinessIcon style={{ marginRight: "10px" }} /> Infracorp
                   </Typography>
                    </AccordionSummary>
                 
                    <AccordionDetails
                   style={{
                       minHeight: expanded ? "170px" : "50px", // Adjust the minHeight here
                       display: "flex",
                       alignItems:"center",
                       backgroundColor: "#849f77",
                       borderRadius: '0px 0px 15px 15px',
                       marginTop:"-30px",
                   }}
                   >
                   <Typography fontSize="30px" fontWeight="900" color="#f6f6f6" >
                      {travellers.toLocaleString()}
                   </Typography>
                   <Typography
                       fontSize="15px"
                       fontWeight="700"
                       color="#f6f6f6"
                       marginLeft="15px"
                       lineHeight='1'
                   >
                       Hired
                   </Typography>
                   </AccordionDetails>
                 
               </Accordion>


      
            

        </Box>
        


        </Box>


    </Box>
);
};

export default DashScholarship;