export const townshipLocationsMapping = {
  1: ["Almanza Dos","Almanza Uno ","Pilar"],
  2: ["Bagong Ilog","Bagumbayan","Buayang Bato","Caniogan","Maybunga","Rosario","San Antonio","Ugong","Wack Wack"],
  3: ["De Ocampo", "Osorio", "San Agustin"],
  4: [""],
  5: ["Balabag","Manoc Manoc","Yapak"],
  6: ["Cabalantian", "Magliman", "Santo Rosario"],
  7: ["8-A","20","Cabantian"],
  8: ["Bagong Nayon","Hills","Santa Cruz"],
  9: ["Amihan","Bagumbayan","Bayanihan","Dela Paz","Escopa 1","Kaunlaran","Libis","Manggahan","Masagana","Santo Tomas","Santolan","Ugong Norte",],
  10: ["Guadalupe Nuevo","Pinagkaisahan","Pitogo"],
  11: ["San Roque","Sto. Domingo","Sto. Niño"],
  12: ["Bakhaw","Benedicto","Buhang Taft North","Compania","Cuartero","Democracia","Dungon A","Guzman-Jesena","Katilingban","Navais","Old Airport","Onate De Leon","PHHC Block 17","Poblacion","Q. Abeto Mirasol Taft South","Sambag","San Rafael","Santa Rosa","Tabucan","Tap-oc","Ungka"],
  13: ["281","282","283","284","285","286","287"],
  14: ["Biclatan","Bacao 1","Maya St.","Navarro","Sampalucan Road"],
  15: ["Central Bicutan","Fort Bonifacio","Hagonoy","Katuparan","Lower Bicutan","Martinez Del 96","North Signal","Pinagsama","San Roque","Tuktukan","Ususan","Western Bicutan"],
  16: ["Central Bicutan","Fort Bonifacio","Hagonoy","Katuparan","Lower Bicutan","Martinez Del 96","North Signal","Pinagsama","San Roque","Tuktukan","Ususan","Western Bicutan"],
  17: ["76","98","136","147","183","184","185","187","189","193","197"],
  18: ["Batic","Estaquio Lopez","Guihalaran"],
  19: ["Duhat","Lambakin", "Saog"],
  20: ["Caruray","Kemdeng","Poblacion"],
  21: [""],
  22: ["Cabilang BayBay","Pacita","Rosario","San Francisco","Soro Soro"],
  23: ["Balibagan Estate","Cadagmayan Sur","Malawog Jaro"],
  24: ["Amaya I","Amaya VII","Mulawin","Tres Cruses"],
  25: ["Caliraya","East Talaongan","Tibatib"],
  26: ["Ibo","Mactan","Maribago","Punta Engano"],
  27: ["1","2","3","4","5","6","7","8","9", "10","11","26","27","28","30","31","32","33","41","Alijis","Estefania","Taculing","Villamonte"],
  28: ["Amuyong","Dayap Itaas","Luksuhin Ibaba","Luksuhin Ilaya","Niugan","Upli"],
  29: ["Bel-Air","Buayang Bato","Cembo","East Rembo","Fort Bonifacio","Guadalupe","North Cembo","Pembo","Pinagkaisahan","Pitogo","Post Proper North Side","South Cembo","West Rembo"],
  30: ["192 Zone 20","193 Zone 20","194 Zone 20","195 Zone 20","196 Zone 20","197 Zone 20","198 Zone 20","Don Galo","Tambo",],
  31: [" "],
};