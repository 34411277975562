import React, { useState, useEffect,useMemo } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Typography,
  Grid,
  InputAdornment,
  Modal,
  Autocomplete,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { doc, deleteDoc, updateDoc, getDoc } from "firebase/firestore";
import { firestoredb } from "../../../firebaseConfig"; // Replace with your Firebase configuration file
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import { useParams } from "react-router-dom";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { townshipLocationsMapping } from "../../../Data/TownshipLocationsMapping";
import Chip from "@mui/material/Chip";
import { PartnerMapping } from "../../../Data/PartnerMapping";

const PartDonationform = () => {
  //Initialized Variables
  const { documentId } = useParams();
  const navigate = useNavigate();
  const [sdgName, setSdgName] = useState("");
  const [DonationMode, setDonationMode] = useState("");
  const [DonationType, setDonationType] = useState("");
  const [projectName, setProjectName] = useState("");
  const [year, setYear] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [isFocused1, setIsFocused1] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [user, setUser] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [locationFields, setLocationFields] = useState([
    {
      date: null, // Initialize with the current date or a default date.
      township: "",
      location: "",
      beneficiaries: "",
      typenebe:"",
      partners: [],
      product:"",
      budget: "",
      expenses: "",
      description: "",
      DonatedBooks: "",
      ClassroomsBuilt: "",
      LibrariesBuilt: "",
      ClassroomsRefurb: "",
      HousesBuilt: "",
      typedisaster: "",
      SuppliesDistrib: "",
      SolarLamp: "",
      WheelchairDonated: "",
      PairSlippers: "",
      BikesDistrib: "",
      RiceDonated: "",
      ROFoodGroceries: "",
      ROMeds: "",
      ROHygiene	: "",
      ROtotalRelief: "",
      MMAlcohol: "",
      MMFacemask: "",
    },
  ]);
  //Creates array for LocationFields
  const excludedDonationTypes = useMemo(() => [
    "Happy Feet Project",
    "If There's A Wheel, There's a Way",
    "Mega Malasakit",
    "Rice Together"
  ], []);

  //Initialization of Added Data
  const handleAddLocation = () => {
    setLocationFields([
      ...locationFields,
      {
        date: null, // Initialize with the current date or a default date.
        township: "",
        location: "",
        beneficiaries: "",
        typenebe:"",
        partners: [],
        product:"",
        budget: "",
        expenses: "",
        description: "",
        DonatedBooks: "",
        ClassroomsBuilt: "",
        LibrariesBuilt: "",
        ClassroomsRefurb: "",
        HousesBuilt: "",
        typedisaster: "",
        SuppliesDistrib: "",
        SolarLamp: "",
        WheelchairDonated: "",
        PairSlippers: "",
        BikesDistrib: "",
        RiceDonated: "",
        ROFoodGroceries: "",
        ROMeds: "",
        ROHygiene	: "",
        ROtotalRelief: "",
        MMAlcohol: "",
        MMFacemask: "",
      },
    ]);
  };
  //Set the year

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear(); // Get the current year
    const startYear = 1997; // Start year
    const yearOptions = [];

    // Loop from the start year to the current year
    for (let i = currentYear; i >= startYear; i--) {
      yearOptions.push(i.toString()); // Push each year as a string to the options array
    }

    return yearOptions; // Return the array of year options
  };

  const handleRemoveLocation = (index) => {
    const newFields = [...locationFields];
    newFields.splice(index, 1);
    setLocationFields(newFields);
  };

  const handleCancel = () => {
    // Use history.goBack() to navigate back to the previous screen
    navigate("/partnershipDonation");
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const PartnershipDocRef = doc(
          firestoredb,
          "PartnershipDonation",
          documentId,
        );
        const partnershipDocSnapshot = await getDoc(PartnershipDocRef);

        if (partnershipDocSnapshot.exists()) {
          const data = partnershipDocSnapshot.data();
          setSdgName(data.sdgName || "");
          setDonationMode(data.DonationMode || "");
          setDonationType(data.DonationType || "");
          setProjectName(data.projectName || "");
          setYear(data.year || "");
          setLocationFields(data.locationFields || []);

          console.log("Data fetched successfully:", data);
        } else {
          console.warn(`Document with ID ${documentId} does not exist.`);
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    fetchData();
  }, [documentId]);
  useEffect(() => {
    if (excludedDonationTypes.includes(DonationType)) {
      setProjectName(DonationType);
    } else {
      setProjectName("");
    }
  }, [DonationType, excludedDonationTypes]);

  // Extracting properties from locationFields
  const locationFieldsRef = locationFields[0] || {};
  const { ROFoodGroceries, ROMeds, ROHygiene,MMAlcohol,MMFacemask } = locationFieldsRef;
 
  useEffect(() => {
    const ROhygiene = parseFloat(ROHygiene) || 0;
    const ROmeds = parseFloat(ROMeds) || 0;
    const ROfoodGroceries = parseFloat(ROFoodGroceries) || 0;
    const MMalcohol = parseFloat(MMAlcohol) || 0;
    const MMfacemask = parseFloat(MMFacemask) || 0;
    const ROtotalRelief = ROhygiene + ROmeds + ROfoodGroceries + MMalcohol + MMfacemask;

    setLocationFields(prevLocationFields => [
      {
        ...prevLocationFields[0],
        ROtotalRelief: ROtotalRelief.toString()
      }
    ]);
  }, [ROFoodGroceries, ROMeds, ROHygiene ,MMAlcohol,MMFacemask ]);
  
  useEffect(() => {
    const fetchUser = async () => {
      try {
        // Get the userId from sessionStorage
        const userId = sessionStorage.getItem("userId");
        if (!userId) {
          console.log("User ID not found in sessionStorage");
          return;
        }

        // Get the user document from Firestore
        const userDocRef = doc(firestoredb, "users", userId);
        const userDocSnapshot = await getDoc(userDocRef);
        if (userDocSnapshot.exists()) {
          // Extract the user data from the user document
          const userData = userDocSnapshot.data();
          setUser(userData);
        } else {
          console.log("User not found");
        }
      } catch (error) {
        console.error("Error fetching user data:", error.message);
      }
    };

    fetchUser();
  }, []);
  const handleUpdate = async (documentId, updateData) => {
    try {
      const docRef = doc(firestoredb, "PartnershipDonation", documentId);
      await updateDoc(docRef, updateData);
      console.log("Document updated successfully.");
      localStorage.setItem("alertSeverity", "success");
      localStorage.setItem("alertMessage", "Activity updated successfully.");
      localStorage.setItem("alertopen", "true");
    } catch (error) {
      console.error("Error updating document:", error);
      localStorage.setItem("alertSeverity", "error");
      localStorage.setItem("alertMessage", "Updating activity failed.");
      localStorage.setItem("alertopen", "false");
    }
    navigate("/partnershipActivity");
  };

  const handleButtonClick = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleDelete = async () => {
    try {
      const PartnershipDocRef = doc(
        firestoredb,
        "PartnershipDonation",
        documentId,
      );
      await deleteDoc(PartnershipDocRef);
      handleCloseModal();
      navigate("/partnershipDonation");
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  return (
    <Box
    backgroundColor="white"
    padding="10px"
    margin="10px"
    borderRadius="15px"
  >
    <form onSubmit={(e) => {
        e.preventDefault(); // Prevent default form submission behavior
        const updateData = {
          // Create an object containing updated data
          sdgName,
          DonationMode,
          DonationType,
          projectName,
          year,
          locationFields,
        };
        handleUpdate(documentId, updateData); // Call handleUpdate with documentId and updateData
      }}>
      <Typography variant="h4" fontWeight="bold" margin="5px" pb="20px">
        Partnership Donation Profile
      </Typography>

      <Grid container spacing={2}>
      <Grid item xs={!excludedDonationTypes.includes(DonationType) ? 2.4 : 3}>
          <FormControl fullWidth>
            <InputLabel>SDG Name</InputLabel>
            <Select
              required
              value={sdgName}
              label="SDG Name"
              MenuProps={{
                MenuListProps: {
                  style: {
                    maxHeight: 200, // Set the maximum height of the dropdown
                  },
                },
              }}
              onChange={(e) => setSdgName(e.target.value)}
            >
              <MenuItem value="1"> 1. No Poverty</MenuItem>
              <MenuItem value="2"> 2. Zero Hunger</MenuItem>
              <MenuItem value="3"> 3. Good Health and Well-being</MenuItem>
              <MenuItem value="4"> 4. Quality Education</MenuItem>
              <MenuItem value="5"> 5. Gender Equality</MenuItem>
              <MenuItem value="6"> 6. Clean Water and Sanitation</MenuItem>
              <MenuItem value="7"> 7. Affordable and Clean Energy</MenuItem>
              <MenuItem value="8"> 8. Decent Work and Economic Growth</MenuItem>
              <MenuItem value="9"> 9. Industry, Innovation and Infrastructure</MenuItem>
              <MenuItem value="10">10. Reduced Inequalities</MenuItem>
              <MenuItem value="11">11. Sustainable Cities and Communities</MenuItem>
              <MenuItem value="12">12. Responsible Consumption and Production</MenuItem>
              <MenuItem value="13">13. Climate Action</MenuItem>
              <MenuItem value="14">14. Life Below Water</MenuItem>
              <MenuItem value="15">15. Life on Land</MenuItem>
              <MenuItem value="16">16. Peace, Justice and Strong Institutions</MenuItem>
              <MenuItem value="17">17. Partnerships for the Goals</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        
        <Grid item xs={!excludedDonationTypes.includes(DonationType) ? 2.4 : 3}>
          <FormControl fullWidth>
            <InputLabel>Mode of Donation</InputLabel>
            <Select
              value={DonationMode}
              required
              label="Mode of Donation"
              onChange={(e) => setDonationMode(e.target.value)}
            >
              <MenuItem value="Bubbles and Dreams">In-kind</MenuItem>
              <MenuItem value="Stitches to Riches">Monetary</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={!excludedDonationTypes.includes(DonationType) ? 2.4 : 3}>
          <FormControl fullWidth>
            <InputLabel>Donation Project Type</InputLabel>
            <Select
              value={DonationType}
              required
              label="Donation Project Type"
              onChange={(e) => setDonationType(e.target.value)}
            >
              <MenuItem value="Books Donation">Books Donation </MenuItem>
              <MenuItem value="Classrooms and/or Library Refurbished">Classrooms and/or Library Refurbished</MenuItem>
              <MenuItem value="Classrooms Built">Classrooms Built</MenuItem>
              <MenuItem value="Happy Feet Project"> Happy Feet Project</MenuItem>
              <MenuItem value="Houses Built">Houses Built</MenuItem>
              <MenuItem value="If There's A Wheel, There's a Way">If There's A Wheel, There's a Way</MenuItem>
              <MenuItem value="Mega Malasakit">Mega Malasakit</MenuItem>
              <MenuItem value="Relief Operations">Relief Operations</MenuItem>
              <MenuItem value="Rice Together">Rice Together</MenuItem>
              <MenuItem value="School Supplies Donation">School Supplies Donation</MenuItem>
              <MenuItem value="Solar Lamp Donation">Solar Lamp Donation</MenuItem>
              <MenuItem value="Wheelchair Donation">Wheelchair Donation</MenuItem>
              <MenuItem value="Other Project"> Other Donation Project</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {!excludedDonationTypes.includes(DonationType) && (
        <Grid item xs={!excludedDonationTypes.includes(DonationType) ? 2.4 : 3}>
            <TextField
                fullWidth
                required
                label="Donation Project Name"
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
            />
        </Grid>
    )}

        <Grid item xs={!excludedDonationTypes.includes(DonationType) ? 2.4 : 3}>
          <FormControl fullWidth required>
            <InputLabel>Year</InputLabel>
            <Select
              label="Year"
              value={year}
              MenuProps={{
                MenuListProps: {
                  style: {
                    maxHeight: 200, // Set the maximum height of the dropdown
                  },
                },
              }}
              onChange={(e) => setYear(e.target.value)}
            >
              {generateYearOptions().map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {locationFields.map((location, index) => (
          <Grid
            container
            spacing={2}
            pt="10px"
            pl="18px"
            pb="5px"
            key={index}
          >
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{ width: "100%" }}
                  fullWidth
                  label="Date"
                  value={location.date ? dayjs(location.date) : null}
                  required
                  onChange={(date) => {
                    const dateString = date
                      ? dayjs(date).format("YYYY-MM-DD")
                      : null; // Format the date as a string
                    const newFields = [...locationFields];
                    newFields[index].date = dateString;
                    setLocationFields(newFields);
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel>Township</InputLabel>
                <Select
                  label="Township"
                  value={location.township}
                  required
                  MenuProps={{
                    MenuListProps: {
                      style: {
                        maxHeight: 200, // Set the maximum height of the dropdown
                      },
                    },
                  }}
                  onChange={(e) => {
                    const newFields = [...locationFields];
                    newFields[index].township = e.target.value;
                    setLocationFields(newFields);
                  }}
                >
                  {/* Add MenuItem components for each  in the dropdown */}
                  <MenuItem value="1"> Alabang West</MenuItem>
                  <MenuItem value="2">Arcovia City</MenuItem>
                  <MenuItem value="3">Arden Botanical Estate</MenuItem>
                  <MenuItem value="4">Baytown Palawan</MenuItem>
                  <MenuItem value="5">Boracay Newcoast</MenuItem>
                  <MenuItem value="6">Capital Town</MenuItem>
                  <MenuItem value="7">Davao Park District</MenuItem>
                  <MenuItem value="8">Eastland Heights </MenuItem>
                  <MenuItem value="9">Eastwood City</MenuItem>
                  <MenuItem value="10">Forbes Town</MenuItem>
                  <MenuItem value="11">Highland City</MenuItem>
                  <MenuItem value="12">Ilo-ilo Business Park</MenuItem>
                  <MenuItem value="13">Lucky Chinatown</MenuItem>
                  <MenuItem value="14">Maple Grove</MenuItem>
                  <MenuItem value="15">Mckinley Hill</MenuItem>
                  <MenuItem value="16">Mckinley West</MenuItem>
                  <MenuItem value="17">Newport City</MenuItem>
                  <MenuItem value="18">Northill Gateway</MenuItem>
                  <MenuItem value="19">Northwin Global City</MenuItem>
                  <MenuItem value="20">Paragua Coastown</MenuItem>
                  <MenuItem value="21">Sherwood Hills</MenuItem>
                  <MenuItem value="22">Southwoods City</MenuItem>
                  <MenuItem value="23">Sta Barbara Heights</MenuItem>
                  <MenuItem value="24">Suntrust Ecotown</MenuItem>
                  <MenuItem value="25">The Hamptons Caliraya</MenuItem>
                  <MenuItem value="26">The Mactan Newtown</MenuItem>
                  <MenuItem value="27">The Upper East</MenuItem>
                  <MenuItem value="28">Twin Lakes</MenuItem>
                  <MenuItem value="29">Uptown Bonifacio</MenuItem>
                  <MenuItem value="30">Westside City</MenuItem>
                  <MenuItem value="31">Winford Resort Estate</MenuItem>
                  <MenuItem value="32">Others</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              {/* Render a TextField for Location if Township is "Others" */}
              {location.township === "4" ||
              location.township === "21" ||
              location.township === "31" ||
              location.township === "32" ? (
                <TextField
                  fullWidth
                  label="Barangay"
                  value={location.location}
                  required
                  onChange={(e) => {
                    const newFields = [...locationFields];
                    newFields[index].location = e.target.value;
                    setLocationFields(newFields);
                  }}
                />
              ) : (
                <FormControl fullWidth>
                  <InputLabel>Barangay</InputLabel>
                  <Select
                    label="Barangay"
                    value={location.location}
                    required
                    MenuProps={{
                      MenuListProps: {
                        style: {
                          maxHeight: 200, // Set the maximum height of the dropdown
                        },
                      },
                    }}
                    onChange={(e) => {
                      const newFields = [...locationFields];
                      newFields[index].location = e.target.value;
                      setLocationFields(newFields);
                    }}
                  >
                    {townshipLocationsMapping[location.township]?.map(
                      (loc, locIndex) => (
                        <MenuItem key={locIndex} value={loc}>
                          {loc}
                        </MenuItem>
                      ),
                    )}
                    <MenuItem value="Others">Others</MenuItem>
                  </Select>
                </FormControl>
              )}
            </Grid>

            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Number of Beneficiaries"
                value={location.beneficiaries}
                required
                onChange={(e) => {
                  const newFields = [...locationFields];
                  const numericValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                  newFields[index].beneficiaries = numericValue;
                  setLocationFields(newFields);
                }}
              />
            </Grid>
          

            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Proposed Budget"
                value={location.budget}
                required
                onChange={(e) => {
                  const input = e.target.value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters
                  const newFields = [...locationFields];
                  newFields[index].budget = input;

                  setLocationFields(newFields);
                }}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                InputProps={{
                  startAdornment: location.budget && !isFocused && (
                    <InputAdornment position="start">
                      <Typography variant="h6" color="textSecondary">
                        P
                      </Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Actual Expense"
                value={location.expenses}
                required
                onChange={(e) => {
                  const input = e.target.value.replace(/[^0-9.]/g, "");
                  const newFields = [...locationFields];
                  newFields[index].expenses = input;

                  setLocationFields(newFields);
                }}
                onFocus={() => setIsFocused1(true)}
                onBlur={() => setIsFocused1(false)}
                InputProps={{
                  startAdornment: location.expenses && !isFocused1 && (
                    <InputAdornment position="start">
                      <Typography variant="h6" color="textSecondary">
                        P
                      </Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            {DonationType === "Books Donation" && (
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    required
                    label="Number of Books Donated"
                    value={location.DonatedBooks}
                    onChange={(e) => {
                      const input = e.target.value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters
                      const newFields = [...locationFields];
                      newFields[index].DonatedBooks = input;
                      setLocationFields(newFields);
                    }}
                />
            </Grid>
         )}
         {DonationType === "Classrooms Built" && (
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    required
                    label="Number of Classrooms Built"
                    value={location.ClassroomsBuilt}
                    onChange={(e) => {
                      const input = e.target.value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters
                      const newFields = [...locationFields];
                      newFields[index].ClassroomsBuilt = input;
                      setLocationFields(newFields);
                    }}
                />
            </Grid>
         )}
         
         {DonationType === "Classrooms Built" && (
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    required
                    label="Number of Libraries Built"
                    value={location.LibrariesBuilt}
                    onChange={(e) => {
                      const input = e.target.value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters
                      const newFields = [...locationFields];
                      newFields[index].LibrariesBuilt = input;
                      setLocationFields(newFields);
                    }}
                />
            </Grid>
         )}
          {DonationType === "Classrooms and/or Library Refurbished" && (
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    required
                    label="Number of Classrooms Refurbished"
                    value={location.ClassroomsRefurb}
                    onChange={(e) => {
                      const input = e.target.value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters
                      const newFields = [...locationFields];
                      newFields[index].ClassroomsRefurb = input;
                      setLocationFields(newFields);
                    }}
                />
            </Grid>
         )}
         {DonationType === "Houses Built" && (
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    required
                    label="Number of Houses Built"
                    value={location.HousesBuilt}
                    onChange={(e) => {
                      const input = e.target.value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters
                      const newFields = [...locationFields];
                      newFields[index].HousesBuilt = input;
                      setLocationFields(newFields);
                    }}
                />
            </Grid>
         )}
         {DonationType === "School Supplies Donation" && (
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    required
                    label="Number of School Supplies Distributed"
                    value={location.SuppliesDistrib}
                    onChange={(e) => {
                      const input = e.target.value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters
                      const newFields = [...locationFields];
                      newFields[index].SuppliesDistrib= input;
                      setLocationFields(newFields);
                    }}
                />
            </Grid>
         )}
          {DonationType === "Solar Lamp Donation" && (
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    required
                    label="Number of Solar Lamp Distributed"
                    value={location.SolarLamp}
                    onChange={(e) => {
                      const input = e.target.value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters
                      const newFields = [...locationFields];
                      newFields[index].SolarLamp= input;
                      setLocationFields(newFields);
                    }}
                />
            </Grid>
         )}
          {DonationType === "Wheelchair Donation" && (
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    required
                    label="Number of Wheelchair Donated"
                    value={location.WheelchairDonated}
                    onChange={(e) => {
                      const input = e.target.value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters
                      const newFields = [...locationFields];
                      newFields[index].WheelchairDonated= input;
                      setLocationFields(newFields);
                    }}
                />
            </Grid>
         )}

         {DonationType === "Happy Feet Project" && (
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    required
                    label="Pairs of Slippers Distributed"
                    value={location.PairSlippers}
                    onChange={(e) => {
                      const input = e.target.value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters
                      const newFields = [...locationFields];
                      newFields[index].PairSlippers= input;
                      setLocationFields(newFields);
                    }}
                />
            </Grid>
         )}
          {DonationType === "If There's A Wheel, There's a Way" && (
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    required
                    label="Number of Bikes Distibuted"
                    value={location.BikesDistrib}
                    onChange={(e) => {
                      const input = e.target.value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters
                      const newFields = [...locationFields];
                      newFields[index].BikesDistrib= input;
                      setLocationFields(newFields);
                    }}
                />
            </Grid>
         )}
         {DonationType === "Rice Together" && (
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    required
                    label="Kilos of Rice Donated"
                    value={location.RiceDonated}  
                    onChange={(e) => {
                      const input = e.target.value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters
                      const newFields = [...locationFields];
                      newFields[index].RiceDonated= input;
                      setLocationFields(newFields);
                    }}
                />
            </Grid>
         )}

        {DonationType === "Relief Operations" && (
              <Grid container spacing={2} ml = "2px" mt = "2px">
                <Grid item xs={2.4}>
                <Autocomplete
                  freeSolo
                  required
                  options={["Earthquake","Fire", "Pandemic","Typhoon", "Volcanic Eruption"]}
                  inputValue={locationFields[index].typedisaster} // Use the value from locationFields
                  onInputChange={(event, newValue) => {
                    const newFields = [...locationFields];
                    newFields[index].typedisaster = newValue;
                    setLocationFields(newFields);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Type of Disaster/Calamity" />
                  )}
                />
              </Grid>
              <Grid item xs={2.4}>
              <TextField
                fullWidth
                label="Food and Groceries"
                value={location.ROFoodGroceries}
                
                onChange={(e) => {
                  const newFields = [...locationFields];
                  const numericValue = e.target.value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters
                  newFields[index].ROFoodGroceries= numericValue;
                  setLocationFields(newFields);
                }}
              />
            </Grid>
            <Grid item xs={2.4}>
              <TextField
                fullWidth
                label="Medicines"
                value={location.ROMeds}
                
                onChange={(e) => {
                  const newFields = [...locationFields];
                  const numericValue = e.target.value.replace(/[^0-9.]/g, "");// Remove non-numeric characters
                  newFields[index].ROMeds = numericValue;
                  setLocationFields(newFields);
                }}
              />
            </Grid>
            <Grid item xs={2.4}>
              <TextField
                fullWidth
                label="Hygiene Kits"
                value={location.ROHygiene}
                
                onChange={(e) => {
                  const newFields = [...locationFields];
                  const numericValue = e.target.value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters
                  newFields[index].ROHygiene = numericValue;
                  setLocationFields(newFields);
                }}
              />
            </Grid>
            
            <Grid item xs={2.4}>
              <TextField
                fullWidth
                label="Total of Relief Packages Packed and Distributed"
                value={location.ROtotalRelief}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

              </Grid>
            )}

          {DonationType === "Mega Malasakit" && (
              <Grid container spacing={2} ml = "2px" mt = "2px">
              <Grid item xs={2.4}>
              <TextField
                fullWidth
                label="Bottle of Alcohols"
                value={location.MMAlcohol}
                required
                onChange={(e) => {
                  const newFields = [...locationFields];
                  const numericValue = e.target.value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters
                  newFields[index].MMAlcohol = numericValue;
                  setLocationFields(newFields);
                }}
              />
            </Grid>
            <Grid item xs={2.4}>
              <TextField
                fullWidth
                label="Facemask"
                value={location.MMFacemask}
                required
                onChange={(e) => {
                  const newFields = [...locationFields];
                  const numericValue = e.target.value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters
                  newFields[index].MMFacemask = numericValue;
                  setLocationFields(newFields);
                }}
              />
            </Grid>
              <Grid item xs={2.4}>
              <TextField
                fullWidth
                label="Food and Groceries"
                value={location.ROFoodGroceries}
                required
                onChange={(e) => {
                  const newFields = [...locationFields];
                  const numericValue = e.target.value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters
                  newFields[index].ROFoodGroceries= numericValue;
                  setLocationFields(newFields);
                }}
              />
            </Grid>
            <Grid item xs={2.4}>
              <TextField
                fullWidth
                label="Medicines"
                value={location.ROMeds}
                required
                onChange={(e) => {
                  const newFields = [...locationFields];
                  const numericValue = e.target.value.replace(/[^0-9.]/g, "");// Remove non-numeric characters
                  newFields[index].ROMeds = numericValue;
                  setLocationFields(newFields);
                }}
              />
            </Grid>
            <Grid item xs={2.4}>
              <TextField
                fullWidth
                label="Total of Relief Packages Packed and Distributed"
                value={location.ROtotalRelief}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

              </Grid>
            )}

            <Grid item xs={4}>
              <Autocomplete
                options={PartnerMapping}
                freeSolo
                value=""
                inputValue={inputValue}
                onChange={(event, newValue) => {
                  if (newValue) {
                    const newFields = [...locationFields];
                    const isDuplicate =
                      newFields[index].partners.includes(newValue);
                    if (!isDuplicate) {
                      const updatedPartners = [
                        ...newFields[index].partners,
                        newValue,
                      ];
                      newFields[index].partners = updatedPartners;
                      setLocationFields(newFields);
                      setInputValue(""); // Clear the input value after successfully adding the chip
                    }
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue); // Update input value as user types
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Partner Organizations" />
                )}
              />
            </Grid>
            <Grid item xs={12} container alignItems="center">
              {location.partners.map((partner, partnerIndex) => (
                <Chip
                  key={partnerIndex}
                  label={partner}
                  onDelete={() => {
                    const newFields = [...locationFields];
                    const updatedPartners = newFields[index].partners.filter(
                      (p, i) => i !== partnerIndex,
                    );
                    newFields[index].partners = updatedPartners;
                    setLocationFields(newFields);
                  }}
                  style={{ margin: "4px", fontSize: "16px" }}
                  variant="outlined"
                  color="success"
                />
              ))}
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Description"
                value={location.description}
                onChange={(e) => {
                  const newFields = [...locationFields];
                  newFields[index].description = e.target.value;
                  setLocationFields(newFields);
                }}
              />
            </Grid>

              <Grid item xs={0.5} display="flex" mb="10px" ml="1px">
                {index === 0 && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddLocation}
                  >
                    <AddIcon style={{ fontWeight: "bold" }} />
                  </Button>
                )}

                {index !== 0 && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleRemoveLocation(index)}
                  >
                    <RemoveIcon style={{ fontWeight: "bold" }} />
                  </Button>
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: "10px",
            marginLeft: "3px",
            marginTop: "5px",
          }}
        >
          <div>
            {user &&
              (user.role === "Owner" || user.role === "Administrator") && (
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{ marginRight: "10px" }}
                >
                  Update
                </Button>
              )}
            <Button variant="outlined" color="primary" onClick={handleCancel}>
              Go Back
            </Button>
          </div>
          {user && user.role === "Owner" && (
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#eb584d",
                color: "#fff",
                "&:hover": { backgroundColor: "#cc180a" },
                mr: "3px",
              }}
              onClick={handleButtonClick}
            >
              <DeleteIcon />
            </Button>
          )}
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-40%, -60%)",
                borderRadius: "5px",
                width: 400,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 2,
              }}
            >
              <Typography
                id="modal-modal-title"
                variant="h4"
                sx={{ fontWeight: "600", marginTop: "10px" }}
              >
                Delete
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                Are you sure you want to delete this activity?
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "right",
                  marginTop: "5px",
                }}
              >
                <Button
                  onClick={handleCloseModal}
                  sx={{
                    border: "1px solid #c2c2c2",
                    textTransform: "none",
                    marginRight: "10px",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleDelete}
                  sx={{
                    textTransform: "none",
                    backgroundColor: "#eb584d",
                    color: "#fff",
                    "&:hover": { backgroundColor: "#cc180a" },
                  }}
                >
                  Yes, Delete
                </Button>
              </div>
            </Box>
          </Modal>
        </div>
      </form>
    </Box>
  );
};

export default PartDonationform;
