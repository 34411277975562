import React from "react";
import { Button, Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const SecondTopBar = () => {
  return (
    <Box>
      <Box
        backgroundColor=" #fff"
        display="flex"
        flexDirection="row"
        marginTop="3px"
        height="80px"
      >
        <Box height="100%" backgroundColor="#849F77">
          <Button
            component={Link}
            to="/dashboard"
            style={{
              backgroundColor: "#ADBDA3",
              borderRadius: "0px 50px 0px 0px",
              width: "220px", // Set a fixed width for all buttons
              height: "100%",
              display: "flex",

              // Align text to the left
            }}
          >
            <Typography
              variant="h6"
              style={{ fontWeight: 900, fontSize: "30px", color: "#FFFFFF" }}
            >
              Overall
            </Typography>
          </Button>
        </Box>

        <Box height=" 100%" backgroundColor="#58827E">
          <Button
            component={Link}
            to="/dashScholarship"
            style={{
              backgroundColor: "#849F77",
              borderRadius: "0px 50px 0px 0px",
              width: "260px", // Set a fixed width for all buttons
              height: " 100%",
              paddingRight: "20px",
            }}
          >
            <Typography
              variant="h6"
              style={{ fontWeight: 900, fontSize: "30px", color: "#FFFFFF" }}
            >
              Scholarship
            </Typography>
          </Button>
        </Box>
        <Box height=" 100%" backgroundColor="#4B6856">
          <Button
            component={Link}
            to="/dashVolunteer"
            style={{
              backgroundColor: "#58827E",
              borderRadius: "0px 50px 0px 0px",
              width: "280px",
              height: " 100%",
              paddingRight: "20px",
            }}
          >
            <Typography
              variant="h6"
              style={{
                fontWeight: 900,
                fontSize: "30px",
                color: "#FFFFFF",
                textAlign: "left",
              }}
            >
              Volunteerism
            </Typography>
          </Button>
        </Box>

        <Box height=" 100%">
          <Button
            component={Link}
            to="/dashPartnership"
            style={{
              backgroundColor: "#4B6856",
              borderRadius: "0px 50px 0px 0px",
              width: "250px", // Set a fixed width for all buttons
              height: " 100%",
              paddingRight: "20px",
            }}
          >
            <Typography
              variant="h6"
              style={{ fontWeight: 900, fontSize: "30px", color: "#FFFFFF" }}
            >
              Partnership
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SecondTopBar;
