import React, { useEffect, useState } from "react";
import { Alert } from "@mui/material";

function CustomAlert() {
  const [visible, setVisible] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");

  useEffect(() => {
    const storedSeverity = localStorage.getItem("alertSeverity");
    const storedMessage = localStorage.getItem("alertMessage");

    if (storedSeverity && storedMessage) {
      setSeverity(storedSeverity);
      setMessage(storedMessage);
      setVisible(true);

      // Clear the stored alert data after displaying the alert
      localStorage.removeItem("alertSeverity");
      localStorage.removeItem("alertMessage");
    }
  }, []);

  useEffect(() => {
    // Automatically hide the alert after 2 seconds
    const timer = setTimeout(() => {
      setVisible(false);
    }, 5000);

    // Clear the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);
  const alertStyle = {
    position: "fixed",
    bottom: "3%",
    left: "7%",
    transform: "translateX(-50%)",
    zIndex: 9999, // Ensure the alert appears above other content
  };
  return (
    <>
      {visible && (
        <div style={alertStyle}>
          <Alert severity={severity} variant="filled">
            {message}
          </Alert>
        </div>
      )}
    </>
  );
}

export default CustomAlert;
