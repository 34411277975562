import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import SecondTopBar from "../SecondTopBar";
import { firestoredb } from "../../firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import BookIcon from "@mui/icons-material/Book";
import FormatPaintIcon from "@mui/icons-material/FormatPaint";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import RedeemIcon from "@mui/icons-material/Redeem";
import ForestIcon from "@mui/icons-material/Forest";
import { townshipLocationsMapping } from "../../Data/TownshipLocationsMapping";


const DashVolunteer = () => {
  const [totalActivities, settotalActivities] = useState(0);
  const [totalVolunteers, settotalVolunteers] = useState(0);
  const [totalhoursRendered, settotalhoursRendered] = useState(0);
  const [totalBeneficiaries, settotalBeneficiaries] = useState(0);
  const [totalBarangay, settotalBarangay] = useState(0);
  const [totalExpenses, settotalExpenses] = useState(0.0);
  const [yearfrom, setYearfrom] = useState("");
  const [yearto, setYearto] = useState("");

  const [BEPainted, setBEPainted] = useState(0);
  const [BEBeneficiaries, setBEBeneficiaries] = useState(0);
  const [EBAssisted, setEBAssisted] = useState(0);
  const [FAfed, setFAfed] = useState(0);
  const [FAserved, setFAserved] = useState(0);
  const [MMpatients, setMMpatients] = useState(0);
  const [SShelped, setSShelped] = useState(0);
  const [TPplanted, setTPplanted] = useState(0);
  const [TPHectares, setTPhectares] = useState(0);
  const [expanded, setExpanded] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
    10: false,
  });
  const [MEtotalActivities, setMEtotalActivities] = useState(0);
  const [MEtotalVolunteers, setMEtotalVolunteers] = useState(0);
  const [MEtotalhoursRendered, setMEtotalhoursRendered] = useState(0);
  const [MEtotalBeneficiaries, setMEtotalBeneficiaries] = useState(0);
  const [MEtotalBarangay, setMEtotalBarangay] = useState(0);
  const [MEtotalExpenses, setMEtotalExpenses] = useState(0.0);
  const [MEBEPainted, setMEBEPainted] = useState(0);
  const [MEBEBeneficiaries, setMEBEBeneficiaries] = useState(0);
  const [MEEBAssisted, setMEEBAssisted] = useState(0);
  const [MEFAfed, setMEFAfed] = useState(0);
  const [MEFAserved, setMEFAserved] = useState(0);
  const [MEMMpatients, setMEMMpatients] = useState(0);

  useEffect(() => {
    const getEventData = async () => {
      try {
        const volunteerismCollection = collection(
          firestoredb,
          "VolunteerismActivities",
        );
        const snapshot = await getDocs(volunteerismCollection);

        let totalActivitiesCount = 0;
        let totalVolunteersCount = 0;
        let totalhoursRendered = 0;
        let totalBeneficiariesCount = 0;
        let totalBarangayCount = 0;
        let totalExpensesCount = 0;
        let totalBEPainted = 0;
        let totalBEBeneficiaries = 0;
        let totalEBAssisted = 0;
        let totalFAfed = 0;
        let totalFAserved = 0;
        let totalMMpatients = 0;
        let totalSShelped = 0;
        let totalTPplanted = 0;
        let totalTPHectares = 0;

        let MEtotalActivitiesCount = 0;
        let MEtotalVolunteersCount = 0;
        let MEtotalhoursRendered = 0;
        let MEtotalBeneficiariesCount = 0;
        let MEtotalBarangayCount = 0;
        let MEtotalExpensesCount = 0;
        let MEtotalBEPainted = 0;
        let MEtotalBEBeneficiaries = 0;
        let MEtotalEBAssisted = 0;
        let MEtotalFAfed = 0;
        let MEtotalFAserved = 0;
        let MEtotalMMpatients = 0;

        let uniqueBarangays = new Set();
        let MEuniqueBarangays = new Set();

        snapshot.forEach((doc) => {
          const data = doc.data();
          const year = data.year;

          // Check if the data has locationFields and if the year is within the specified range
          if (
            (!yearfrom || yearfrom === "" || year >= yearfrom) &&
            (!yearto || yearto === "" || year <= yearto)
          ) {
            data.locationFields.forEach((locationField) => {
              //Activities
              if (locationField.date) {
                totalActivitiesCount++;
              }
              // Volunteers
              const VolunteersCount = parseInt(locationField.volunteers || 0);
              totalVolunteersCount += VolunteersCount;

              // Orgs
              const hoursRendered = parseInt(
                locationField.hoursRendered || 0,
                10,
              );
              totalhoursRendered += hoursRendered;

              // Beneficiaries
              const BeneficiariesCount = parseInt(
                locationField.beneficiaries || 0,
                10,
              );
              totalBeneficiariesCount += BeneficiariesCount;

              // Barangay
              if (locationField.location) {
                const barangays = townshipLocationsMapping[locationField.location];
                if (barangays) {
                  barangays.forEach(barangay => {
                    if (barangay.trim()) {
                      uniqueBarangays.add(barangay.trim());
                    }
                  });
                } else {
                  uniqueBarangays.add(locationField.location);
                }
              }
              totalBarangayCount = uniqueBarangays.size;

              // Expenses
              const ExpensesCount = parseFloat(locationField.expenses || 0, 10);
              totalExpensesCount += ExpensesCount;

              //accordions
              if (data.activityType === "Brigada Eskwela") {
                const BEPainted = parseInt(locationField.BEpainted || 0, 10);
                totalBEPainted += BEPainted;
                const BEBeneficiaries = parseInt(
                  locationField.beneficiaries || 0,
                  10,
                );
                totalBEBeneficiaries += BEBeneficiaries;
              }
              if (data.activityType === "Eskwela ng Bayan") {
                const EBAssisted = parseInt(locationField.EBlearners || 0, 10);
                totalEBAssisted += EBAssisted;
              }
              if (data.activityType === "Feeding Program") {
                const FAfed = parseInt(locationField.beneficiaries || 0, 10);
                totalFAfed += FAfed;
                const FAserved = parseInt(locationField.FPmeals || 0, 10);
                totalFAserved += FAserved;
              }
              if (data.activityType === "Medical-Dental Mission") {
                const MMpatients = parseInt(
                  locationField.beneficiaries || 0,
                  10,
                );
                totalMMpatients += MMpatients;
              }
              if (data.activityType === "Santa Scholars") {
                const SShelped = parseInt(locationField.beneficiaries || 0, 10);
                totalSShelped += SShelped;
              }
              if (data.activityType === "Tree Planting") {
                const TPplanted = parseInt(locationField.TPplanted || 0, 10);
                totalTPplanted += TPplanted;
                const TPHectares = parseInt(locationField.TPhectares || 0, 10);
                totalTPHectares += TPHectares;
              }
              //end of accordions

              if (data.MeGreenValue === "MeGreen") {
                if (locationField.date) {
                  MEtotalActivitiesCount++;
                }
                // Volunteers
                const MEVolunteersCount = parseInt(
                  locationField.volunteers || 0,
                );
                MEtotalVolunteersCount += MEVolunteersCount;

                // Orgs
                const MEhoursRendered = parseInt(
                  locationField.hoursRendered || 0,
                  10,
                );
                MEtotalhoursRendered += MEhoursRendered;

                // Beneficiaries
                const MEBeneficiariesCount = parseInt(
                  locationField.beneficiaries || 0,
                  10,
                );
                MEtotalBeneficiariesCount += MEBeneficiariesCount;

                // Barangay
                if (locationField.township) {
                  MEtotalBarangayCount++;
                }
                if (locationField.location) {
                  const barangays = townshipLocationsMapping[locationField.location];
                  if (barangays) {
                    barangays.forEach(barangay => {
                      if (barangay.trim()) {
                        MEuniqueBarangays.add(barangay.trim());
                      }
                    });
                  } else {
                    MEuniqueBarangays.add(locationField.location);
                  }
                }
                MEtotalBarangayCount = MEuniqueBarangays.size;
  
                // Expenses
                const MEExpensesCount = parseFloat(
                  locationField.expenses || 0,
                  10,
                );
                MEtotalExpensesCount += MEExpensesCount;

                // Check if activityType is 'Brigada Eskwela' and update counts accordingly
                if (data.activityType === "Brigada Eskwela") {
                  const MEBEPainted = parseInt(
                    locationField.BEpainted || 0,
                    10,
                  );
                  MEtotalBEPainted += MEBEPainted;
                  const MEBEBeneficiaries = parseInt(
                    locationField.beneficiaries || 0,
                    10,
                  );
                  MEtotalBEBeneficiaries += MEBEBeneficiaries;
                }

                // Check if activityType is 'Eskwela ng Bayan' and update counts accordingly
                if (data.activityType === "Eskwela ng Bayan") {
                  const MEEBAssisted = parseInt(
                    locationField.EBlearners || 0,
                    10,
                  );
                  MEtotalEBAssisted += MEEBAssisted;
                }

                // Check if activityType is 'Feeding Program' and update counts accordingly
                if (data.activityType === "Feeding Program") {
                  const MEFAfed = parseInt(
                    locationField.beneficiaries || 0,
                    10,
                  );
                  MEtotalFAfed += MEFAfed;
                  const MEFAserved = parseInt(locationField.FPmeals || 0, 10);
                  MEtotalFAserved += MEFAserved;
                }

                // Check if activityType is 'Medical-Dental Mission' and update counts accordingly
                if (data.activityType === "Medical-Dental Mission") {
                  const MEMMpatients = parseInt(
                    locationField.beneficiaries || 0,
                    10,
                  );
                  MEtotalMMpatients += MEMMpatients;
                }
              }
            });
          }
        });

        // Set the state variables
        settotalActivities(totalActivitiesCount);
        settotalVolunteers(totalVolunteersCount);
        settotalhoursRendered(totalhoursRendered);
        settotalBeneficiaries(totalBeneficiariesCount);
        settotalBarangay(totalBarangayCount);
        settotalExpenses(totalExpensesCount);

        setBEPainted(totalBEPainted);
        setBEBeneficiaries(totalBEBeneficiaries);
        setEBAssisted(totalEBAssisted);
        setFAfed(totalFAfed);
        setFAserved(totalFAserved);
        setMMpatients(totalMMpatients);
        setSShelped(totalSShelped);
        setTPplanted(totalTPplanted);
        setTPhectares(totalTPHectares);

        setMEtotalActivities(MEtotalActivitiesCount);
        setMEtotalVolunteers(MEtotalVolunteersCount);
        setMEtotalhoursRendered(MEtotalhoursRendered);
        setMEtotalBeneficiaries(MEtotalBeneficiariesCount);
        setMEtotalBarangay(MEtotalBarangayCount);
        setMEtotalExpenses(MEtotalExpensesCount);
        setMEBEPainted(MEtotalBEPainted);
        setMEBEBeneficiaries(MEtotalBEBeneficiaries);
        setMEEBAssisted(MEtotalEBAssisted);
        setMEFAfed(MEtotalFAfed);
        setMEFAserved(MEtotalFAserved);
        setMEMMpatients(MEtotalMMpatients);
      } catch (error) {
        console.error("Error fetching Volunteerism Activities:", error);
      }
    };

    // Call the function to fetch data
    getEventData();
  }, [yearfrom, yearto]);

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear(); // Get the current year
    const startYear = 1997; // Start year
    const yearOptions = [];

    // Loop from the start year to the current year
    for (let i = currentYear; i >= startYear; i--) {
      yearOptions.push(i.toString()); // Push each year as a string to the options array
    }

    return yearOptions; // Return the array of year options
  };
  const handleYearFromChange = (e) => {
    const selectedYearFrom = e.target.value;

    // Set yearfrom based on the selected value
    setYearfrom(selectedYearFrom);

    // If "Overall" is selected, clear both yearfrom and yearto
    if (selectedYearFrom === "") {
      setYearto(""); // Clear the value in yearto
    } else if (yearto && selectedYearFrom > yearto) {
      // If the selected value of "Year To" is smaller than "Year From", update "Year To" to match "Year From"
      setYearto(selectedYearFrom);
    }
  };

  // Event handler for "Year To" change
  const handleYearToChange = (e) => {
    // If the selected value of "Year To" is smaller than "Year From", prevent updating "Year To"
    setYearto(e.target.value);
    if (yearfrom === "") {
      setYearto("2024");
      return;
    }
    if (yearfrom && e.target.value < yearfrom) {
      return;
    }
  };

  const handleChangeExp = (index) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [index]: !prevExpanded[index],
    }));
  };

  return (
    <Box>
      <SecondTopBar />
      <Box
        borderTop="15px solid #58827E"
        maxHeight="750px"
        height="calc(100vh - 25px)"
        width="auto%"
      >
        <Box
          marginRight="25px"
          marginTop="15px"
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <FormControl style={{ width: "100px", height: "25px" }}>
            <InputLabel>Year From:</InputLabel>
            <Select
              style={{ height: "35px" }}
              label="Year From"
              value={yearfrom}
              MenuProps={{
                MenuListProps: {
                  style: {
                    maxHeight: 150, // Set the maximum height of the dropdown
                  },
                },
              }}
              onChange={handleYearFromChange}
            >
              <MenuItem value="">Overall</MenuItem>
              {generateYearOptions().map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {yearfrom && yearfrom !== "" && (
            <FormControl
              style={{ marginLeft: "20px", width: "100px", height: "25px" }}
            >
              <InputLabel>Year to</InputLabel>
              <Select
                style={{ height: "35px" }}
                label="Year to"
                value={yearto}
                MenuProps={{
                  MenuListProps: {
                    style: {
                      maxHeight: 150, // Set the maximum height of the dropdown
                    },
                  },
                }}
                onChange={handleYearToChange}
              >
                {generateYearOptions().map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Box>
        <Box margin="25px" display="flex">
          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #58827E"
            maxWidth=" 230px"
            width="100%"
            height="120px"
            backgroundColor="#58827E"
            borderRadius="15px"
            paddingLeft="10px"
            marginRight="25px"
            display="flex"
            flexDirection="column"
            justifyContent="center" // Center items vertically
          >
            <Typography
              fontSize="45px"
              fontWeight="900"
              color="#f6f6f6"
              marginBottom="-15px"
            >
              {totalActivities.toLocaleString()}
            </Typography>
            <Typography fontSize="25px" fontWeight="700" color="#f6f6f6">
              activities
            </Typography>
          </Box>
          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #58827E"
            maxWidth=" 230px"
            width="100%"
            height="120px"
            backgroundColor="#58827E"
            borderRadius="15px"
            paddingLeft="10px"
            marginRight="25px"
            display="flex"
            flexDirection="column"
            justifyContent="center" // Center items vertically
          >
            <Typography
              fontSize="45px"
              fontWeight="900"
              color="#f6f6f6"
              marginBottom="-15px"
            >
              {totalVolunteers.toLocaleString()}
            </Typography>
            <Typography fontSize="25px" fontWeight="700" color="#f6f6f6">
              volunteers
            </Typography>
          </Box>
          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #58827E"
            maxWidth=" 230px"
            width="100%"
            height="120px"
            backgroundColor="#58827E"
            borderRadius="15px"
            paddingLeft="10px"
            marginRight="25px"
            paddingBottom="0px"
            display="flex"
            flexDirection="column"
            justifyContent="center" // Center items vertically
          >
            <Typography
              fontSize="45px"
              fontWeight="900"
              color="#f6f6f6"
              marginBottom="-15px"
            >
              {totalhoursRendered.toLocaleString()}
            </Typography>
            <Typography fontSize="25px" fontWeight="700" color="#f6f6f6">
              volunteer hours
            </Typography>
          </Box>

          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #58827E"
            maxWidth=" 230px"
            width="100%"
            height="120px"
            backgroundColor="#58827E"
            borderRadius="15px"
            paddingLeft="10px"
            marginRight="25px"
            display="flex"
            flexDirection="column"
            justifyContent="center" // Center items vertically
          >
            <Typography
              fontSize="45px"
              fontWeight="900"
              color="#f6f6f6"
              marginBottom="-15px"
            >
              {totalBeneficiaries.toLocaleString()}
            </Typography>
            <Typography fontSize="25px" fontWeight="700" color="#f6f6f6">
              beneficiaries
            </Typography>
          </Box>
          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #58827E"
            maxWidth=" 250px"
            width="100%"
            height="120px"
            backgroundColor="#58827E"
            borderRadius="15px"
            paddingLeft="10px"
            paddingBottom="10px"
            marginRight="25px"
            display="flex"
            flexDirection="column"
            justifyContent="center" // Center items vertically
          >
            <Typography
              fontSize="45px"
              fontWeight="900"
              color="#f6f6f6"
              marginBottom="-8px"
            >
              {totalBarangay.toLocaleString()}
            </Typography>
            <Typography
              fontSize="25px"
              fontWeight="700"
              color="#f6f6f6"
              lineHeight="1"
            >
              barangays reached
            </Typography>
          </Box>
          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #58827E"
            maxWidth=" 500px"
            width="100%"
            height="120px"
            backgroundColor="#58827E"
            borderRadius="15px"
            paddingLeft="10px"
            display="flex"
            flexDirection="column"
            justifyContent="center" // Center items vertically
          >
            <Typography
              fontSize="45px"
              fontWeight="900"
              display="flex"
              alignItems="flex-end"
              color="#f6f6f6"
              marginBottom="-15px"
            >
              <span style={{ marginRight: "5px" }}>P</span>{" "}
              {totalExpenses.toLocaleString()}
            </Typography>
            <Typography fontSize="25px" fontWeight="700" color="#f6f6f6">
              expenses
            </Typography>
          </Box>
        </Box>
        <Box marginLeft="25px" display="flex" flexDirection="row">
          <Accordion
            expanded={expanded[1]}
            onChange={() => handleChangeExp(1)}
            style={{
              maxWidth: "300px",
              width: "100%",
              maxHeight: "200px",
              height: "100%",
              borderRadius: "15px",
              marginRight: "25px",
              backgroundColor: "#58827E",
              borderTop: "1px solid #58827E",
            }}
          >
            <AccordionSummary style={{ height: "20px" }}>
              <Typography
                fontSize="20px"
                fontWeight="700"
                color="#f6f6f6"
                display="flex"
                alignItems="center"
              >
                <FormatPaintIcon style={{ marginRight: "10px" }} /> Brigada
                Eskwela
              </Typography>
            </AccordionSummary>

            <AccordionDetails
              style={{
                minHeight: expanded ? "120px" : "50px", // Adjust the minHeight here
                display: "flex",
                alignItems: "center",
                backgroundColor: "#58827E",
                borderRadius: "0px 0px 15px 15px",
                marginTop: "-30px",
              }}
            >
              <Typography fontSize="30px" fontWeight="900" color="#f6f6f6">
                {BEPainted.toLocaleString()}
              </Typography>
              <Typography
                fontSize="15px"
                fontWeight="700"
                color="#f6f6f6"
                marginLeft="15px"
                lineHeight="1"
              >
                classrooms repainted
              </Typography>
            </AccordionDetails>

            <AccordionDetails
              style={{
                minHeight: expanded ? "100px" : "50px", // Adjust the minHeight here
                display: "flex",
                alignItems: "center",
                backgroundColor: "#58827E",
                borderRadius: "0px 0px 15px 15px",
                marginTop: "-50px",
              }}
            >
              <Typography fontSize="30px" fontWeight="900" color="#f6f6f6">
                {BEBeneficiaries.toLocaleString()}
              </Typography>
              <Typography
                fontSize="15px"
                fontWeight="700"
                color="#f6f6f6"
                marginLeft="15px"
                lineHeight="1"
              >
                student beneficiaries
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded[2]}
            onChange={() => handleChangeExp(2)}
            style={{
              maxWidth: "300px",
              width: "100%",
              maxHeight: "200px",
              margin: "0px",
              height: "100%",
              borderRadius: "15px",
              marginRight: "25px",
              backgroundColor: "#58827E",
            }}
          >
            <AccordionSummary style={{ height: "20px" }}>
              <Typography
                fontSize="20px"
                fontWeight="700"
                color="#f6f6f6"
                display="flex"
                alignItems="center"
              >
                <BookIcon style={{ marginRight: "10px" }} /> Eskwela ng Bayan
              </Typography>
            </AccordionSummary>

            <AccordionDetails
              style={{
                minHeight: expanded ? "170px" : "50px", // Adjust the minHeight here
                display: "flex",
                alignItems: "center",
                backgroundColor: "#58827E",
                borderRadius: "0px 0px 15px 15px",
                marginTop: "-30px",
              }}
            >
              <Typography fontSize="30px" fontWeight="900" color="#f6f6f6">
                {EBAssisted.toLocaleString()}
              </Typography>
              <Typography
                fontSize="15px"
                fontWeight="700"
                color="#f6f6f6"
                marginLeft="15px"
                lineHeight="1"
              >
                students assisted
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded[3]}
            onChange={() => handleChangeExp(3)}
            style={{
              maxWidth: "300px",
              width: "100%",
              maxHeight: "200px",
              marginTop: "0px",
              height: "100%",
              borderRadius: "15px",
              marginRight: "25px",
              backgroundColor: "#58827E",
            }}
          >
            <AccordionSummary style={{ height: "20px" }}>
              <Typography
                fontSize="20px"
                fontWeight="700"
                color="#f6f6f6"
                display="flex"
                alignItems="center"
              >
                <RestaurantMenuIcon style={{ marginRight: "10px" }} /> Feeding
                Activity
              </Typography>
            </AccordionSummary>

            <AccordionDetails
              style={{
                minHeight: expanded ? "120px" : "50px", // Adjust the minHeight here
                display: "flex",
                alignItems: "center",
                backgroundColor: "#58827E",
                borderRadius: "0px 0px 15px 15px",
                marginTop: "-30px",
              }}
            >
              <Typography fontSize="30px" fontWeight="900" color="#f6f6f6">
                {FAfed.toLocaleString()}
              </Typography>
              <Typography
                fontSize="15px"
                fontWeight="700"
                color="#f6f6f6"
                marginLeft="15px"
                lineHeight="1"
              >
                children fed
              </Typography>
            </AccordionDetails>
            <AccordionDetails
              style={{
                minHeight: expanded ? "100px" : "50px", // Adjust the minHeight here
                display: "flex",
                alignItems: "center",
                backgroundColor: "#58827E",
                borderRadius: "0px 0px 15px 15px",
                marginTop: "-50px",
              }}
            >
              <Typography fontSize="30px" fontWeight="900" color="#f6f6f6">
                {FAserved.toLocaleString()}
              </Typography>
              <Typography
                fontSize="15px"
                fontWeight="700"
                color="#f6f6f6"
                marginLeft="15px"
                lineHeight="1"
              >
                meals served
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded[4]}
            onChange={() => handleChangeExp(4)}
            style={{
              maxWidth: "300px",
              width: "100%",
              maxHeight: "200px",
              marginTop: "0px",
              height: "100%",
              borderRadius: "15px",
              marginRight: "25px",
              backgroundColor: "#58827E",
            }}
          >
            <AccordionSummary style={{ height: "20px" }}>
              <Typography
                fontSize="20px"
                fontWeight="700"
                color="#f6f6f6"
                display="flex"
                alignItems="center"
              >
                <MedicalServicesIcon style={{ marginRight: "10px" }} />
                Medical Mission
              </Typography>
            </AccordionSummary>

            <AccordionDetails
              style={{
                minHeight: expanded ? "170px" : "50px", // Adjust the minHeight here
                display: "flex",
                alignItems: "center",
                backgroundColor: "#58827E",
                borderRadius: "0px 0px 15px 15px",
                marginTop: "-30px",
              }}
            >
              <Typography fontSize="30px" fontWeight="900" color="#f6f6f6">
                {MMpatients.toLocaleString()}
              </Typography>
              <Typography
                fontSize="15px"
                fontWeight="700"
                color="#f6f6f6"
                marginLeft="15px"
                lineHeight="1"
              >
                patients served
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded[5]}
            onChange={() => handleChangeExp(5)}
            style={{
              maxWidth: "300px",
              width: "100%",
              maxHeight: "200px",
              marginTop: "0px",
              height: "100%",
              borderRadius: "15px",
              marginRight: "25px",
              backgroundColor: "#58827E",
            }}
          >
            <AccordionSummary style={{ height: "20px" }}>
              <Typography
                fontSize="20px"
                fontWeight="700"
                color="#f6f6f6"
                display="flex"
                alignItems="center"
              >
                <RedeemIcon style={{ marginRight: "10px" }} />
                Santa Scholars
              </Typography>
            </AccordionSummary>

            <AccordionDetails
              style={{
                minHeight: expanded ? "170px" : "50px", // Adjust the minHeight here
                display: "flex",
                alignItems: "center",
                backgroundColor: "#58827E",
                borderRadius: "0px 0px 15px 15px",
                marginTop: "-30px",
              }}
            >
              <Typography fontSize="30px" fontWeight="900" color="#f6f6f6">
                {SShelped.toLocaleString()}
              </Typography>
              <Typography
                fontSize="15px"
                fontWeight="700"
                color="#f6f6f6"
                marginLeft="15px"
                lineHeight="1"
              >
                families helped
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded[6]}
            onChange={() => handleChangeExp(6)}
            style={{
              maxWidth: "300px",
              width: "100%",
              maxHeight: "200px",
              marginTop: "0px",
              height: "100%",
              borderRadius: "15px",
              marginRight: "25px",
              backgroundColor: "#58827E",
            }}
          >
            <AccordionSummary style={{ height: "20px" }}>
              <Typography
                fontSize="20px"
                fontWeight="700"
                color="#f6f6f6"
                display="flex"
                alignItems="center"
              >
                <ForestIcon style={{ marginRight: "10px" }} /> Tree Planting
              </Typography>
            </AccordionSummary>

            <AccordionDetails
              style={{
                minHeight: expanded ? "120px" : "50px", // Adjust the minHeight here
                display: "flex",
                alignItems: "center",
                backgroundColor: "#58827E",
                borderadius: "0px 0px 15px 15px",
                marginTop: "-30px",
              }}
            >
              <Typography fontSize="30px" fontWeight="900" color="#f6f6f6">
                {TPplanted.toLocaleString()}
              </Typography>
              <Typography
                fontSize="15px"
                fontWeight="700"
                color="#f6f6f6"
                marginLeft="15px"
                lineHeight="1"
              >
                trees planted
              </Typography>
            </AccordionDetails>
            <AccordionDetails
              style={{
                minHeight: expanded ? "100px" : "50px", // Adjust the minHeight here
                display: "flex",
                alignItems: "center",
                backgroundColor: "#58827E",
                borderRadius: "0px 0px 15px 15px",
                marginTop: "-50px",
              }}
            >
              <Typography fontSize="30px" fontWeight="900" color="#f6f6f6">
                {TPHectares.toLocaleString()}
              </Typography>
              <Typography
                fontSize="15px"
                fontWeight="700"
                color="#f6f6f6"
                marginLeft="15px"
                lineHeight="1"
              >
                hectares protected
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Box display="flex" marginLeft="25px" marginTop="40px">
          <Box maxHeight="50px" maxWidth="75px">
            <img
              src="MEGreen3.png"
              alt="MEGreen"
              style={{ width: "100%", height: "120%" }}
            />
          </Box>
          <Box display="flex" marginLeft="30px">
            <Typography
              fontSize="40px"
              fontWeight="900"
              color="#174A55"
              marginTop="5px"
            >
              MEGREEN PROJECTS
            </Typography>
          </Box>
        </Box>

        <Box margin="25px" display="flex">
          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #174A55"
            maxWidth=" 230px"
            width="100%"
            height="120px"
            backgroundColor="#174A55"
            borderRadius="15px"
            paddingLeft="10px"
            marginRight="25px"
            display="flex"
            flexDirection="column"
            justifyContent="center" // Center items vertically
          >
            <Typography
              fontSize="45px"
              fontWeight="900"
              color="#ADBDA3"
              marginBottom="-15px"
            >
              {MEtotalActivities.toLocaleString()}
            </Typography>
            <Typography fontSize="25px" fontWeight="700" color="#ADBDA3">
              activities
            </Typography>
          </Box>
          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #174A55"
            maxWidth=" 230px"
            width="100%"
            height="120px"
            backgroundColor="#174A55"
            borderRadius="15px"
            paddingLeft="10px"
            marginRight="25px"
            display="flex"
            flexDirection="column"
            justifyContent="center" // Center items vertically
          >
            <Typography
              fontSize="45px"
              fontWeight="900"
              color="#ADBDA3"
              marginBottom="-15px"
            >
              {MEtotalVolunteers.toLocaleString()}
            </Typography>
            <Typography fontSize="25px" fontWeight="700" color="#ADBDA3">
              volunteers
            </Typography>
          </Box>
          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #174A55"
            maxWidth=" 230px"
            width="100%"
            height="120px"
            backgroundColor="#174A55"
            borderRadius="15px"
            paddingLeft="10px"
            marginRight="25px"
            paddingBottom="0px"
            display="flex"
            flexDirection="column"
            justifyContent="center" // Center items vertically
          >
            <Typography
              fontSize="45px"
              fontWeight="900"
              color="#ADBDA3"
              marginBottom="-15px"
            >
              {MEtotalhoursRendered.toLocaleString()}
            </Typography>
            <Typography fontSize="25px" fontWeight="700" color="#ADBDA3">
              volunteer hours
            </Typography>
          </Box>

          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #174A55"
            maxWidth=" 230px"
            width="100%"
            height="120px"
            backgroundColor="#174A55"
            borderRadius="15px"
            paddingLeft="10px"
            marginRight="25px"
            display="flex"
            flexDirection="column"
            justifyContent="center" // Center items vertically
          >
            <Typography
              fontSize="45px"
              fontWeight="900"
              color="#ADBDA3"
              marginBottom="-15px"
            >
              {MEtotalBeneficiaries.toLocaleString()}
            </Typography>
            <Typography fontSize="25px" fontWeight="700" color="#ADBDA3">
              beneficiaries
            </Typography>
          </Box>
          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #174A55"
            maxWidth=" 250px"
            width="100%"
            height="120px"
            backgroundColor="#174A55"
            borderRadius="15px"
            paddingLeft="10px"
            paddingBottom="10px"
            marginRight="25px"
            display="flex"
            flexDirection="column"
            justifyContent="center" // Center items vertically
          >
            <Typography
              fontSize="45px"
              fontWeight="900"
              color="#ADBDA3"
              marginBottom="-15px"
            >
              {MEtotalBarangay.toLocaleString()}
            </Typography>
            <Typography
              fontSize="25px"
              fontWeight="700"
              color="#ADBDA3"
              lineHeight="1"
            >
              barangays reached
            </Typography>
          </Box>
          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #174A55"
            maxWidth=" 500px"
            width="100%" // Set width to fill the parent container
            height="120px"
            backgroundColor="#174A55"
            borderRadius="15px"
            paddingLeft="10px"
            display="flex"
            flexDirection="column"
            justifyContent="center" // Center items vertically
          >
            <Typography
              fontSize="45px"
              fontWeight="900"
              display="flex"
              alignItems="flex-end"
              color="#ADBDA3"
              marginBottom="-15px"
            >
              <span style={{ marginRight: "5px" }}>P</span>{" "}
              {MEtotalExpenses.toLocaleString()}
            </Typography>
            <Typography fontSize="25px" fontWeight="700" color="#ADBDA3">
              expenses
            </Typography>
          </Box>
        </Box>
        <Box marginLeft="25px" display="flex">
          <Accordion
            expanded={expanded[7]}
            onChange={() => handleChangeExp(7)}
            style={{
              maxWidth: "245px",
              width: "100%",
              maxHeight: "200px",
              height: "100%",
              borderRadius: "15px",
              marginRight: "25px",
              backgroundColor: "#174A55",
              
            }}
          >
            <AccordionSummary style={{ height: "20px" }}>
              <Typography
                fontSize="20px"
                fontWeight="700"
                color="#ADBDA3"
                display="flex"
                alignItems="center"
              >
                <FormatPaintIcon style={{ marginRight: "10px" }} /> Brigada
                Eskwela
              </Typography>
            </AccordionSummary>

            <AccordionDetails
              style={{
                minHeight: expanded ? "100px" : "50px", // Adjust the minHeight here
                display: "flex",
                alignItems: "center",
                backgroundColor: "#174A55",
                borderRadius: "0px 0px 15px 15px",
              }}
            >
              <Typography fontSize="30px" fontWeight="900" color="#ADBDA3">
                {MEBEPainted.toLocaleString()}
              </Typography>
              <Typography
                fontSize="15px"
                fontWeight="700"
                color="#ADBDA3"
                marginLeft="15px"
                lineHeight="1"
              >
                classrooms repainted
              </Typography>
            </AccordionDetails>
            <AccordionDetails
              style={{
                minHeight: expanded ? "90px" : "50px", // Adjust the minHeight here
                display: "flex",
                alignItems: "center",
                backgroundColor: "#174A55",
                borderRadius: "0px 0px 15px 15px",
                marginTop: "-50px",
              }}
            >
              <Typography fontSize="30px" fontWeight="900" color="#ADBDA3">
                {MEBEBeneficiaries.toLocaleString()}
              </Typography>
              <Typography
                fontSize="15px"
                fontWeight="700"
                color="#ADBDA3"
                marginLeft="15px"
                lineHeight="1"
              >
                student beneficiaries
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded[8]}
            onChange={() => handleChangeExp(8)}
            style={{
              maxWidth: "245px",
              width: "100%",
              maxHeight: "200px",
              marginTop: "0px",
              height: "100%",
              borderRadius: "15px",
              marginRight: "25px",
              backgroundColor: "#174A55",
            }}
          >
            <AccordionSummary style={{ height: "20px" }}>
              <Typography
                fontSize="20px"
                fontWeight="700"
                color="#ADBDA3"
                display="flex"
                alignItems="center"
              >
                <BookIcon style={{ marginRight: "10px" }} /> Eskwela ng Bayan
              </Typography>
            </AccordionSummary>

            <AccordionDetails
              style={{
                minHeight: expanded ? "170px" : "50px", // Adjust the minHeight here
                display: "flex",
                alignItems: "center",
                backgroundColor: "#174A55",
                borderRadius: "0px 0px 15px 15px",
                marginTop: "-30px",
              }}
            >
              <Typography fontSize="30px" fontWeight="900" color="#ADBDA3">
                {MEEBAssisted.toLocaleString()}
              </Typography>
              <Typography
                fontSize="15px"
                fontWeight="700"
                color="#ADBDA3"
                marginLeft="15px"
                lineHeight="1"
              >
                students assisted
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded[9]}
            onChange={() => handleChangeExp(9)}
            style={{
              maxWidth: "245px",
              width: "100%",
              maxHeight: "200px",
              marginTop: "0px",
              height: "100%",
              borderRadius: "15px",
              marginRight: "25px",
              backgroundColor: "#174A55",
            }}
          >
            <AccordionSummary style={{ height: "20px" }}>
              <Typography
                fontSize="20px"
                fontWeight="700"
                color="#ADBDA3"
                display="flex"
                alignItems="center"
              >
                <RestaurantMenuIcon style={{ marginRight: "10px" }} /> Feeding
                Activity
              </Typography>
            </AccordionSummary>

            <AccordionDetails
              style={{
                minHeight: expanded ? "120px" : "50px", // Adjust the minHeight here
                display: "flex",
                alignItems: "center",
                backgroundColor: "#174A55",
                borderRadius: "0px 0px 15px 15px",
                marginTop: "-30px",
              }}
            >
              <Typography fontSize="30px" fontWeight="900" color="#ADBDA3">
                {MEFAfed.toLocaleString()}
              </Typography>
              <Typography
                fontSize="15px"
                fontWeight="700"
                color="#ADBDA3"
                marginLeft="15px"
                lineHeight="1"
              >
                children fed
              </Typography>
            </AccordionDetails>
            <AccordionDetails
              style={{
                minHeight: expanded ? "100px" : "50px", // Adjust the minHeight here
                display: "flex",
                alignItems: "center",
                backgroundColor: "#174A55",
                borderRadius: "0px 0px 15px 15px",
                marginTop: "-50px",
              }}
            >
              <Typography fontSize="30px" fontWeight="900" color="#ADBDA3">
                {MEFAserved.toLocaleString()}
              </Typography>
              <Typography
                fontSize="15px"
                fontWeight="700"
                color="#ADBDA3"
                marginLeft="15px"
                lineHeight="1"
              >
                meals served
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded[10]}
            onChange={() => handleChangeExp(10)}
            style={{
              maxWidth: "245px",
              width: "100%",
              maxHeight: "200px",
              marginTop: "0px",
              height: "100%",
              borderRadius: "15px",
              marginRight: "25px",
              backgroundColor: "#174A55",
            }}
          >
            <AccordionSummary style={{ height: "20px" }}>
              <Typography
                fontSize="20px"
                fontWeight="700"
                color="#ADBDA3"
                display="flex"
                alignItems="center"
              >
                <MedicalServicesIcon style={{ marginRight: "10px" }} />
                Medical Mission
              </Typography>
            </AccordionSummary>

            <AccordionDetails
              style={{
                minHeight: expanded ? "170px" : "50px", // Adjust the minHeight here
                display: "flex",
                alignItems: "center",
                backgroundColor: "#174A55",
                borderRadius: "0px 0px 15px 15px",
                marginTop: "-30px",
              }}
            >
              <Typography fontSize="30px" fontWeight="900" color="#ADBDA3">
                {MEMMpatients.toLocaleString()}
              </Typography>
              <Typography
                fontSize="15px"
                fontWeight="700"
                color="#ADBDA3"
                marginLeft="15px"
                lineHeight="1"
              >
                patients served
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </Box>
  );
};

export default DashVolunteer;
