import React, { useState } from "react";
import { auth, firestoredb } from "../../firebaseConfig";
import { signInWithEmailAndPassword } from "firebase/auth";
import { getDoc, doc } from "firebase/firestore";
import SignUpModal from "../Sign-Up";
import NewUser from "../../Users/NewUser";
import {
  Box,
  Button,
  CssBaseline,
  Grid,
  Container,
  TextField,
  Typography,
} from "@mui/material";
// Import the SignUpModal component

const LoginPage = ({ setIsAuthenticated }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [showSignUpModal, setShowSignUpModal] = useState(false); // State to control the visibility of the SignUp modal
  const [showNewUserModal, setShowNewUserModal] = useState(false);

  const handleLogin = async () => {
    try {
      // Sign in with Firebase using the provided snippet
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password,
      );
      const user = userCredential.user;
      const userId = user.uid;

      // Retrieve user data from Firestore
      const userDocRef = doc(firestoredb, "users", userId);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();
        const userRole = userData.role;

        // Check user role and authenticate accordingly
        if (
          userRole === "Owner" ||
          userRole === "Administrator" ||
          userRole === "User"
        ) {
          setIsAuthenticated(true);
          sessionStorage.setItem("userId", userId);
          sessionStorage.setItem("isAuthenticated", "true");
        } else {
          setShowNewUserModal(true);
          setIsAuthenticated(false);
          sessionStorage.setItem("isAuthenticated", "false");
        }
      } else {
        // User document not found
        console.error("User document not found");
      }
    } catch (error) {
      console.error("Error signing in:", error.message);
      setError(error.message);
    }
  };
  const handleCloseNewUserModal = () => {
    setShowNewUserModal(false);
  };

  const handleOpenSignUpModal = () => {
    setShowSignUpModal(true);
  };

  const handleCloseSignUpModal = () => {
    setShowSignUpModal(false);
  };

  return (
    <Box
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/loginbg.png)`,
        backgroundSize: "cover", // Ensures the background image covers the element
        backgroundPosition: "center", // Centers the image
        backgroundRepeat: "no-repeat", // Prevents the image from repeating
        minHeight: "100vh", // Ensures it takes at least the full height of the viewport
        width: "100%", // Makes the box take the full width of its container
        maxHeight: "300vh", // Limits the maximum height to 300vh
        display: "flex",
        alignItems: "center",
      }}
    >
      <Container>
        {" "}
        {/* Center the box horizontally */}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding="50px"
          bgcolor="#FAF4F4"
          borderRadius="10px"
          margin="auto" // Center the box horizontally
        >
          <Box>
            <img
              src={process.env.PUBLIC_URL + "/MEGA_LOGO.png"}
              alt="Logo"
              style={{
                maxWidth: "300px",
                margin: "0 auto", // Center the image horizontally
                display: "block", // Ensure the image is a block element
              }}
            />

            <Typography
              textAlign="center"
              paddingRight="5px"
              variant="h4"
              fontStyle="oblique"
              fontWeight="medium"
            >
              Creating Change and Transforming Lives
            </Typography>

            <Typography
              marginTop="5px"
              textAlign="center"
              fontSize="55px"
              color="#144954"
              fontWeight="900"
              marginRight="40px"
            >
              DATA BANKING SYSTEM
            </Typography>
          </Box>

          <CssBaseline />

          <Box
            textAlign="center"
            justifyContent="center"
            display="flex"
            flexDirection="column"
            padding="20px"
            borderRadius="8px"
            boxShadow="0 0 10px rgba(0, 0, 0, 0.5)"
            maxWidth="400px"
            height="600px"
          >
            <Grid item xs={12}>
              <form>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="E-mail address"
                  autoFocus
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  type="password"
                  label="Password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {error && (
                  <Typography color="error" variant="body2" gutterBottom>
                    Your e-mail or password is incorrect!
                  </Typography>
                )}

                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  style={{
                    backgroundColor: "#007366",
                  }}
                  sx={{ width: "75%", m: "16px" }}
                  onClick={handleLogin}
                >
                  Login
                </Button>

                <Button
                  type="button"
                  fullWidth
                  onClick={handleOpenSignUpModal}
                  variant="contained"
                  style={{
                    backgroundColor: "#036c8c",
                  }}
                  sx={{ width: 1 / 2, mt: "10px", marginBottom: "10px" }}
                >
                  Create New Account
                </Button>
              </form>
            </Grid>
          </Box>
          <SignUpModal
            open={showSignUpModal}
            handleClose={handleCloseSignUpModal}
          />
          <NewUser
            open={showNewUserModal}
            handleClose={handleCloseNewUserModal}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default LoginPage;
