import { useEffect, useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";
import { firestoredb } from "../../firebaseConfig";
import CustomAlert from "../../components/alert";

const Users = () => {
  const [roleOptions, setRoleOptions] = useState([]);
  const [userData, setUserData] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserRole, setSelectedUserRole] = useState(null);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);

  const [alertOpen, setAlertOpen] = useState(false); // State for alert visibility
  const [alertMessage] = useState(""); // State for alert message
  const [alertSeverity] = useState("success");

  const fetchUserData = async () => {
    const userCollection = collection(firestoredb, "users");
    const userSnapshot = await getDocs(userCollection);
    const data = userSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setUserData(data);
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    const roles = [...new Set(userData.map((user) => user.role))];
    const defaultOptions = ["User (New)", "User", "Administrator", "Owner"];
    const mergedOptions = [...new Set([...defaultOptions, ...roles])];
    setRoleOptions(mergedOptions);
  }, [userData]);

  const handleRoleChange = (e) => {
    setSelectedUserRole(e.target.value);
  };

  const handleUpdateUser = async () => {
    try {
      if (selectedUserId && selectedUserRole) {
        const userDocRef = doc(firestoredb, "users", selectedUserId);
        await updateDoc(userDocRef, { role: selectedUserRole });
        setOpenUpdateModal(false);
        fetchUserData();

        // Store the alert data in local storage
        localStorage.setItem("alertSeverity", "success");
        localStorage.setItem("alertMessage", "Updated Successfully");
        setAlertOpen(true);

        // Reload the page
        window.location.reload();
      }
    } catch (error) {
      console.error("Error updating user:", error.message);

      // Store the alert data in local storage
      localStorage.setItem("alertSeverity", "error");
      localStorage.setItem("alertMessage", "Update Failed");
      setAlertOpen(true);

      // Reload the page
      window.location.reload();
    }
  };

  const columns = [
    { field: "lastName", headerName: "Last Name", flex: 0.5 },
    { field: "firstName", headerName: "First Name", flex: 0.5 },
    {
      field: "email",
      headerName: "E-mail Address",
      cellClassName: "name-column--cell",
      flex: 0.5,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 0.2,
      renderCell: (params) => (
        <select value={params.value} onChange={(e) => handleRoleChange(e)}>
          {roleOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      ),
    },
  ];

  const tableStyles = {
    "& .MuiDataGrid-root": { border: "none" },
    "& .MuiDataGrid-cell": { fontSize: "14px ", borderBottom: "none" },
    "& .name-column--cell": { color: "darkgreen" },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#e1e6de", // Set the background color of the column headers
      borderBottom: "none", // Remove the bottom border
      borderRadius: "15px 15px 0px 0px", // Apply rounded corners to the top
      fontSize: "16px ", // Set the font size
    },
    "& .MuiDataGrid-virtualScroller": { backgroundColor: "#f6f6f6" },
    "& .MuiDataGrid-footerContainer": {
      display: "flex",
      justifyContent: "center", // Center horizontally
      alignItems: "center", // Center vertically
      borderTop: "none",
      backgroundColor: "#e1e6de",
      borderRadius: "0px 0px 15px 15px",
    },
    "& .MuiCheckbox-root": { color: `#e1e6de !important` },
    "& .actionbuttons": {
      display: "flex !important",
      alignItems: "center !important",
      justifyContent: "center !important",
    },
    "& .MuiDataGrid-cell:focus": {
      outline: "none !important", // Remove the outline when cell is focused (clicked)
      border: "none !important",
      boxShadow: "none !important", // Remove box shadow when cell is focused
    },
    "& .MuiDataGrid-row:nth-of-type(even)": {
      backgroundColor: "#f7f7f7", // Alternate color for even rows
    },
    "& .MuiDataGrid-row:nth-of-type(odd)": {
      backgroundColor: "#FFFFFF", // Default color for odd rows
    },
    "& .MuiDataGrid-row:hover": {
      backgroundColor: "#F0F0F0", // Hover color for both even and odd rows
    },
  };

  return (
    <Box width="1610px" margin="20px">
      <Header title="Manage Users" />
      <Box
        margin="5px 0 0 0"
        height="85vh"
        sx={tableStyles}
        style={{ width: "100%" }}
      >
        <DataGrid
          rows={userData}
          columns={columns}
          cellClassName="data-grid-cell"
          autoPageSize
          pageSize={15}
          rowsPerPage={15}
          onRowClick={(row) => {
            setSelectedUserId(row.id);
            setSelectedUserRole(row.role);
            setOpenUpdateModal(true);
          }}
        />
      </Box>
      <Modal open={openUpdateModal} onClose={() => setOpenUpdateModal(false)}>
        <Box
          sx={{
            position: "absolute",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: "15px",
            boxShadow: 24,
            p: 3,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <select
            value={selectedUserRole}
            onChange={(e) => handleRoleChange(e)}
          >
            {roleOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <Button onClick={handleUpdateUser}>Update</Button>
        </Box>
      </Modal>
      <CustomAlert
        open={alertOpen}
        severity={alertSeverity}
        message={alertMessage}
      />
    </Box>
  );
};

export default Users;
