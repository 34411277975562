import React,{ useEffect, useState } from 'react';
import { Box,  Button, IconButton , Select, MenuItem, FormControl, InputLabel} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "../../components/Header";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs,getDoc,doc } from 'firebase/firestore';
import { firestoredb } from '../../firebaseConfig';
import CustomAlert from '../../components/alert'


const Scholarship = () => {
  const navigate = useNavigate();
  const [yearfrom, setYearfrom] = useState('');
  const [yearto, setYearto] = useState('');
  const [user, setUser] = useState('');
  const [scholarshipData, setScholarshipData] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false); // State for alert visibility
  const [alertMessage,setAlertMessage] = useState(''); // State for alert message
  const [alertSeverity,setAlertSeverity] = useState('success');
  
  useEffect(() => {
    const severity = localStorage.getItem('alertSeverity');
    const message = localStorage.getItem('alertMessage');
    const open = localStorage.getItem('alertopen') === 'true';
    
    if (open) {
      setAlertSeverity(severity);
      setAlertMessage(message);
      setAlertOpen(open);

      // Clear localStorage items
      localStorage.removeItem('alertSeverity');
      localStorage.removeItem('alertMessage');
      localStorage.removeItem('alertopen');
    }
  }, []);


  useEffect(() => {
    const fetchScholarshipData = async () => {
          try {
            const scholarshipCollection = collection(firestoredb, 'ScholarshipData');
            const scholarshipSnapshot = await getDocs(scholarshipCollection);
      
            // Define variables to store filtered and sorted data
            const filteredData = scholarshipSnapshot.docs.map(doc => ({
              timestamp: parseInt(doc.id),
              id: doc.id,
              ...doc.data()
            }));
      
      
            // Filter data based on selected criteria
            const filteredAndSortedData = filteredData.filter(item =>
              (!yearfrom || yearfrom === '' || item.year >= yearfrom) &&
              (!yearto || item.year <= yearto)
              );

                filteredAndSortedData.sort((a, b) => b.timestamp - a.timestamp);
            
            // Sort data based on timestamp in descending order
        
      
            // Set the filtered and sorted data to the state variable
            setScholarshipData(filteredAndSortedData);
            
          } catch (error) {
            console.error('Error fetching Scholarship Data', error);
          }
        };
      
        // Call the function to fetch, filter, and sort data
        fetchScholarshipData();
  }, [yearfrom, yearto]); // Update data when filter options change

      useEffect(() => {
        const fetchUser = async () => {
          try {
            // Get the userId from sessionStorage
            const userId = sessionStorage.getItem('userId');
            if (!userId) {
              console.log("User ID not found in sessionStorage");
              return;
            }

        // Get the user document from Firestore
        const userDocRef = doc(firestoredb, 'users', userId);
        const userDocSnapshot = await getDoc(userDocRef);
        if (userDocSnapshot.exists()) {
          // Extract the user data from the user document
          const userData = userDocSnapshot.data();
          setUser(userData);
        } else {
          console.log("User not found");
        }
      } catch (error) {
        console.error("Error fetching user data:", error.message);
      }
    };

    fetchUser();
  }, []);



  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear(); // Get the current year
    const startYear = 1997; // Start year
    const yearOptions = [];
    
    // Loop from the start year to the current year
    for (let i = currentYear; i >= startYear; i--) {
      yearOptions.push(i.toString()); // Push each year as a string to the options array
    }
    
    return yearOptions; // Return the array of year options
  };
  
  const handleYearFromChange = (e) => {
    const selectedYearFrom = e.target.value;
    
    // Set yearfrom based on the selected value
    setYearfrom(selectedYearFrom);
  
    // If "Overall" is selected, clear both yearfrom and yearto
    if (selectedYearFrom === "") {
      setYearto(""); // Clear the value in yearto
    } else if (yearto && selectedYearFrom > yearto) {
      // If the selected value of "Year To" is smaller than "Year From", update "Year To" to match "Year From"
      setYearto(selectedYearFrom);
    }
  };

  const handleYearToChange = (e) => {
    // If the selected value of "Year To" is smaller than "Year From", prevent updating "Year To"
    if (yearfrom && e.target.value < yearfrom) {
      return;
    }
    setYearto(e.target.value);
  };


  const handleAddNewClick = () => {
    navigate('/SchoformAdd');
    
  };

  const handleShowonCLick = (params) => {
    const documentId = params.id;
    
    navigate(`/SchoShowform/${documentId}`);
  };

  const columns = [
    { field: "year", headerName: "Year", flex: 0.15 },
    { field: "scholarshipGrants", headerName: "Scholarship Grants", flex: 0.5, headerAlign: "center", align: "center" },
    { field: "annualTargetScholar", headerName: "Annual Target Scholars", flex: 0.5, headerAlign: "center", align: "center"},
    { field: "scholarsFTY",
      headerName: "Scholars for the Year",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      cellClassName: (params) => {
        const scholarsFTY = parseInt(params.row.scholarsFTY, 10);
        const annualTargetScholar = parseInt(params.row.annualTargetScholar, 10);

        if (scholarsFTY < annualTargetScholar) {
          return 'red-text';
        } else if (scholarsFTY > annualTargetScholar) {
          return 'blue-text';
        } else {
          return ""; 
        } 
    }
  },
      
    { field: "scholars", headerName: "Graduated Scholars", flex: 0.5, headerAlign: "center", align: "center",
      valueGetter: (params) => {
        const scholars = params.row.scholars;
        return scholars ? scholars.graduated : "0";
      },
    },
    { field: "graduatedWithHonor", headerName: "Graduates With Honors", flex: 0.5, headerAlign: "center", align: "center"},
    { field: "graduateWhoAreBoardPasser", headerName: "Graduates Who are Board Passers", flex: 0.7, headerAlign: "center", align: "center"},
    { field: "totalHiredScholars", headerName: "Hired Scholars  ", flex: 0.5, headerAlign: "center", align: "center"},
    { field: "partnerSchools", headerName: "Partner Schools", flex: 0.5, headerAlign: "center", align: "center"},
    { field: "actions", headerName: "Actions", flex: 0.20, headerAlign: "center", cellClassName: "actionbuttons",
    renderCell: (params) => (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <IconButton variant="contained" onClick={() => handleShowonCLick(params)}>
          <VisibilityOutlinedIcon />
        </IconButton>
      </div>
    ),
  },
  ] 

  const tableStyles = {
    "& .MuiDataGrid-root": {
      border: "none",
      overflow: 'auto',
    },
    "& .MuiDataGrid-cell": {
      borderBottom: "none",
      fontSize: "14px",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: '#e1e6de',
      borderBottom: "none",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "15px 15px 0px 0px",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontSize: "13px",
    },
    "& .MuiDataGrid-virtualScroller": {
      backgroundColor: "#f6f6f6",
    },
    "& .MuiDataGrid-footerContainer": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderTop: "none",
      backgroundColor: '#e1e6de',
      borderRadius: "0px 0px 15px 15px",
    },
    "& .MuiCheckbox-root": {
      color: "#e1e6de !important",
    },
    "& .actionbuttons": {
      display: 'flex !important',
      alignItems: 'center !important',
      justifyContent: 'center !important',
    },
    "& .MuiDataGrid-cell:focus": {
      outline: "none !important",
      border: "none !important",
      boxShadow: "none !important",
    },
    "& .MuiDataGrid-row:nth-of-type(even)": {
      backgroundColor: "#f7f7f7", // Alternate color for even rows
    },
    "& .MuiDataGrid-row:nth-of-type(odd)": {
      backgroundColor: "#FFFFFF", // Default color for odd rows
    },
    "& .blue-text": {
      color: "blue !important",
    },
    "& .red-text": {
      color: "red !important",
    },
    "& .MuiDataGrid-row:hover, .MuiDataGrid-row.Mui-selected, .MuiDataGrid-row.Mui-selected:hover": {
      backgroundColor: "#D3D3D3",
    },
    "& .MuiDataGrid-row:focus": {
      backgroundColor: "#D3D3D3",
    },
  };
  
  
  
  return (
    <Box margin="20px">
      <Box display = "flex ">
        <Header title="Scholarships" />
        {(user && (user.role === 'Owner' || user.role === 'Administrator')) && (
          <Button
              variant="contained"
              onClick={handleAddNewClick}
              sx={{
                backgroundColor: "#4B6856",
                color: "white",
                "&:hover": { backgroundColor: "#849F77" },
                display: 'flex',
                flexDirection: 'flex-end',
                marginLeft: "auto",
                mb: '10px',  
            }}
          >
            <AddOutlinedIcon/>
            Add New
          </Button> 
           )}
        </Box>
        <Box  marginTop='10px' marginBottom ="30px"  display="flex" justifyContent="flex-end" alignItems="flex-end" >
          <FormControl style={{ width: '100px', height: '25px' }}>
            <InputLabel >Year From:</InputLabel> 
            <Select
              style={{ height : '35px'}}
              label="Year From"
              value={yearfrom}
              MenuProps={{
                MenuListProps: {
                  style: {
                    maxHeight: 150, // Set the maximum height of the dropdown
                  },
                },
              }}
              onChange={handleYearFromChange}
            ><MenuItem value="">Overall</MenuItem>
              {generateYearOptions().map((option) => (
                <MenuItem key={option} value={option}>  {option}</MenuItem>
              ))}
            </Select>
            </FormControl>
            {yearfrom && yearfrom !== "" && (
              <FormControl style={{ marginLeft: '20px', width: '100px', height: '25px' }}>
                  <InputLabel>Year to</InputLabel>
                  <Select
                    style={{ height: '35px' }}
                    label="Year to"
                    value={yearto}
                    MenuProps={{
                      MenuListProps: {
                        style: {
                          maxHeight: 150, // Set the maximum height of the dropdown
                        },
                      },
                    }}
                    onChange={handleYearToChange}
                  >
                    {generateYearOptions().map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
         </Box>
        <Box margin="5px 0px 0 0" height="78vh" sx={tableStyles} >
          <DataGrid
              rows={scholarshipData}
              columns={columns}
              cellClassName="data-grid-cell"
              autoPageSize
              pageSize={15}
              rowsPerPage={15}
              sx={{maxWidth:"1610px", width:"100%"}}
          />
            </Box>
            <CustomAlert
              open={alertOpen}
              severity={alertSeverity}
              message={alertMessage}
            />
      
         </Box>
  );
};



export default Scholarship;