export const PartnerMapping = [
  "ABS-CBN Lingkod Kapamilya Foundation, Inc. (ABS-CBN Foundation, Inc.)",
  "AKAP Movement, Inc.",
  "Alagang Kapatid Foundation, Inc.",
  "Alay Pag-asa Christian Foundation, Inc.",
  "Alay sa Kawal Foundation, Inc.",
  "Alejandro Mirasol Memorial School",
  "Allan L. Cristobal Foundation, Inc.",
  "Alon & Araw Club of Zambales",
  "Alpha Phi Omega Sorority Eta Alumnae Association, Inc.",
  "American Chamber Foundation Philippines, Inc.",
  "Amuyong Elementary school",
  "Anawim Lay Missions Foundation, Inc.",
  "Angeles University Foundation",
  "Angeles University Foundation Medical Center",
  "Angelique Vizoro Deaf Ministry Foundation, Inc.",
  "Anggelos Religious and Charitable Foundation, Inc.",
  "Apolinario Mabini Elementary School ",
  "Archdiocese of Lipa",
  "Armed Forces of the Philippines Officers' Ladies Club, Inc.",
  "Armed Forces of the Philippines Retired Veterans Association",
  "Asian Conference of Religions for Peace",
  "Asian Students Christian Foundation, Inc.",
  "Asociacion de Damas de Filipinas, Inc.",
  "Assembly of Family & Friends in Christ, Inc.",
  "Assembly of Family & Friends of Christ",
  "Assisi Development Foundation, Inc.",
  "Association of Foundations Phils., Inc.",
  "Ateneo de Manila University",
  "Ateneo De Manila University - Management Economics Organization",
  "ATS Global Smile Foundation",
  "Augustinian Sisters of Our Lady of Consolation",
  "Aurora Polytechnic College",
  "Autism Society Philippines, Inc.",
  "Bacao Elementary School",
  "Bagumbayan Elementary School",
  "Bahay ni San Jose Home for God's Special Children",
  "Balabag Elementary School",
  "Bamboo Organ Foundation Inc.",
  "Barangay 282, Manila",
  "Barangay 98, Pasay City",
  "Barangay Almanza Dos, Las Pinas City",
  "Barangay Almanza Uno, Las Pinas City",
  "Barangay Bacao I, General Trias, Cavite",
  "Barangay Bagong Bayan, Quezon City",
  "Barangay Bagumbayan, Pasig City",
  "Barangay Calawis, Antipolo City",
  "Barangay Don Galo, Parañaque City",
  "Barangay Eguia, Dasol, Pangasinan",
  "Barangay Fort Bonifacio, Taguig City",
  "Barangay Guadalupe Nuevo, Makati City",
  "Barangay Hi-way Soong, Mactan, Cebu",
  "Barangay Kemdeng, San Vicente, Palawan",
  "Barangay Lambakin, Marilao, Bulacan",
  "Barangay Lower Bicutan, Taguig City",
  "Barangay Mactan, Cebu City",
  "Barangay Malanday, Marikina",
  "Barangay Manggahan, Pasig City",
  "Barangay Pinagsama, Taguig City",
  "Barangay San Isidro, Cainta, Rizal",
  "Barangay Santa Cruz, Manila",
  "Barangay Sto. Nino, San Fernando, Pampanga",
  "Barangay Ugong Person with Disability and Parent Support Group Inc.",
  "Barangay Ugong, Pasig City",
  "Barangay West Rembo, Taguig City",
  "Bethany House Sto. Nino Orphanage",
  "Bicol Development Learning Center",
  "Biñan Elementary School",
  "Birth, Inc.",
  "Boy Scout of the Philippines",
  "Brain Train Academic Center",
  "Brothers of Mercy of St. John of God - Bulacan",
  "Bukas Palad Foundation of the Philippines, Inc.",
  "C.L. Montelibano Elementary School",
  "Cabilang Bay bay Elementary School",
  "Caritas Manila, Inc.",
  "Cartwheel Foundation, Inc.",
  "Casa Miani (Somascan Fathers) Foundation, Inc.",
  "Catholic Ministry to Deaf People, Inc.",
  "Cembo Elementary School",
  "Center for Tuberculosis in Children Philippines",
  "Central Luzon Conference of Seventh-Day Adventist Church",
  "Child Justice League, Inc.",
  "Childhope Asia Philippines",
  "Children's Hour Philippines, Inc.",
  "Children's Joy Foundation, Inc.",
  "Chosen Children Village, Inc.",
  "Circle of Trust Badminton Club",
  "Civil-Military Operations Regiment - Philippine Air Force",
  "Civil-Military Operations Regiment - Philippine Army",
  "College of the Holy Spirit of Tarlac",
  "Community and Family Services International",
  "Concordia Children's Services",
  "Congregation of the Blessed Sacrament",
  "Consular Corps of the Philippines",
  "Cosmos Lodge No. 8 F & AM",
  "Cottolengo Filipino, Inc.",
  "Couples For Christ Global Mission Foundation Inc.",
  "Create a Job for the Disabled Association, Inc.",
  "Create Responsive Infants By Sharing Foundation, Inc.",
  "CRIBS Foundation, Inc.",
  "CURE Foundation, Inc.",
  "Dalaw Kalinga Foundation, Inc.",
  "Dar Amanah Children's Village Foundation, Inc.",
  "Daughters of Mary Help of Christians",
  "Department of Agriculture",
  "Department of Education - Paoay District",
  "Department of Foreign Affairs Ladies Foundation, Inc.",
  "Development Center for the Handicapped Foundations",
  "Disabled Coordinating Council, Inc. (National Council on Disability Affairs)",
  "Dr. Fe del Mundo Medical Center Foundation Philippines, Inc.",
  "Dr. Jose N. Rodriguez Memorial Hospital",
  "Dugong Bughaw Golf Club, Inc.",
  "Dulaang Tahimik ng Pilipinas Inc.",
  "Dynamic Teen Company - Making a Difference, Inc.",
  "Earth Day Network Philippines, Inc.",
  "East Avenue Medical Center",
  "Easter Heart Draw",
  "EBB Churches",
  "Economic Journalist Association of the Philippines, Inc.",
  "Educational Research and Development Assistance (ERDA), Foundation, Inc.",
  "Emergency Medical Responders Raptors - Iloilo Chapter",
  "Emperador Distillers, Inc.",
  "Empire East Land Holdings, Inc.",
  "Empowering Brilliant Minds Foundation, Inc.",
  "EM's Signal Village Elementary School",
  "Enrique Jose Elementary School",
  "Erwin Piedad Cabanag Adult Leukemia Memorial Foundation of the Philippines, Inc.",
  "Federation Of Filipino Chinese Chambers Of Commerce and Industry, Inc. (FFCCCII)",
  "Federation Of Ice Hockey League, Inc.",
  "Fernando Ma. Guerrero Elementary School",
  "First Associated Medical Distribution Company, Inc,",
  "Focolare Movement for Women, Inc.",
  "Food for Hungry Minds School, Inc.",
  "Food for the Hungry, Inc.",
  "Foundation for Cultural Survival, Inc.",
  "Foundation for Professional Training, Inc.",
  "Foundation of Our Lady of Peace Mission, Inc.",
  "Fr. Al’s Children Foundation, Inc.",
  "Fr. Simpliciano Academy, Inc.",
  "Fundacion Santiago, Inc.",
  "Gawad Kalinga Community Development Foundation",
  "Gen. Ricardo G. Papa Memorial High School Annex",
  "GMA Kapuso Foundation, Inc.",
  "Golden Reception and Action Center for the Elderly and Other Special Cases (DSWD-GRACES)",
  "Gospel Church of Manila",
  "Grace to Be Born, Inc.",
  "GreenEarth Heritage Foundation, Inc.",
  "H. Bautista Elementary School, Marikina City",
  "Habitat for Humanity Philippines Foundation, Inc.",
  "Hands on Manila Foundation, Inc.",
  "Haribon Foundation for the Conservation of Natural Resources, Inc.",
  "Haven for the Elderly (Golden Acres - DSWD)",
  "Hay-atul Wafa Al-kairiya Bel Filiben, Inc.",
  "He Cares Foundation",
  "Heart Warriors of the Philippines",
  "Hero Foundation, Inc.",
  "Holy Spirit Academy of Irosin",
  "Holy Spirit Aeta Mission",
  "Holy Spirit Social Center, Inc.",
  "Home of Joy",
  "Hospicio de San Jose",
  "House Foundation, Inc.",
  "House of Refuge Foundation, Inc. (Bahay Kanlungan)",
  "House of Sarang, Inc.",
  "ICanServe Foundation, Inc.",
  "Ideals PH",
  "Iloilo Volunteer Emergency Response Team",
  "Immaculate Concepcion Parish",
  "Informatics Holdings Philippines, Inc.",
  "Institute for the Promotion & Preservation of Ecclesiastical Cultural Heritage",
  "International Bazaar Foundation, Inc.",
  "Jacques Demolay Memorial Lodge No. 305",
  "JC Binay Foundation, Inc.",
  "Jesuit Volunteers Philippines Foundation, Inc.",
  "Jesus' Friends Community Church, Inc.",
  "Jose Abad Santos High School",
  "Junior Chamber International of Manila, Inc.",
  "Kabalaasnan Elementary School",
  "Kabang Kalikasan ng Pilipinas Foundation, Inc.",
  "Kabisig Elementary School",
  "Kaisahang Buhay Foundation, Inc. ",
  "Kalayaan National High School",
  "Kalipay Negrense Foundation Inc.",
  "Kanlungan ni Maria Home for the Aged, Inc.",
  "Kapitan Eddie T. Reyes Integrated School ",
  "Kidney Foundation of the Philippines, Inc.",
  "King Solomon Brotherhood Foundation, Inc.",
  "Knowledge Channel Foundation, Inc.",
  "Kythe Foundation, Inc.",
  "La Consolacion University General Hospital",
  "Lake Sebu Indigenous Women Weavers Association Inc. (LASIWWAI)",
  "Lamberto H. Tirol National School",
  "Legaspi Memorial School",
  "Let The (Special) Children Come to Me, Inc.",
  "Leukemic Indigents Fund Endowment (L.I.F.E.), Inc.",
  "Libis Health Center",
  "Lincoln Masonic Lodge No. 34 F & A M",
  "Lingap Pangkabataan, Inc.",
  "Little League Manila Charter",
  "Living for Christ Foundation, Inc.",
  "Local Government of  Ilocos Region",
  "Local Government of Bulacan",
  "Local Government of Cagayan",
  "Local Government of Ilocos Norte",
  "Local Government of Isabela",
  "Local Government of Naga",
  "Lung Center of the Philippines",
  "Mabuhay Deseret Foundation",
  "Mactan Elementary School ",
  "Mactan National High School",
  "Makati Police Association, Inc. - Batch '72",
  "Management Association of the Philippines Research and Development Foundation, Inc.",
  "Mandurriao Elementary School",
  "Manggahan High School",
  "Mangyan Mission, Inc.",
  "Manila Boys Town",
  "Maria Lena Buhay Memorial Foundation, Inc.",
  "Maricaban Elementary School",
  "Marinducare Foundation, Inc. ",
  "Marriage Encounter Foundation of the Philippines, Inc.",
  "Masonic District NCR-G",
  "Matanglinaw Association, Inc.",
  "Mater Dei Marriage Encounter Community ",
  "MDSS Crossway Kidney Care, Inc.",
  "Metropolitan Medical Center",
  "Mga Anak ni Inang Daigdig (Children of Mother Earth)",
  "Missionaries of the Poor",
  "Monasterio De Santa Clara Del Sagrado Corazon De Jesus",
  "Move.org Foundation, Inc.",
  "Mt. Redondo Lodge No. 372 New Masonic Temple Association, Inc.",
  "Municipality of  Apalit, Pampanga",
  "Municipality of Cainta",
  "Municipality of Calumpit, Bulacan ",
  "Municipality of Hagonoy, Bulacan",
  "Municipality of Mabitac, Laguna",
  "Municipality of Sta Maria, Ilocos Sur",
  "Municipality of Taguig",
  "Municipality of Ternate, Cavite",
  "Museo Pambata Foundation, Inc.",
  "Museum Foundation of the Philippines, Inc.",
  "NAMFREL Bantay ng Bayan Foundation, Inc.",
  "National Blind Masseurs Service Association, Inc.",
  "National Center for Mental Health",
  "National Commission on Muslim Filipinos",
  "National Kidney and Transplant Institute",
  "National Press Club of the Philippines",
  "Oh My Gulay, Inc.",
  "One Algon Place Foundation, Inc.",
  "One Million Lights Philippines, Inc.",
  "Operation Blessing Foundation Philippines, Inc.",
  "Operation Smile Philippines Foundation, Inc.",
  "Opifices Christi Catholic Priests & Brothers",
  "Order of the Knights of Rizal",
  "Ospital ng Makati",
  "Our Lady of Lujan Mission Center, Inc.",
  "Our Lady of Peace Hospital",
  "Pamantasan ng Lungsod ng Maynila",
  "Pangarap Foundation, Inc.",
  "Pasay City East High School",
  "Pasay City General Hospital",
  "Pasay City Health Office",
  "Pasig City General Hospital",
  "Pembo Elementary School",
  "Perfect World Travel Corporation",
  "PGH Medical Foundation, Inc.",
  "Philippine Agricultural Scientist",
  "Philippine Air Force",
  "Philippine Alliance for the Disabled Persons, Inc.",
  "Philippine American Chamber of Commerce",
  "Philippine Anti-Drug Support Group, Inc.",
  "Philippine Band of Mercy",
  "Philippine Business for Social Progress",
  "Philippine Cancer Society, Inc.",
  "Philippine Center of Young Leaders in Governance Foundation, Inc.",
  "Philippine Cerebral Palsy, Inc.",
  "Philippine Children's Medical Center",
  "Philippine Council for NGO Certification Inc.",
  "Philippine Council of Deans and Educators in Business",
  "Philippine Crime Buster Support Group, Inc.",
  "Philippine Foundation for Breast Care, Inc.",
  "Philippine Foundation for the Rehabilitation of the Disabled, Inc.",
  "Philippine General Hospital",
  "Philippine Heart Center",
  "Philippine Institute for the Deaf",
  "Philippine Institute of Certified Public Accountants - Metro Manila Region",
  "Philippine Jesuit Aid Association, Inc.",
  "Philippine Leprosy Mission, Inc.",
  "Philippine Olympic Committee",
  "Philippine Orthopedic Center",
  "Philippine Red Cross",
  "Philippine School for the Deaf",
  "Philippine Society for Orphan Disorders, Inc.",
  "Philippine Tuberculosis Society, Inc.",
  "Philippines Central Conference of the United Methodist Church",
  "Pilipinas Obstacle Sports Federation",
  "Polytechnic University of the Philippines",
  "Premier Island Management Group",
  "President Diosdado Macapagal High School",
  "Progreen Agricorp, Inc.",
  "Project NEST",
  "Quezon City Health Department",
  "Quezon Institute Pulmonary Hospital",
  "Rajah Soliman National High School",
  "Ramos Peace and Development Foundation, Inc.",
  "Real LIFE Foundation",
  "Rehabilitation and Prevention of Children with Disabilities, Inc.",
  "Rescue Team 926, Inc.",
  "Research Institute of Tropical Medicine",
  "Resources for Children with Disabilities of The Philippines, Inc.",
  "Resources for the Blind, Inc.",
  "Resources of the Filipino Sightless Inc.",
  "Rise Against Hunger Philippines, Inc.",
  "Rizal Medical Center",
  "Roman Catholic Diocese of Cubao",
  "Ronald Mcdonald House Charities",
  "Rosario Elementary School",
  "Rotary Club of Acropolis",
  "Rotary Club of Makati, Inc. - District 3830",
  "Rotary Club of Timog - Quezon City",
  "Saint John Paul II Home For Priests Foundation, Inc.",
  "Saklolo at Gabay ng Ina at Pamilya (SAGIP), Inc.",
  "Salt Payatas Foundation Philippines, Inc.",
  "Samahang Magdalo, Inc.",
  "Samat Elementary School",
  "San Diego Pathfinders Lions Club",
  "San Francisco Elementary School",
  "San Juan de Dios Educational Foundation, Inc.",
  "San Juan South Elementary School",
  "San Lazaro Hospital",
  "San Ricardo Elementary School, Nueva Ecija",
  "Santuario de San Antonio Parish Foundation, Inc.",
  "Save A Life for the Hydrocephalus Children, Inc.",
  "Sergia Esguerra Memorial Foundation, Inc. (Phil. Institute for the Deaf)",
  "Servants of the Good Shepherd Marriage Encounter Community",
  "Serviam Catholic Charismatic Community Foundation, Inc.",
  "Sinag for the Sightless, Inc.",
  "Sinagtala Homes",
  "Somascan Fathers Philippines",
  "Soong Elementary School",
  "SOS Children's Villages Philippines",
  "SPECS Optical",
  "St. Francis of Assisi Parish Finance Construction Committee",
  "St. Isidore the Farmer Parish",
  "St. Joseph Foundation, Inc.",
  "St. Joseph of Dingras Foundation Inc.",
  "St. La Salle Preschool",
  "Sta. Ines Integrated School",
  "Sta. Rita Orphanage",
  "Startek Philippines, Inc.",
  "Sto. Nino Elementary School",
  "Sustainable Outreach and Lifelong Advocacy to Rekindle (SOLAR) Hope, Inc.",
  "Tabucan Elementary School",
  "Taguig City Health Office",
  "Taguig District Hospital",
  "Tahanan ng Pagmamahal",
  "Tahanan Sta. Luisa, Inc.",
  "Tahanang Walang Hagdanan, Inc.",
  "Tambo Elementary School",
  "Ten Accomplished Youth Organizations (TAYO) Awards Foundation, Inc.",
  "Teodoro F. Valencia Foundation, Inc.",
  "The Catholic Mass Media Awards Foundation, Inc.",
  "The Medical City",
  "The Mother Provincial of the Daughters of Mary Help of Christians, Inc.",
  "The Police Tribune Foundation Inc.",
  "The Roman Catholic Archbishop of Manila",
  "The Sisters of Mary School",
  "Tiny Blessings",
  "Tipas National High School",
  "Travellers International Hotel Group, Inc.",
  "Tuloy Foundation, Inc.",
  "Tunay na Alyansa ng Bayan Alay sa Katutubo (TABAK)",
  "Ubalde Elementary School",
  "Ugong Pasig National High School",
  "United Nations International Children's Emergency Fund (UNICEF)",
  "University of Asia and the Pacific Foundation, Inc.",
  "University of Santo Tomas",
  "University of Santo Tomas - Office for Student Affairs",
  "University of the East Alumni Foundation, Inc.",
  "University of the Philippines Medical Alumni Foundation, Inc. (UPMAF)",
  "University of the Philippines Mountaineers",
  "University of the Philippines Rural High School Alumni Class 1997",
  "University of the Philippines Vanguard, Inc",
  "US-Philippines Society",
  "Ususan Elementary School",
  "Victory Christian Fellowship of the Philippines, Inc.",
  "Villamor Air Base Elementary School",
  "Virlanie Foundation, Inc.",
  "Voice of Hydrocephalus Victim Children, Inc.",
  "Waray Bayaay",
  "Water Flow Foundation, Inc.",
  "We Exalt You Jesus Foundation, Inc.",
  "Western Bicutan National High School",
  "World of Outbound, Corp.",
  "World Vision Development Foundation, Inc.",
  "Yellow Boat of Hope Foundation",
  "YesPinoy Foundation, Inc.",
];
