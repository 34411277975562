// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Optionally import the services that you want to use
// import {...} from "firebase/auth";
// import {...} from "firebase/database";
// import {...} from "firebase/firestore";
// import {...} from "firebase/functions";
// import {...} from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC14GRSeBtTO1qYfZya2jjNi6ANRkgYa7g",
  authDomain: "data-banking-system.firebaseapp.com",
  projectId: "data-banking-system",
  storageBucket: "data-banking-system.appspot.com",
  messagingSenderId: "357681425614",
  appId: "1:357681425614:web:45c06991a668228d74349f",
  measurementId: "G-C5LR20495R",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestoredb = getFirestore(app);

export { auth, firestoredb };
// For more information on how to access Firebase in your project,
// see the Firebase documentation: https://firebase.google.com/docs/web/setup#access-firebase
