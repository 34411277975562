import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import SecondTopBar from "../SecondTopBar";
import { firestoredb } from "../../firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
//import { PartnerMapping } from "../../Data/PartnerMapping";
import { townshipLocationsMapping } from "../../Data/TownshipLocationsMapping";

const Dashboard = () => {
  console.log("Rendering Dashboard component");
  const [totalScholars, settotalScholars] = useState(0);
  const [totalVolunteers, settotalVolunteers] = useState(0);
  const [totalOrgs, settotalOrgs] = useState(0);
  const [totalBeneficiaries, settotalBeneficiaries] = useState(0);
  const [totalBarangay, settotalBarangay] = useState(0);
  const [totalExpenses, settotalExpenses] = useState(0.0);
  const [yearfrom, setYearfrom] = useState("");
  const [yearto, setYearto] = useState("");

  const [MEtotalScholars, setMEtotalScholars] = useState(0);
  const [MEtotalVolunteers, setMEtotalVolunteers] = useState(0);
  const [MEtotalOrgs, setMEtotalOrgs] = useState(0);
  const [MEtotalBeneficiaries, setMEtotalBeneficiaries] = useState(0);
  const [MEtotalBarangay, setMEtotalBarangay] = useState(0);
  const [MEtotalExpenses, setMEtotalExpenses] = useState(0.0);

  useEffect(() => {
    const getEventData = async () => {
      try {
        const collections = [
          { collectionName: 'PartnershipActivities', ref: collection(firestoredb, 'PartnershipActivities') },
          { collectionName: 'PartnershipDonation', ref: collection(firestoredb, 'PartnershipDonation') },
          { collectionName: 'PartnershipLivelihood', ref: collection(firestoredb, 'PartnershipLivelihood') },
          { collectionName: 'VolunteerismActivities', ref: collection(firestoredb, 'VolunteerismActivities') },
          { collectionName: 'ScholarshipData', ref: collection(firestoredb, 'ScholarshipData') },
        ];
        const snapshot = await Promise.all(collections.map(c => getDocs(c.ref)));
        let totalScholarsCount = 0;
        let totalVolunteersCount = 0;
        let totalOrgsCount = 0;
        let totalBeneficiariesCount = 0;
        let totalBarangayCount = 0;
        let totalExpensesCount = 0;
        let MEtotalScholarsCount = 0;
        let MEtotalVolunteersCount = 0;
        let MEtotalOrgsCount = 0;
        let MEtotalBeneficiariesCount = 0;
        let MEtotalBarangayCount = 0;
        let MEtotalExpensesCount = 0;

        let uniqueBarangays = new Set();
        let MEuniqueBarangays = new Set();

        let uniquePartners = new Set();
        let MEuniquePartners = new Set();
        const processSnapshot = (snapshot, collectionName) => {
          snapshot.forEach((doc) => {
            const data = doc.data();
            const year = data.year;
            if (
              (!yearfrom || yearfrom === '' || year >= yearfrom) &&
              (!yearto || yearto === '' || year <= yearto)
            ) {

                if (collectionName === 'ScholarshipData') {
                  totalScholarsCount += parseInt(data.noScholar || 0);
                  MEtotalScholarsCount += parseInt(data.noScholar || 0);
                }
                if (collectionName === 'VolunteerismActivities') {
                  data.locationFields.forEach((locationField)=>{

                    totalVolunteersCount += parseInt(locationField.volunteers || 0);
                    
                    if (data.MeGreenValue === "MeGreen") {
                      if (locationField.location) {
                        const barangays = townshipLocationsMapping[locationField.location];
                        if (barangays) {
                          barangays.forEach(barangay => {
                            if (barangay.trim()) {
                              MEuniqueBarangays.add(barangay.trim());
                            }
                          });
                        } else {
                          MEuniqueBarangays.add(locationField.location);
                        }
                      }

                      if (locationField.partners && Array.isArray(locationField.partners)) {
                        locationField.partners.forEach(partner => {
                          if (partner.trim()) {
                            MEuniquePartners.add(partner.trim());
                          }
                        });
                      }
                      MEtotalBarangayCount = MEuniqueBarangays.size;
                      MEtotalOrgsCount = MEuniquePartners.size;
                      MEtotalVolunteersCount += parseInt(locationField.volunteers || 0);
                      
                      MEtotalBeneficiariesCount += parseInt(locationField.beneficiaries || 0);
                      MEtotalExpensesCount += parseInt(locationField.expenses || 0);
                      
                    }
                  })

                }
                if (data.locationFields) {
                  // For other collections with locationFields
                  data.locationFields.forEach((locationField) => {
                    totalExpensesCount += parseInt(locationField.expenses || 0);
                    totalBeneficiariesCount += parseInt(locationField.beneficiaries);

                    if (locationField.location) {
                      const barangays = townshipLocationsMapping[locationField.location];
                      if (barangays) {
                        barangays.forEach(barangay => {
                          if (barangay.trim()) {
                            uniqueBarangays.add(barangay.trim());
                          }
                        });
                      } else {
                        uniqueBarangays.add(locationField.location);
                      }
                    }
                    if (locationField.partners && Array.isArray(locationField.partners)) {
                      locationField.partners.forEach(partner => {
                        if (partner.trim()) {
                          uniquePartners.add(partner.trim());
                        }
                      });
                    }
                    
                  });
                }

                totalBarangayCount = uniqueBarangays.size;
                totalOrgsCount = uniquePartners.size;
          }
      });
    }
    

        snapshot.forEach((snapshot, index) => processSnapshot(snapshot, collections[index].collectionName));

        // Set the state variables
        settotalScholars(totalScholarsCount);
        settotalVolunteers(totalVolunteersCount);
        settotalOrgs(totalOrgsCount);
        settotalBeneficiaries(totalBeneficiariesCount);
        settotalBarangay(totalBarangayCount);
        settotalExpenses(totalExpensesCount);

        setMEtotalScholars(MEtotalScholarsCount);
        setMEtotalVolunteers(MEtotalVolunteersCount);
        setMEtotalOrgs(MEtotalOrgsCount);
        setMEtotalBeneficiaries(MEtotalBeneficiariesCount);
        setMEtotalBarangay(MEtotalBarangayCount);
        setMEtotalExpenses(MEtotalExpensesCount);
      } catch (error) {
        console.error("Error fetching Volunteerism Activities:", error);
      }
    };

    // Call the function to fetch data
    getEventData();
  }, [yearfrom, yearto]);


  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear(); // Get the current year
    const startYear = 1997; // Start year
    const yearOptions = [];

    // Loop from the start year to the current year
    for (let i = currentYear; i >= startYear; i--) {
      yearOptions.push(i.toString()); // Push each year as a string to the options array
    }

    return yearOptions; // Return the array of year options
  };
  const handleYearFromChange = (e) => {
    const selectedYearFrom = e.target.value;

    // Set yearfrom based on the selected value
    setYearfrom(selectedYearFrom);

    // If "Overall" is selected, clear both yearfrom and yearto
    if (selectedYearFrom === "") {
      setYearto(""); // Clear the value in yearto
    } else if (yearto && selectedYearFrom > yearto) {
      // If the selected value of "Year To" is smaller than "Year From", update "Year To" to match "Year From"
      setYearto(selectedYearFrom);
    }
  };

  // Event handler for "Year To" change
  const handleYearToChange = (e) => {
    // If the selected value of "Year To" is smaller than "Year From", prevent updating "Year To"
    setYearto(e.target.value);
    if (yearfrom === "") {
      setYearto("2024");
      return;
    }
    if (yearfrom && e.target.value < yearfrom) {
      return;
    }
  };

  return (
    <Box>
      <SecondTopBar />
      <Box
        borderTop="1rem solid #ADBDA3"
        width="auto"
        height="calc(100vh - 25px)"
      >
        <Box
          marginRight="25px"
          marginTop="15px"
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <FormControl style={{ width: "100px", height: "25px" }}>
            <InputLabel>Year From:</InputLabel>
            <Select
              style={{ height: "35px" }}
              label="Year From"
              value={yearfrom}
              MenuProps={{
                MenuListProps: {
                  style: {
                    maxHeight: 150, // Set the maximum height of the dropdown
                  },
                },
              }}
              onChange={handleYearFromChange}
            >
              <MenuItem value="">Overall</MenuItem>
              {generateYearOptions().map((option) => (
                <MenuItem key={option} value={option}>
                  {" "}
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {yearfrom && yearfrom !== "" && (
            <FormControl
              style={{ marginLeft: "20px", width: "100px", height: "25px" }}
            >
              <InputLabel>Year to</InputLabel>
              <Select
                style={{ height: "35px" }}
                label="Year to"
                value={yearto}
                MenuProps={{
                  MenuListProps: {
                    style: {
                      maxHeight: 150, // Set the maximum height of the dropdown
                    },
                  },
                }}
                onChange={handleYearToChange}
              >
                {generateYearOptions().map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Box>
        <Box marginTop="25px" marginLeft="25px" display="flex">
          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #ADBDA3"
            maxWidth=" 500px"
            width="100%"
            height="120px"
            backgroundColor="#ADBDA3"
            borderRadius="15px"
            padding="20px"
            marginRight="25px"
            display="flex" // Flex container
            alignItems="flex-end"
          >
            <Typography
              fontSize="60px"
              fontWeight="900"
              color="#174A55"
              marginBottom="-13px"
            >
              {totalScholars.toLocaleString()}
            </Typography>
            <Typography
              fontSize="30px"
              fontWeight="700"
              color="#174A55"
              marginLeft=" 15px"
            >
              scholars
            </Typography>
          </Box>
          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #ADBDA3"
            maxWidth=" 500px"
            width="100%"
            height="120px"
            backgroundColor="#ADBDA3"
            borderRadius="15px"
            padding="20px"
            marginRight="25px"
            display="flex" // Flex container
            alignItems="flex-end"
          >
            <Typography
              fontSize="60px"
              fontWeight="900"
              color="#174A55"
              marginBottom="-13px"
            >
              {totalVolunteers.toLocaleString()}
            </Typography>
            <Typography
              fontSize="30px"
              fontWeight="700"
              color="#174A55"
              marginLeft=" 15px"
            >
              volunteers
            </Typography>
          </Box>
          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #ADBDA3"
            maxWidth=" 750px"
            marginRight="20px"
            width="100%"
            height="120px"
            backgroundColor="#ADBDA3"
            borderRadius="15px"
            padding="20px"
            display="flex" // Flex container
            alignItems="flex-end"
          >
            <Typography
              fontSize="60px"
              fontWeight="900"
              color="#174A55"
              marginBottom="-13px"
            >
              {totalOrgs.toLocaleString()}
            </Typography>
            <Typography
              fontSize="30px"
              fontWeight="700"
              color="#174A55"
              marginLeft=" 15px"
              lineHeight="1"
              marginBottom="8px"
            >
              partner organizations
            </Typography>
          </Box>
        </Box>

        <Box marginTop="25px" marginLeft="25px" display="flex">
          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #ADBDA3"
            maxWidth=" 500px"
            width="100%"
            height="120px"
            backgroundColor="#ADBDA3"
            borderRadius="15px"
            padding="20px"
            marginRight="25px"
            display="flex" // Flex container
            alignItems="flex-end"
          >
            <Typography
              fontSize="60px"
              fontWeight="900"
              color="#174A55"
              marginBottom="-13px"
            >
              {totalBeneficiaries.toLocaleString()}
            </Typography>
            <Typography
              fontSize="30px"
              fontWeight="700"
              color="#174A55"
              marginLeft=" 15px"
            >
              beneficiaries
            </Typography>
          </Box>
          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #ADBDA3"
            maxWidth=" 500px"
            width="100%"
            height="120px"
            backgroundColor="#ADBDA3"
            borderRadius="15px"
            padding="20px"
            marginRight="25px"
            display="flex" // Flex container
            alignItems="flex-end"
          >
            <Typography
              fontSize="60px"
              fontWeight="900"
              color="#174A55"
              marginBottom="-13px"
            >
              {totalBarangay.toLocaleString()}
            </Typography>
            <Typography
              fontSize="30px"
              fontWeight="700"
              color="#174A55"
              marginLeft=" 15px"
              lineHeight="1"
              marginBottom="8px"
            >
              barangays reached
            </Typography>
          </Box>
          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #ADBDA3"
            maxWidth=" 750px"
            marginRight="20px"
            width="100%"
            height="120px"
            backgroundColor="#ADBDA3"
            borderRadius="15px"
            padding="20px"
            display="flex" // Flex container
            alignItems="flex-end"
          >
            <Typography
              fontSize="60px"
              fontWeight="900"
              color="#174A55"
              display="flex"
              alignItems="flex-end"
              style={{ marginBottom: "-13px" }}
            >
              <span style={{ marginRight: "5px" }}>P</span>
              {totalExpenses.toLocaleString()}
            </Typography>
            <Typography
              fontSize="30px"
              fontWeight="700"
              color="#174A55"
              marginLeft=" 15px"
            >
              expenses
            </Typography>
          </Box>
        </Box>

        <Box display="flex" margin="25px">
          <Box maxHeight="50px" maxWidth="75px" marginLeft="1rem">
            <img
              src="MEGreen3.png"
              alt="MEGreen"
              style={{ width: "100%", height: "120%" }}
            />
          </Box>
          <Box display="flex" marginLeft="2rem">
            <Typography
              fontSize="40px"
              fontWeight="900"
              color="#174A55"
              marginTop="5px"
            >
              VOLUNTEERISM PROJECTS
            </Typography>
          </Box>
        </Box>

        <Box marginLeft="25px" display="flex">
          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #174A55"
            maxWidth=" 500px"
            width="100%"
            height="120px"
            backgroundColor="#174A55"
            borderRadius="15px"
            padding="20px"
            marginRight="25px"
            display="flex" // Flex container
            alignItems="flex-end"
          >
            <Typography
              fontSize="60px"
              fontWeight="900"
              color="#ADBDA3"
              marginBottom="-13px"
            >
              {MEtotalScholars.toLocaleString()}
            </Typography>
            <Typography
              fontSize="30px"
              fontWeight="700"
              color="#ADBDA3"
              marginLeft=" 15px"
            >
              scholars
            </Typography>
          </Box>
          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #174A55"
            maxWidth=" 500px"
            width="100%"
            height="120px"
            backgroundColor="#174A55"
            borderRadius="15px"
            padding="20px"
            marginRight="25px"
            display="flex" // Flex container
            alignItems="flex-end"
          >
            <Typography
              fontSize="60px"
              fontWeight="900"
              color="#ADBDA3"
              marginBottom="-13px"
            >
              {MEtotalVolunteers.toLocaleString()}
            </Typography>
            <Typography
              fontSize="30px"
              fontWeight="700"
              color="#ADBDA3"
              marginLeft=" 15px"
            >
              volunteers
            </Typography>
          </Box>
          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #174A55"
            maxWidth=" 750px"
            marginRight="20px"
            width="100%"
            height="120px"
            backgroundColor="#174A55"
            borderRadius="15px"
            padding="20px"
            display="flex" // Flex container
            alignItems="flex-end"
          >
            <Typography
              fontSize="60px"
              fontWeight="900"
              color="#ADBDA3"
              marginBottom="-13px"
            >
              {MEtotalOrgs.toLocaleString()}
            </Typography>
            <Typography
              fontSize="30px"
              fontWeight="700"
              color="#ADBDA3"
              marginLeft=" 15px"
              lineHeight="1"
              marginBottom="8px"
            >
              partner organizations
            </Typography>
          </Box>
        </Box>

        <Box marginLeft="25px" display="flex" marginTop="25px">
          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #174A55"
            maxWidth=" 500px"
            width="100%"
            height="120px"
            backgroundColor="#174A55"
            borderRadius="15px"
            padding="20px"
            marginRight="25px"
            display="flex" // Flex container
            alignItems="flex-end"
          >
            <Typography
              fontSize="60px"
              fontWeight="900"
              color="#ADBDA3"
              marginBottom="-13px"
            >
              {MEtotalBeneficiaries.toLocaleString()}
            </Typography>
            <Typography
              fontSize="30px"
              fontWeight="700"
              color="#ADBDA3"
              marginLeft=" 15px"
            >
              beneficiaries
            </Typography>
          </Box>
          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #174A55"
            maxWidth=" 500px"
            width="100%"
            height="120px"
            backgroundColor="#174A55"
            borderRadius="15px"
            padding="20px"
            marginRight="25px"
            display="flex" // Flex container
            alignItems="flex-end"
          >
            <Typography
              fontSize="60px"
              fontWeight="900"
              color="#ADBDA3"
              marginBottom="-13px"
            >
              {MEtotalBarangay.toLocaleString()}
            </Typography>
            <Typography
              fontSize="30px"
              fontWeight="700"
              color="#ADBDA3"
              marginLeft=" 15px"
              lineHeight="1"
              marginBottom="8px"
            >
              barangays reached
            </Typography>
          </Box>
          <Box
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.3)"
            border="1px solid #174A55"
            maxWidth=" 750px"
            marginRight="20px"
            width="100%"
            height="120px"
            backgroundColor="#174A55"
            borderRadius="15px"
            padding="20px"
            display="flex" // Flex container
            alignItems="flex-end"
          >
            <Typography
              fontSize="60px"
              fontWeight="900"
              color="#ADBDA3"
              marginBottom="-13px"
            >
              <span style={{ marginRight: "5px" }}>P</span>
              {MEtotalExpenses.toLocaleString()}
            </Typography>
            <Typography
              fontSize="30px"
              fontWeight="700"
              color="#ADBDA3"
              marginLeft=" 15px"
              lineHeight="1"
            >
              expenses
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
