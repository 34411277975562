import React, { useState, useEffect } from "react";
import { Box, Typography, Button, TextField, Modal } from "@mui/material";
//import { useNavigate } from 'react-router-dom';
import { getDoc, doc } from "firebase/firestore";
import { firestoredb } from "../../firebaseConfig";

const UserProfile = ({ open, handleClose }) => {
  const [user, setUser] = useState("");
  //const navigate = useNavigate();
  const [showUserProfile, setShowUserProfile] = useState(true);
  const [editClicked, setEditClicked] = useState(false);
  useEffect(() => {
    const fetchUser = async () => {
      try {
        // Get the userId from sessionStorage
        const userId = sessionStorage.getItem("userId");
        if (!userId) {
          console.log("User ID not found in sessionStorage");
          return;
        }

        // Get the user document from Firestore
        const userDocRef = doc(firestoredb, "users", userId);
        const userDocSnapshot = await getDoc(userDocRef);
        if (userDocSnapshot.exists()) {
          // Extract the user data from the user document
          const userData = userDocSnapshot.data();
          setUser(userData);
        } else {
          console.log("User not found");
        }
      } catch (error) {
        console.error("Error fetching user data:", error.message);
      }
    };

    fetchUser();
  }, []);

  // const handleEditProfile = () => {
  //   // Navigate to the edit profile page
  //   navigate(`/edit-profile`);
  // };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        margin="20px"
        position="absolute"
        maxWidth="750px"
        width="100%"
        top="20%"
        left=" 30%"
        transform="translate(-50%, -50%)"
      >
        <Box
          display="flex"
          height="430px"
          sx={{
            backgroundColor: "#fefefe",
            padding: "30px",
            borderRadius: "15px",
            marginBottom: "20px",
          }}
        >
          <Box display="flex" flexDirection="column" width="250px">
            <Button
              sx={{
                justifyContent: "flex-start",
                borderBottom: showUserProfile ? "4px solid #58827E" : "none",
              }}
              onClick={() => setShowUserProfile(true)}
            >
              <Typography fontSize="20px" fontWeight="600">
                {" "}
                User Profile{" "}
              </Typography>
            </Button>

            <Button
              sx={{
                justifyContent: "flex-start",
                borderBottom: showUserProfile ? "none" : "4px solid #58827E",
              }}
              onClick={() => setShowUserProfile(false)}
            >
              <Typography fontSize="20px" fontWeight="600">
                {" "}
                Password Setting{" "}
              </Typography>
            </Button>
          </Box>
          <Box>
            {showUserProfile && (
              <>
                <Box
                  display="flex"
                  flexDirection="column"
                  width="450px"
                  sx={{ alignItems: "flex-end" }}
                >
                  <TextField
                    value={user.displayName}
                    label=" Display Name"
                    sx={{ marginBottom: "20px" }}
                    InputProps={{ style: { width: "400px", height: "50px" } }}
                  />
                  <TextField
                    value={user.firstName}
                    label=" First Name"
                    sx={{ marginBottom: "20px" }}
                    InputProps={{ style: { width: "400px", height: "50px" } }}
                  />
                  <TextField
                    value={user.lastName}
                    label=" Last Name"
                    sx={{ marginBottom: "20px" }}
                    InputProps={{ style: { width: "400px", height: "50px" } }}
                  />
                  <TextField
                    value={user.email}
                    label=" E-Mail"
                    sx={{ marginBottom: "20px" }}
                    InputProps={{ style: { width: "400px", height: "50px" } }}
                  />
                  <TextField
                    value={user.role}
                    label=" Role"
                    sx={{ marginBottom: "20px" }}
                    InputProps={{
                      style: { readOnly: true, width: "400px", height: "50px" },
                    }}
                  />
                </Box>
              </>
            )}

            {!showUserProfile && (
              <>
                <Box
                  display="flex"
                  flexDirection="column"
                  width="450px"
                  sx={{ alignItems: "flex-end" }}
                >
                  <TextField
                    value={user.password}
                    label="Current Password"
                    sx={{ marginBottom: "20px" }}
                    InputProps={{
                      style: { readOnly: true, width: "400px", height: "50px" },
                    }}
                  />
                  <TextField
                    value={user.password}
                    label="New Password"
                    sx={{ marginBottom: "20px" }}
                    InputProps={{
                      style: { readOnly: true, width: "400px", height: "50px" },
                    }}
                  />
                  <TextField
                    value={user.password}
                    label="Re-Type Password"
                    sx={{ marginBottom: "20px" }}
                    InputProps={{
                      style: { readOnly: true, width: "400px", height: "50px" },
                    }}
                  />
                </Box>
              </>
            )}

            <Box display="flex" justifyContent="flex-end">
              {!editClicked && (
                <Button
                  sx={{
                    backgroundColor: "#58827E",
                    color: "#ffffff",
                    "&:hover": { backgroundColor: "#4a6c68" },
                  }}
                  onClick={() => setEditClicked(true)}
                >
                  <Typography> Edit </Typography>
                </Button>
              )}
              {editClicked && (
                <>
                  <Button
                    sx={{
                      marginRight: "10px",
                      backgroundColor: "#58827E",
                      color: "#ffffff",
                      "&:hover": { backgroundColor: "#4a6c68" },
                    }}
                  >
                    <Typography> Save </Typography>
                  </Button>
                  <Button onClick={() => setEditClicked(false)}>
                    <Typography> Cancel </Typography>
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default UserProfile;
