// App.js
import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
//Global
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
//Scenes
import Dashboard from "./scenes/dashboard";
//Dashboard Sub Scenes
import DashScholarship from "./scenes/dashScholarship";
import DashVolunteer from "./scenes/dashVolunteer";
import DashPartnership from "./scenes/dashPartnership";

import Scholarship from "./scenes/scholarship";
//Partnership Sub Scenes
import PartnershipActivity from "./scenes/partnershipActivity";
import PartnershipDonation from "./scenes/partnershipDonation";
import PartnershipLivelihood from "./scenes/partnershipLivelihood";
import Volunteerism from "./scenes/volunteerism";
import Financial from "./scenes/financial";
//Auth
import LoginPage from "./Auth/Login";
//Users
import Users from "./Users/users";
//Forms
//Addforms
import Volform from "./forms/AddForms/VolformAdd";
import Schoform from "./forms/AddForms/SchoformAdd";
import PartnershipActivityForm from "./forms/AddForms/PartActivityAddForm";
import PartnershipDonationForm from "./forms/AddForms/PartDonateAddForm";
import PartnershipLivelihoodForm from "./forms/AddForms/PartLiveAddForm";
//ShowForms
import VolSeeform from "./forms/ShowForms/VolShowform";
import SchoSeeform from "./forms/ShowForms/SchoShowform";
import PartActForm from "./forms/ShowForms/PartActivityShowForm";
import PartLivelihoodForm from "./forms/ShowForms/PartLivelihoodShowForm";
import PartDonationForm from "./forms/ShowForms/PartDonationShowForm";

import UserProfile from "./Users/UserProfile";


function App() {
  const [theme, colorMode] = useMode();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Check if the user is authenticated on page load
    const storedAuthStatus = sessionStorage.getItem("isAuthenticated");
    console.log("Stored Auth Status:", storedAuthStatus);

    if (storedAuthStatus === "true") {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false); // Ensure the state is explicitly set to false
    }
  }, []);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {isAuthenticated && (
            <>
              <Sidebar />
              <main className="content">
                <Topbar setIsAuthenticated={setIsAuthenticated} />
                <Routes>
                  {/*MAIN TABS*/}
                  <Route index element={<Dashboard />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/scholarship" element={<Scholarship />} />
                  <Route path="/volunteerism" element={<Volunteerism />} />
                  <Route path="/partnershipActivity" element={<PartnershipActivity />}/>
                  <Route path="/partnershipDonation" element={<PartnershipDonation />}/>
                  <Route path="/partnershipLivelihood" element={<PartnershipLivelihood />}/>
                  <Route path="/financial" element={<Financial />} />
                  <Route path="/users" element={<Users />} />
                  {/* ADD FORMS*/}
                  <Route path="/VolformAdd" element={<Volform />} />
                  <Route path="/SchoformAdd" element={<Schoform/>} />
                  <Route path="/PartActivityAddForm" element={<PartnershipActivityForm />}/>
                  <Route path="/PartDonateAddForm" element={<PartnershipDonationForm />}/>
                  <Route path="/PartLiveAddForm" element={<PartnershipLivelihoodForm />}/>
                  {/* SHOW FORMS*/}
                  <Route path="/VolShowform/:documentId" element={<VolSeeform />}/>
                  <Route path="/SchoShowform/:documentId" element={<SchoSeeform/>} />
                  <Route path="/PartActivityShowForm/:documentId" element={<PartActForm />}/>
                  <Route path="/PartDonationShowForm/:documentId" element={<PartDonationForm />}/>
                  <Route path="/PartLivelihoodShowForm/:documentId" element={<PartLivelihoodForm />}/>
                  {/*DASHBOARD*/}
                  <Route path="/dashScholarship" element={<DashScholarship />} />
                  <Route path="/dashVolunteer" element={<DashVolunteer />} />
                  <Route path="/dashPartnership" element={<DashPartnership />} />
                  <Route path="/UserProfile" element={<UserProfile />} />
                </Routes>
              </main>
            </>
          )}

          {/* Always show LoginPage, but only if not authenticated */}
          {!isAuthenticated && (
            <Routes>
              <Route
                path="/"
                element={<LoginPage setIsAuthenticated={setIsAuthenticated} />}
              />
            </Routes>
          )}
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
