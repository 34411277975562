
export const getCompanyOptions = () => {
    return [    
                'Anglo Watsons Glass, Inc.',
                'Asia Affinity Property Management, Inc.',
                'Emperador, Inc.',
                'Empire East Land Holdings, Inc.',
                'First Oceanic Property Management, Inc.',
                'Global-Estate Resorts, Inc.',
                'Golden Arches Development, Inc.',
                'Infracorp Development Inc.',
                'Megaworld Corporation',
                'Megaworld Hotels & Resorts',
                'MREIT, Inc.',
                'Pickaroo',
                'ProGreen Agricorp., Inc.',
                'Suntrust Properties, Inc.',
                'Travellers International Hotel Group, Inc.'
          ];
  };
 