import React, { useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  ToggleButton,
  Typography,
  Grid,
  InputAdornment,
  Autocomplete,
  Chip
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { firestoredb } from "../../../firebaseConfig"; // Replace with your Firebase configuration file
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { townshipLocationsMapping } from "../../../Data/TownshipLocationsMapping";
import { PartnerMapping } from "../../../Data/PartnerMapping";

const Volform = () => {
  const [sdgName, setSdgName] = useState("");
  const [activityType, setActivityType] = useState("");
  const [projectName, setProjectName] = useState("");
  const [year, setYear] = useState("");
  const navigate = useNavigate();
  const [isFocused, setIsFocused] = useState(false);
  const [isFocused1, setIsFocused1] = useState(false);
  const [toggled, setIstoggled] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const [locationFields, setLocationFields] = useState([
    {
      date: null,
      township: "",
      location: "",
      beneficiaries: "",
      volunteers: "",
      hoursRendered: "",
      partners: [],
      budget: "",
      expenses: "",
      EBlearners: "",
      BEpainted: "",
      FPmeals: "",
      CAGifts: "",
      TPplanted: "",
      TPhectares: "",
      remarks: "",
    },
  ]);

  const handleAddLocation = () => {
    setLocationFields([
      ...locationFields,
      {
        date: null, // Initialize with the current date or a default date.
        township: "",
        location: "",
        beneficiaries: "",
        volunteers: "",
        hoursRendered: "",
        partners: [],
        budget: "",
        expenses: "",
        EBlearners: "",
        BEpainted: "",
        FPmeals: "",
        CAGifts: "",
        TPplanted: "",
        TPhectares: "",
        remarks: "",
      },
    ]);
  };

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear(); // Get the current year
    const startYear = 1997; // Start year
    const yearOptions = [];

    // Loop from the start year to the current year
    for (let i = currentYear; i >= startYear; i--) {
      yearOptions.push(i.toString()); // Push each year as a string to the options array
    }

    return yearOptions; // Return the array of year options
  };

  const handleRemoveLocation = (index) => {
    const newFields = [...locationFields];
    newFields.splice(index, 1);
    setLocationFields(newFields);
  };

  const handleCancel = () => {
    // Use history.goBack() to navigate back to the previous screen
    navigate("/volunteerism");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Add the form data to Firestore
      const MeGreenValue = toggled ? "MeGreen" : "";
      const docRef = await addDoc(
        collection(firestoredb, "VolunteerismActivities"),
        {
          sdgName,
          activityType,
          projectName,
          year,
          locationFields,
          MeGreenValue,
          timestamp: serverTimestamp(),
        },
      );
      console.log("Document written with ID: ", docRef.id);
      setSdgName("");
      setActivityType("");
      setProjectName("");
      setYear("");
      setLocationFields([
        ...locationFields,
        {
          date: new Date(),
          township: "",
          location: "",
          beneficiaries: "",
          volunteers: "",
          hoursRendered: "",
          partners: [],
          budget: "",
          expenses: "",
          EBlearners: "",
          BEpainted: "",
          FPmeals: "",
          CAGifts: "",
          TPplanted: "",
          TPhectares: "",
          remarks: "",
        },
      ]);

      setIstoggled(false);
      localStorage.setItem("alertSeverity", "success");
      localStorage.setItem("alertMessage", "Activity added successfully.");
      localStorage.setItem("alertopen", "true");
    } catch (error) {
      console.error("Error adding document: ", error);
      localStorage.setItem("alertSeverity", "error");
      localStorage.setItem("alertMessage", "Adding activity failed.");
      localStorage.setItem("alertopen", "false");
    }
    navigate("/volunteerism");
  };

  return (
    <Box
      backgroundColor="white"
      padding="10px"
      margin="10px"
      borderRadius="15px"
    >
      <form onSubmit={handleSubmit}>
        <Typography variant="h4" fontWeight="bold" margin="5px" pb="20px">
          Volunteerism Activity Profile
        </Typography>

        <Grid container spacing={2}>
          {/* Other form fields */}
          <Grid item xs={0.5}>
            <ToggleButton
              value="MeGreen"
              selected={toggled}
              style={{
                width: "55px",
                height: "100%",
                backgroundColor: toggled ? "#849F77" : undefined,
                maxHeight: "53px",
              }}
              onChange={() => {
                setIstoggled(!toggled);
              }}
            >
              <img
                src="MEGreen2.png"
                alt="MEGreen"
                style={{ width: "75%", height: "120%" }}
              />
            </ToggleButton>
          </Grid>
          <Grid item xs={2.875}>
            <FormControl fullWidth>
              <InputLabel>SDG Name</InputLabel>
              <Select
                required
                value={sdgName}
                label="SDG Name"
                MenuProps={{
                  MenuListProps: {
                    style: {
                      maxHeight: 200, // Set the maximum height of the dropdown
                    },
                  },
                }}
                onChange={(e) => setSdgName(e.target.value)}
              >
                <MenuItem value="1"> 1. No Poverty</MenuItem>
                <MenuItem value="2"> 2. Zero Hunger</MenuItem>
                <MenuItem value="3"> 3. Good Health and Well-being</MenuItem>
                <MenuItem value="4"> 4. Quality Education</MenuItem>
                <MenuItem value="5"> 5. Gender Equality</MenuItem>
                <MenuItem value="6"> 6. Clean Water and Sanitation</MenuItem>
                <MenuItem value="7"> 7. Affordable and Clean Energy</MenuItem>
                <MenuItem value="8">
                  {" "}
                  8. Decent Work and Economic Growth
                </MenuItem>
                <MenuItem value="9">
                  {" "}
                  9. Industry, Innovation and Infrastructure
                </MenuItem>
                <MenuItem value="10">10. Reduced Inequalities</MenuItem>
                <MenuItem value="11">
                  11. Sustainable Cities and Communities
                </MenuItem>
                <MenuItem value="12">
                  12. Responsible Consumption and Production
                </MenuItem>
                <MenuItem value="13">13. Climate Action</MenuItem>
                <MenuItem value="14">14. Life Below Water</MenuItem>
                <MenuItem value="15">15. Life on Land</MenuItem>
                <MenuItem value="16">
                  16. Peace, Justice and Strong Institutions
                </MenuItem>
                <MenuItem value="17">17. Partnerships for the Goals</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={2.875}>
            <FormControl fullWidth>
              <InputLabel>Activity Type</InputLabel>
              <Select
                value={activityType}
                required
                label="Type of Activity"
                onChange={(e) => setActivityType(e.target.value)}
              >
                <MenuItem value="Brigada Eskwela"> Brigada Eskwela</MenuItem>
                <MenuItem value="Eskwela ng Bayan"> Eskwela ng Bayan</MenuItem>
                <MenuItem value="Feeding Program"> Feeding Program</MenuItem>
                <MenuItem value="Medical-Dental Mission">
                  {" "}
                  Medical-Dental Mission
                </MenuItem>
                <MenuItem value="Christmas Activity">
                  Christmas Activity
                </MenuItem>
                <MenuItem value="Tree Planting"> Tree Planting</MenuItem>
                <MenuItem value="Other Project"> Other Activity</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {activityType === "Christmas Activity" ? (
            <Grid item xs={2.875}>
              <FormControl fullWidth>
                <InputLabel>Activity Name</InputLabel>
                <Select
                  value={projectName}
                  required
                  label="Activity Name"
                  onChange={(e) => setProjectName(e.target.value)}
                >
                  <MenuItem value="Leadership with A Heart">
                    {" "}
                    Leadership with A Heart
                  </MenuItem>
                  <MenuItem value="Mega Santa"> Mega Santa</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          ) : (
            <Grid item xs={2.875}>
              <TextField
                fullWidth
                required
                label="Activity Name"
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
              />
            </Grid>
          )}

          <Grid item xs={2.875}>
            <FormControl fullWidth required>
              <InputLabel>Year</InputLabel>
              <Select
                label="Year"
                value={year}
                MenuProps={{
                  MenuListProps: {
                    style: {
                      maxHeight: 200, // Set the maximum height of the dropdown
                    },
                  },
                }}
                onChange={(e) => setYear(e.target.value)}
              >
                {generateYearOptions().map((option) => (
                  <MenuItem key={option} value={option}>
                    {" "}
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid>
          {locationFields.map((location, index) => (
            <Grid container spacing={2} pt="10px" pb="5px" key={index}>
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ width: "100%" }}
                    fullWidth
                    label="Date"
                    value={location.date ? dayjs(location.date) : null}
                    required
                    onChange={(date) => {
                      const dateString = date
                        ? dayjs(date).format("YYYY-MM-DD")
                        : null; // Format the date as a string
                      const newFields = [...locationFields];
                      newFields[index].date = dateString;
                      setLocationFields(newFields);
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel>Township</InputLabel>
                  <Select
                    label="Township"
                    value={location.township}
                    required
                    MenuProps={{
                      MenuListProps: {
                        style: {
                          maxHeight: 200, // Set the maximum height of the dropdown
                        },
                      },
                    }}
                    onChange={(e) => {
                      const newFields = [...locationFields];
                      newFields[index].township = e.target.value;
                      setLocationFields(newFields);
                    }}
                  >
                    {/* Add MenuItem components for each  in the dropdown */}
                    <MenuItem value="1"> Alabang West</MenuItem>
                    <MenuItem value="2">Arcovia City</MenuItem>
                    <MenuItem value="3">Arden Botanical Estate</MenuItem>
                    <MenuItem value="4">Baytown Palawan</MenuItem>
                    <MenuItem value="5">Boracay Newcoast</MenuItem>
                    <MenuItem value="6">Capital Town</MenuItem>
                    <MenuItem value="7">Davao Park District</MenuItem>
                    <MenuItem value="8">Eastland Heights </MenuItem>
                    <MenuItem value="9">Eastwood City</MenuItem>
                    <MenuItem value="10">Forbes Town</MenuItem>
                    <MenuItem value="11">Highland City</MenuItem>
                    <MenuItem value="12">Ilo-ilo Business Park</MenuItem>
                    <MenuItem value="13">Lucky Chinatown</MenuItem>
                    <MenuItem value="14">Maple Grove</MenuItem>
                    <MenuItem value="15">Mckinley Hill</MenuItem>
                    <MenuItem value="16">Mckinley West</MenuItem>
                    <MenuItem value="17">Newport City</MenuItem>
                    <MenuItem value="18">Northill Gateway</MenuItem>
                    <MenuItem value="19">Northwin Global City</MenuItem>
                    <MenuItem value="20">Paragua Coastown</MenuItem>
                    <MenuItem value="21">Sherwood Hills</MenuItem>
                    <MenuItem value="22">Southwoods City</MenuItem>
                    <MenuItem value="23">Sta Barbara Heights</MenuItem>
                    <MenuItem value="24">Suntrust Ecotown</MenuItem>
                    <MenuItem value="25">The Hamptons Caliraya</MenuItem>
                    <MenuItem value="26">The Mactan Newtown</MenuItem>
                    <MenuItem value="27">The Upper East</MenuItem>
                    <MenuItem value="28">Twin Lakes</MenuItem>
                    <MenuItem value="29">Uptown Bonifacio</MenuItem>
                    <MenuItem value="30">Westside City</MenuItem>
                    <MenuItem value="31">Winford Resort Estate</MenuItem>
                    <MenuItem value="32">Others</MenuItem>

                    {/* Add more MenuItem components as needed */}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                {/* Render a TextField for Location if Township is "Others" */}
                {location.township === "4" ||
                location.township === "21" ||
                location.township === "31" ||
                location.township === "32" ? (
                  <TextField
                    fullWidth
                    label="Barangay"
                    value={location.location}
                    required
                    onChange={(e) => {
                      const newFields = [...locationFields];
                      newFields[index].location = e.target.value;
                      setLocationFields(newFields);
                    }}
                  />
                ) : (
                  <FormControl fullWidth>
                    <InputLabel>Barangay</InputLabel>
                    <Select
                      label="Barangay"
                      value={location.location}
                      required
                      MenuProps={{
                        MenuListProps: {
                          style: {
                            maxHeight: 200, // Set the maximum height of the dropdown
                          },
                        },
                      }}
                      onChange={(e) => {
                        const newFields = [...locationFields];
                        newFields[index].location = e.target.value;
                        setLocationFields(newFields);
                      }}
                    >
                      {townshipLocationsMapping[location.township]?.map(
                        (loc, locIndex) => (
                          <MenuItem key={locIndex} value={loc}>
                            {loc}
                          </MenuItem>
                        ),
                      )}
                      <MenuItem value="Others">Others</MenuItem>
                    </Select>
                  </FormControl>
                )}
              </Grid>

              {(activityType === "Brigada Eskwela" ||
                activityType === "Eskwela ng Bayan" ||
                activityType === "Feeding Program" ||
                activityType === "Medical-Dental Mission" ||
                activityType === "Christmas Activity" ||
                activityType === "Other Project") && (
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Number of Beneficiaries"
                    value={location.beneficiaries}
                    required
                    onChange={(e) => {
                      const newFields = [...locationFields];
                      const numericValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                      newFields[index].beneficiaries = numericValue;
                      setLocationFields(newFields);
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Number of Volunteers"
                  value={location.volunteers}
                  required
                  onChange={(e) => {
                    const newFields = [...locationFields];
                    const numericValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                    newFields[index].volunteers = numericValue;
                    setLocationFields(newFields);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Hours Rendered by Volunteers"
                  required
                  value={location.hoursRendered}
                  onChange={(e) => {
                    const input = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                    const newFields = [...locationFields];
                    newFields[index].hoursRendered = input;

                    setLocationFields(newFields);
                  }}
                />
              </Grid>

              

              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Proposed Budget"
                  value={location.budget}
                  required
                  onChange={(e) => {
                    const input = e.target.value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters
                    const newFields = [...locationFields];
                    newFields[index].budget = input;

                    setLocationFields(newFields);
                  }}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  InputProps={{
                    startAdornment: location.budget && !isFocused && (
                      <InputAdornment position="start">
                        <Typography variant="h6" color="textSecondary">
                          P
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Actual Expense"
                  value={location.expenses}
                  required
                  onChange={(e) => {
                    const input = e.target.value.replace(/[^0-9.]/g, "");
                    const newFields = [...locationFields];
                    newFields[index].expenses = input;

                    setLocationFields(newFields);
                  }}
                  onFocus={() => setIsFocused1(true)}
                  onBlur={() => setIsFocused1(false)}
                  InputProps={{
                    startAdornment: location.expenses && !isFocused1 && (
                      <InputAdornment position="start">
                        <Typography variant="h6" color="textSecondary">
                          P
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              

              {/* -----------SITUATIONAL FIELDS------------------------------------------------------------------------------------------------------------*/}
              {activityType === "Eskwela ng Bayan" && (
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Students Assisted"
                    value={location.EBlearners}
                    required
                    onChange={(e) => {
                      const input = e.target.value.replace(/\D/g, "");
                      const newFields = [...locationFields];
                      newFields[index].EBlearners = input;

                      setLocationFields(newFields);
                    }}
                  />
                </Grid>
              )}

              {activityType === "Brigada Eskwela" && (
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Number of Classrooms Painted"
                    value={location.BEpainted}
                    required
                    onChange={(e) => {
                      const input = e.target.value.replace(/\D/g, "");
                      const newFields = [...locationFields];
                      newFields[index].BEpainted = input;

                      setLocationFields(newFields);
                    }}
                  />
                </Grid>
              )}

              {activityType === "Feeding Program" && (
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Meals Served"
                    value={location.FPmeals}
                    required
                    onChange={(e) => {
                      const input = e.target.value.replace(/\D/g, "");
                      const newFields = [...locationFields];
                      newFields[index].FPmeals = input;

                      setLocationFields(newFields);
                    }}
                  />
                </Grid>
              )}

              {activityType === "Christmas Activity" && (
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Number of Gifts Distributed"
                    value={location.CAGifts}
                    required
                    onChange={(e) => {
                      const input = e.target.value.replace(/\D/g, "");
                      const newFields = [...locationFields];
                      newFields[index].CAGifts = input;

                      setLocationFields(newFields);
                    }}
                  />
                </Grid>
              )}

              {activityType === "Tree Planting" && (
                <>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label="Number of Trees planted"
                      value={location.TPplanted}
                      required
                      onChange={(e) => {
                        const input = e.target.value.replace(/\D/g, "");
                        const newFields = [...locationFields];
                        newFields[index].TPplanted = input;

                        setLocationFields(newFields);
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label="Hectares protected"
                      value={location.TPhectares}
                      required
                      onChange={(e) => {
                        const input = e.target.value.replace(/\D/g, "");
                        const newFields = [...locationFields];
                        newFields[index].TPhectares = input;

                        setLocationFields(newFields);
                      }}
                    />
                  </Grid>
                </>
              )}
             <Grid item xs={4}>
                <Autocomplete
                  options={PartnerMapping}
                  freeSolo
                  value=""
                  inputValue={inputValue}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      const newFields = [...locationFields];
                      const isDuplicate =
                        newFields[index].partners.includes(newValue);
                      if (!isDuplicate) {
                        const updatedPartners = [
                          ...newFields[index].partners,
                          newValue,
                        ];
                        newFields[index].partners = updatedPartners;
                        setLocationFields(newFields);
                        setInputValue(""); // Clear the input value after successfully adding the chip
                      }
                    }
                  }}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue); // Update input value as user types
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Partner Organizations" />
                  )}
                />
              </Grid>
              <Grid item xs={12} container alignItems="center">
                {location.partners.map((partner, partnerIndex) => (
                  <Chip
                    key={partnerIndex}
                    label={partner}
                    onDelete={() => {
                      const newFields = [...locationFields];
                      const updatedPartners = newFields[index].partners.filter(
                        (p, i) => i !== partnerIndex,
                      );
                      newFields[index].partners = updatedPartners;
                      setLocationFields(newFields);
                    }}
                    style={{ margin: "4px", fontSize: "16px" }}
                    variant="outlined"
                    color="success"
                  />
                ))}
              </Grid>

              <Grid container spacing={2} pt="10px" marginLeft="1px">
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Remarks"
                    value={location.remarks}
                    onChange={(e) => {
                      const newFields = [...locationFields];
                      newFields[index].remarks = e.target.value;
                      setLocationFields(newFields);
                    }}
                  />
                </Grid>
              </Grid>

              {/* -----------END OF SITUATIONAL FIELDS------------------------------------------------------------------------------------------------------*/}
              <Grid item xs={0.5} display="flex" mb="10px" ml="1px">
                {index === 0 && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddLocation}
                  >
                    <AddIcon style={{ fontWeight: "bold" }} />
                  </Button>
                )}

                {index !== 0 && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleRemoveLocation(index)}
                  >
                    <RemoveIcon style={{ fontWeight: "bold" }} />
                  </Button>
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
        {/* Remaining fields */}

        <Grid container spacing={2} pb="10px" justifyContent="center">
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleCancel}
              sx={{ marginLeft: "10px" }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Volform;
