import { Box, useTheme, IconButton } from "@mui/material";

import { tokens } from "../../theme";
import Header from "../../components/Header";

import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

const Financial = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  <Box></Box>;

  const columns = [
    { field: "id", headerName: "ID", flex: 0.18 },
    { field: "sdgno", headerName: "SDG Number", flex: 0.3 },
    {
      field: "sdgfield",
      headerName: "SDG Field",
      flex: 0.5,
      cellClassName: "name-column--cell",
    },
    {
      field: "projectname",
      headerName: "Project Name",
      flex: 0.8,
    },
    {
      field: "year",
      headerName: "Year",
      flex: 0.5,
    },
    {
      field: "partnername",
      headerName: "Partner Institution",
      flex: 0.5,
    },
    {
      field: "beneficiaries",
      headerName: "Beneficiaries",
      flex: 0.5,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      headerAlign: "center",
      cellClassName: "actionbuttons",
      renderCell: () => (
        <div>
          <IconButton>
            <VisibilityOutlinedIcon />
          </IconButton>

          <IconButton>
            <DeleteOutlinedIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const tableStyles = {
    "& .MuiDataGrid-root": {
      border: "none",
    },
    "& .MuiDataGrid-cell": {
      borderBottom: "none",
    },
    "& .name-column--cell": {
      color: colors.greenAccent[300],
    },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: colors.primary[900],
      borderBottom: "none",
      borderRadius: "15px 15px 0px 0px",
    },
    "& .MuiDataGrid-virtualScroller": {
      backgroundColor: colors.primary[400],
    },
    "& .MuiDataGrid-footerContainer": {
      borderTop: "none",
      backgroundColor: colors.primary[400],
      borderRadius: "0px 0px 15px 15px",
    },
    "& .MuiCheckbox-root": {
      color: `${colors.greenAccent[200]} !important`,
    },
    "& .actionbuttons": {
      justifyContent: "center",
      alignItems: "center",
      padding: " 10px",
    },
  };

  return (
    <Box margin="20px">
      <Header title="Financial Statements" />
      <Box margin="10px 0 0 0" height="80vh" sx={tableStyles}></Box>
    </Box>
  );
};

export default Financial;
