import React, { useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Typography,
  Grid,
  Modal,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {  doc ,deleteDoc,updateDoc,getDoc} from 'firebase/firestore';    
import { firestoredb } from '../../../firebaseConfig'; // Replace with your Firebase configuration file
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import { useParams } from 'react-router-dom';
import { getCompanyOptions } from '../../../Data/companyOptions';


const Schoform = () => {
    const navigate = useNavigate();
    const { documentId } = useParams();
    const [year, setYear] = useState('');
    const [scholars, setScholars] = useState({
      graduated: '',
      disqualified: '',
    });
    const [annualTargetScholar, setAnnualTargetScholar] = useState('');
    const [graduatedWithHonor, setGraduatedWithHonor] = useState('');
    const [scholarshipGrants, setScholarshipGrants]= useState('');
    const [partnerSchools, setPartnerSchools]= useState('');  
    const [graduateWhoAreBoardPasser, setGraduateWhoAreBoardPassers]= useState('');  
    const [user, setUser] = useState('');
    const [scholarsFTY, setScholarsFTY]= useState('');  
    const [remarks, setRemarks]= useState('');  
    const [openModal, setOpenModal] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false); // State to track form submission
    const [graduatedWithHonorError, setGraduatedWithHonorError] = useState(false);
    const [graduateError, setGraduateError] = useState(false);
    const [grantsError, setGrantsError] = useState(false);
    const [companyFields, setCompanyFields] = useState([
      { company: '', 
        companyHired: '' },
    ]);
    
    const checkGraduates = () => {
      if (parseInt(graduatedWithHonor) > parseInt(scholars.graduated)) {
          setGraduatedWithHonorError(true);
          return false;
        }
        setGraduatedWithHonorError(false);
        return true;
    };

    const checkGrads = () => {
      if (parseInt(scholarsFTY) < parseInt(scholars.graduated)) {
        setGraduateError(true);
          return false;
        }
        setGraduateError(false);
        return true;
    };

    const checkGrants = () => {
      if (parseInt(scholarshipGrants) < parseInt(scholarsFTY)) {
        setGrantsError(true);
          return false;
        }
        setGrantsError(false);
        return true;
      };

    const checkGraduatesAndGrants = () => {
      const graduatesValid = checkGraduates();
      const grantsValid = checkGrants();
      const gradsValid = checkGrads();
      
      return graduatesValid && grantsValid && gradsValid;
    };

    const generateYearOptions = () => {
        const currentYear = new Date().getFullYear(); // Get the current year
        const startYear = 1997; // Start year
        const yearOptions = [];
        
        // Loop from the start year to the current year
        for (let i = currentYear; i >= startYear; i--) {
          yearOptions.push(i.toString()); // Push each year as a string to the options array
        } 
        
        return yearOptions; // Return the array of year options
      };  

      const handleInputChange = (e) => {
        const { name, value } = e.target;
        const numericValue = value.replace(/\D/g, ''); // Remove non-numeric characters
        setScholars((prevState) => ({
          ...prevState,
          [name]: numericValue,
        }));
      };

      
      const handleCancel = () => {
        // Use history.goBack() to navigate back to the previous screen
        navigate('/scholarship');
      };

      const handleAddCompany = () => {
        setCompanyFields([
          ...companyFields,
          {
            company: '',
            companyHired: '',
          },
        ]);
      };

      const handleRemoveCompany = (index) => {
        const newFields = [...companyFields];
        newFields.splice(index, 1);
        setCompanyFields(newFields);
      };
    
      
      const noScholar = (parseInt(scholars.graduated) || 0) + (parseInt(scholars.disqualified) || 0);
      const totalHiredScholars = companyFields.reduce((total, field) => {
        return total + (parseInt(field.companyHired) || 0);
      }, 0);

      useEffect(() => {
        const fetchData = async () => {
          try {
            
            const scholarshipDocRef = doc(firestoredb, 'ScholarshipData', documentId);
            const scholarshipDocSnapshot = await getDoc(scholarshipDocRef);
      
            if (scholarshipDocSnapshot.exists()) {
              const data = scholarshipDocSnapshot.data();
              setYear(data.year || '');
              setScholarsFTY(data.scholarsFTY || '');
              setRemarks(data.remarks || '');
              setAnnualTargetScholar(data.annualTargetScholar || '');
              setGraduatedWithHonor(data.graduatedWithHonor || '');
              setScholarshipGrants(data.scholarshipGrants || '');
              setPartnerSchools(data.partnerSchools || '');
              setGraduateWhoAreBoardPassers(data.graduateWhoAreBoardPasser || '');
              setCompanyFields(data.companyFields || []);
              setScholars({
                graduated: data.scholars?.graduated || '',
                disqualified: data.scholars?.disqualified || '',
              });
              console.log('Data fetched successfully:', data);
            } else {
              console.warn(`Document with ID ${documentId} does not exist.`);
            }
          } catch (error) {
            console.error('Error fetching document:', error);
          } 
        };
      
        fetchData();
      }, [documentId]);

      useEffect(() => {
        const fetchUser = async () => {
          try {
            // Get the userId from sessionStorage
            const userId = sessionStorage.getItem('userId');
            if (!userId) {
              console.log("User ID not found in sessionStorage");
              return;
            }
    
            // Get the user document from Firestore
            const userDocRef = doc(firestoredb, 'users', userId);
            const userDocSnapshot = await getDoc(userDocRef);
            if (userDocSnapshot.exists()) {
              // Extract the user data from the user document
              const userData = userDocSnapshot.data();
              setUser(userData);
            } else {
              console.log("User not found");
            }
          } catch (error) {
            console.error("Error fetching user data:", error.message);
          }
        };
    
        fetchUser();
      }, []);

        const handleUpdate = async (documentId, updateData) => {
          setIsSubmitted(true); // Set form submission flag

          if (!checkGraduatesAndGrants()) {
            return;
          }
        
          try {
            const docRef = doc(firestoredb, 'ScholarshipData', documentId);
            await updateDoc(docRef, updateData);
            console.log('Document updated successfully.');
            localStorage.setItem('alertSeverity', 'success');
            localStorage.setItem('alertMessage', 'Activity updated successfully.');
            localStorage.setItem('alertopen', 'true');
            
          } catch (error) {
            console.error('Error updating document:', error);
            localStorage.setItem('alertSeverity', 'error');
          localStorage.setItem('alertMessage', 'Updating activity failed.');
          localStorage.setItem('alertopen', 'false');
          }
          navigate('/scholarship');
        };

        
        const handleButtonClick = () => {
          setOpenModal(true);
        };

        const handleCloseModal = () => {
          setOpenModal(false);
        };

        const handleDelete = async () => {
          try {
            const scholarshipDocRef = doc(firestoredb, 'ScholarshipData', documentId);
            await deleteDoc(scholarshipDocRef);
            handleCloseModal();
            navigate('/scholarship');
          } catch (error) {
            console.error('Error deleting document:', error);
          }
        };

        return (
          <Box backgroundColor="white" padding="10px" margin="10px" borderRadius="15px">
            <form onSubmit={(e) => {
            e.preventDefault(); // Prevent default form submission behavior

            const updateData = {  // Create an object containing updated data
              year,
              annualTargetScholar,
              graduatedWithHonor,
              scholarsFTY,
              remarks,
              scholarshipGrants,
              noScholar,
              partnerSchools,
              graduateWhoAreBoardPasser,
              companyFields,
              scholars,
              totalHiredScholars,
            };
            handleUpdate(documentId, updateData); // Call handleUpdate with documentId and updateData
          }}>
            <Typography variant="h4" fontWeight="bold" margin="5px"pb='20px'>
                SCHOLARSHIP YEARLY DATA
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                  <FormControl fullWidth required>
                    <InputLabel>Year</InputLabel>
                    <Select 
                    label="Year"  
                    value = {year}
                    MenuProps={{
                      MenuListProps: {
                        style: {
                          maxHeight: 200, // Set the maximum height of the dropdown
                        },
                      },
                    }}
                    onChange ={(e) => setYear(e.target.value) }>
                      {generateYearOptions().map((option)=> (
                        <MenuItem key={option} value ={option}>  {option}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                      <TextField
                        fullWidth
                        label="Scholarship Grants"
                        required
                        name="Scholarship Grants"
                        value={scholarshipGrants}
                        onChange={(e) =>{
                          const input = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                          setScholarshipGrants(input);
                          setGrantsError(false); // Reset error state on change
                        }}
                      />
                      {isSubmitted && grantsError && (
                          <Typography variant="body2" color="error">
                            Scholarship grants should be greater than the scholars for the year
                          </Typography>
                        )}
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        label="Annual Number of Target Scholar"
                        required
                        name="Annual Number of Target Scholar"
                        value={annualTargetScholar}
                        onChange={(e) =>{
                          const input = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                          setAnnualTargetScholar(input);
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        label="Scholars for the year"
                        required
                        name="Scholars FTY"
                        value={scholarsFTY}
                        onChange={(e) =>{
                          const input = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                          setScholarsFTY(input);
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            value={noScholar}
                            fullWidth
                            label="No. Scholar"
                            required
                          />
                      </Grid>

                      <Grid item xs={3}>
                      <TextField
                        fullWidth
                        label="Graduated Scholars"
                        required
                        name="graduated"
                        value={scholars.graduated}
                        onChange={(e) => {
                          const input = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                          setScholars(prevState => ({
                            ...prevState,
                            graduated: input,
                          }));
                          setGraduateError(false); // Reset error state on change
                        }}
                      />
                        {isSubmitted && graduateError && (
                          <Typography variant="body2" color="error">
                            Graduated scholars should be less than the scholars for the year
                          </Typography>
                        )}
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        label="Disqualified Scholars"
                        required
                        name="disqualified"
                        value={scholars.disqualified}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        label="Graduated with Honors"
                        required
                        name="Graduated with Honors"
                        value={graduatedWithHonor}
                         onChange={(e) =>{
                          const input = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                          setGraduatedWithHonor(input);
                          setGraduatedWithHonorError(false); // Reset error state on change
                        }}
                      />
                        {isSubmitted && graduatedWithHonorError && (
                          <Typography variant="body2" color="error">
                            Graduated with honors should be less than the scholars for the year
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          fullWidth
                          label="Graduates who are Board Passers"
                          required
                          name="Graduates who are Board Passers"
                          value={graduateWhoAreBoardPasser}
                          onChange={(e) =>{
                            const input = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                            setGraduateWhoAreBoardPassers(input);
                          }}
                        />
                      </Grid>
                    
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        label="Partner Schools"
                        required
                        name="Partner Schools"
                        value={partnerSchools}
                        onChange={(e) =>{
                          const input = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                          setPartnerSchools(input);
                        }}
                      />
                    </Grid>
                      <Grid item xs={3}>
                        <TextField
                          fullWidth
                          label="Total Hired Scholars"
                          required
                          name="Total Hired Scholars"
                          value={totalHiredScholars}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid>
                      {companyFields.map((company, index) => (
                        <Grid container spacing={2} pt="10px" pb="5px" key={index}>
                          <Grid item xs={4.5}>
                          <FormControl fullWidth>
                            <InputLabel>Company</InputLabel>
                            <Select
                              label ="Company"
                              value={company.company}
                              required
                              MenuProps={{
                                MenuListProps: {
                                  style: {
                                    maxHeight: 200, // Set the maximum height of the dropdown
                                  },
                                },
                              }}
                              onChange={(e) => {
                                const newFields = [...companyFields];
                                newFields[index].company = e.target.value;
                                setCompanyFields(newFields);
                              }}>     
                             {getCompanyOptions()
                                  .filter(option => !companyFields.some(field => field.company === option && field !== company))
                                  .map(option => (
                                    <MenuItem key={option} value={option}>{option}</MenuItem>
                                  ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={4.5}>
                          <TextField
                            fullWidth
                            label="Number of Hired Scholars: "
                            value={company.companyHired}
                            required
                            onChange={(e) => {
                              const newFields = [...companyFields];
                              const numericValue = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                              newFields[index].companyHired = numericValue;
                              setCompanyFields(newFields);
                            }}
                          />   
                        </Grid>
                        <Grid item xs={0.5} display='flex' mb ="10px" ml="1px">
                    
                          {index === 0 && (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleAddCompany}
                            >
                              <AddIcon style={{ fontWeight: 'bold' }} />
                            </Button>
                          )}
                          
                          {index !== 0 && (
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => handleRemoveCompany(index)}
                          >
                            <RemoveIcon style={{ fontWeight: 'bold' }} />
                          </Button>
                        )}    
                          </Grid>
                          
                        </Grid>
                      ))}
                      <Grid item xs={0.5} display='flex' mb ="10px" ml="1px"></Grid>
                      <Grid item xs={12}>
                            <TextField
                              fullWidth
                              multiline
                              rows={4}
                              label="Remarks"
                              value={remarks}
                              onChange={(e) => setRemarks(e.target.value)}
                            />
                          </Grid>
                  </Grid>

                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: '10px' ,marginLeft:'3px', marginTop:'5px' }}>
                    <div>
                      {user && (user.role === 'Owner'|| user.role === 'Administrator') && (
                        <Button variant="contained"  color="primary" type="submit"  sx={{ marginRight: '10px' }}>
                          Update
                        </Button>
                          )}
                        <Button variant="outlined" color="primary" onClick={handleCancel}>
                          Go Back
                        </Button>
                      </div>
                      {user && user.role === 'Owner' && (
                      <Button variant="contained" 
                              sx={{ backgroundColor: '#eb584d',color: '#fff', '&:hover': { backgroundColor: '#cc180a' }, mr:'3px'}}
                              onClick={handleButtonClick}
                              >
                        <DeleteIcon />
                      </Button>
                      )}
                        <Modal
                            open={openModal}
                            onClose={handleCloseModal}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box
                              sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-40%, -60%)',
                                borderRadius: '5px',
                                width: 400,
                                bgcolor: 'background.paper',
                                boxShadow: 24,
                                p: 2,
                              }}
                            >
                              <Typography id="modal-modal-title" variant="h4" sx={{ fontWeight: '600' ,marginTop: '10px'}}>
                                Delete
                              </Typography>
                              <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                                Are you sure you want to delete this data? 
                              </Typography>
                              <div style={{ display: 'flex', justifyContent: 'right',marginTop: '5px' }}>
                              <Button onClick={handleCloseModal} sx={{ border: '1px solid #c2c2c2', textTransform: 'none',marginRight:'10px' }}>Cancel</Button>
                              <Button variant="contained" onClick={handleDelete} sx={{ textTransform: 'none', backgroundColor: '#eb584d', color: '#fff', '&:hover': { backgroundColor: '#cc180a' }}}>Yes, Delete</Button>
                              </div>
                              
                            </Box>
                          </Modal>
                    </div>

          </form>
          </Box>
        );

    

}
export default Schoform;