import React, { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Box, IconButton, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { getDoc, doc } from "firebase/firestore";
import { firestoredb } from "../../firebaseConfig";

import HomeIcon from "@mui/icons-material/Home";
import PeopleIcon from "@mui/icons-material/People";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import MenuIcon from "@mui/icons-material/Menu";
import HandshakeIcon from "@mui/icons-material/Handshake";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import SchoolIcon from "@mui/icons-material/School";
import CelebrationIcon from "@mui/icons-material/Celebration";
import PaymentsIcon from "@mui/icons-material/Payments";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const handleClick = () => {
    setSelected(title); // Set the selected item when clicked
  };

  return (
    <MenuItem
      active={selected === title} // Check if the current item is selected
      onClick={handleClick} // Handle click event
      icon={icon}
    >
      <Link to={to}>{title}</Link>
    </MenuItem>
  );
};

const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const [user, setUser] = useState("");

  useEffect(() => {
    const fetchUser = async () => {
      try {
        // Get the userId from sessionStorage
        const userId = sessionStorage.getItem("userId");
        if (!userId) {
          console.log("User ID not found in sessionStorage");
          return;
        }

        // Get the user document from Firestore
        const userDocRef = doc(firestoredb, "users", userId);
        const userDocSnapshot = await getDoc(userDocRef);
        if (userDocSnapshot.exists()) {
          // Extract the user data from the user document
          const userData = userDocSnapshot.data();
          setUser(userData);
        } else {
          console.log("User not found");
        }
      } catch (error) {
        console.error("Error fetching user data:", error.message);
      }
    };

    fetchUser();
  }, []);
  useEffect(() => {
    // Initialize isCollapsed state from session storage
    const storedIsCollapsed = sessionStorage.getItem("isCollapsed");
    if (storedIsCollapsed !== null) {
      setIsCollapsed(JSON.parse(storedIsCollapsed));
    }
  }, []);

  useEffect(() => {
    // Update session storage whenever isCollapsed changes
    sessionStorage.setItem("isCollapsed", JSON.stringify(isCollapsed));
  }, [isCollapsed]);

  return (
    <Box>
      <Box
        sx={{
          position: "fixed", // Fix the position of the sidebar
          top: 0, // Align the top of the sidebar with the top of the viewport
          left: 0, // Align the left of the sidebar with the left of the viewport
          bottom: 0, // Align the bottom of the sidebar with the bottom of the viewport
          zIndex: 999, // Ensure the sidebar stays above other content
          fontWeight: "900",
          "& .pro-sidebar-inner": {
            background: "#E1E6DE !important",
          },
          "& .pro-inner-item ": {
            color: "#174A55 !important",
            padding: "5px 15px 5px 20px !important",
          },

          "& .pro-item-content a": {
            color: "#174A55 !important",
          },
          "& .pro-inner-item:hover , & .pro-item-content a:hover,& .pro-menu-item:active , & .pro-item-content a:active, & .pro-icon:focus , & .pro-item-content a:focus":
            {
              color: "#58827E !important",
            },
          "& .pro-arrow-wrapper": {
            display: "none",
          },
        }}
      >
        <ProSidebar collapsed={isCollapsed}>
          <Menu>
            {/* LOGO BOX */}
            <MenuItem
              onClick={() => setIsCollapsed(!isCollapsed)}
              icon={isCollapsed ? <MenuIcon /> : undefined}
              style={{
                padding: "0px 3px 0px 3px",
                color: "#fff",
                boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.1)",
              }}
            >
              {/* LOGO */}
              {!isCollapsed && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  ml="5px"
                >
                  <img
                    src={process.env.PUBLIC_URL + "/Megaworld.png"}
                    alt="Company Logo"
                    style={{
                      width: "100%",
                      height: "auto",
                      cursor: "pointer",
                      maxWidth: "200px",
                    }}
                  />
                  <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                    <MenuIcon />
                  </IconButton>
                </Box>
              )}
            </MenuItem>
            {/* ITEMS */}
            <Box paddingLeft="40px" paddingTop="28px">
              {!isCollapsed && (
                <>
                  <Typography color="#174A55" fontWeight="900" fontSize="16px">
                    WELCOME TO
                  </Typography>
                  <Typography color="#174A55" fontWeight="900" fontSize="16px">
                    MEGAWORLD FOUNDATION
                  </Typography>
                  <Typography color="#174A55" fontWeight="900" fontSize="16px">
                    DATA BANKING SYSTEM!
                  </Typography>
                </>
              )}
            </Box>
            <Box paddingLeft={isCollapsed ? undefined : "5%"} paddingTop="80px">
              <Item
                title="Dashboard"
                to="/dashboard"
                icon={<HomeIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Scholarship"
                to="/scholarship"
                icon={<SchoolIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Volunteerism"
                to="/volunteerism"
                icon={<VolunteerActivismIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <SubMenu
                title="Partnership Building"
                icon={<HandshakeIcon />}
                className="custom-submenu"
              >
                <Item
                  title="Activity"
                  to="/partnershipActivity"
                  icon={<CelebrationIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Donation"
                  to="/partnershipDonation"
                  icon={<PaymentsIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Livelihood"
                  to="/partnershipLivelihood"
                  icon={<TipsAndUpdatesIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </SubMenu>
              <Item
                title="Financial Statements"
                to="/financial"
                icon={<LeaderboardIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              {user && user.role === "Owner" && (
                <Item
                  title="Manage Users"
                  to="/users"
                  icon={<PeopleIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              )}
            </Box>
          </Menu>
        </ProSidebar>
      </Box>
      <Box
        sx={{
          width: isCollapsed ? "80px" : "270px", // Set the width based on the collapsed state
          transition: "width 0.3s ease-in-out", // Add transition for smoother animation
        }}
      ></Box>
    </Box>
  );
};

export default Sidebar;
