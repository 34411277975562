import React, { useState } from 'react';
import {
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Button,
    Typography,
    Grid,
  } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { collection, addDoc , serverTimestamp } from 'firebase/firestore';    
import { firestoredb } from '../../../firebaseConfig'; // Replace with your Firebase configuration file
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { getCompanyOptions } from '../../../Data/companyOptions';


const Schoform = () => {
    const [year, setYear] = useState('');
    const [annualTargetScholar, setAnnualTargetScholar] = useState('');
    const [graduatedWithHonor, setGraduatedWithHonor] = useState('');
    const [scholarshipGrants, setScholarshipGrants]= useState('');
    const [partnerSchools, setPartnerSchools]= useState('');  
    const [graduateWhoAreBoardPasser, setGraduateWhoAreBoardPassers]= useState('');  
    const [scholarsFTY, setScholarsFTY]= useState('');  
    const [remarks, setRemarks]= useState('');    
    const navigate = useNavigate();
    const [isSubmitted, setIsSubmitted] = useState(false); // State to track form submission
    const [graduatedWithHonorError, setGraduatedWithHonorError] = useState(false);
    const [graduateError, setGraduateError] = useState(false);
    const [grantsError, setGrantsError] = useState(false);

    const [scholars, setScholars] = useState({
      graduated: '',
      disqualified: '',
    });

    const [companyFields, setCompanyFields] = useState([
      { company: '', 
        companyHired: '' },
    ]);
    

    const checkGraduates = () => {
      if (parseInt(graduatedWithHonor) > parseInt(scholars.graduated)) {
          setGraduatedWithHonorError(true);
          return false;
        }
        setGraduatedWithHonorError(false);
        return true;
    };

    const checkGrads = () => {
      if (parseInt(scholarsFTY) < parseInt(scholars.graduated)) {
        setGraduateError(true);
          return false;
        }
        setGraduateError(false);
        return true;
    };

    const checkGrants = () => {
      if (parseInt(scholarshipGrants) < parseInt(scholarsFTY)) {
        setGrantsError(true);
          return false;
        }
        setGrantsError(false);
        return true;
      };

    const checkGraduatesAndGrants = () => {
      const graduatesValid = checkGraduates();
      const grantsValid = checkGrants();
      const gradsValid = checkGrads();
      
      return graduatesValid && grantsValid && gradsValid;
    };

    const generateYearOptions = () => {
        const currentYear = new Date().getFullYear(); // Get the current year
        const startYear = 1997; // Start year
        const yearOptions = [];
        
        // Loop from the start year to the current year
        for (let i = currentYear; i >= startYear; i--) {
          yearOptions.push(i.toString()); // Push each year as a string to the options array
        } 
        
        return yearOptions; // Return the array of year options
      };

    
      const handleCancel = () => {
        navigate('/scholarship');
      };

      
      const handleSubmit = async (e) => {
        e.preventDefault();
        
        setIsSubmitted(true); // Set form submission flag

          if (!checkGraduatesAndGrants()) {
            return;
          }
        
        try {
          const docRef = await addDoc(collection(firestoredb, 'ScholarshipData'), {
            year,
            scholars,
            scholarsFTY,
            remarks,
            noScholar,
            annualTargetScholar,
            graduatedWithHonor,
            graduateWhoAreBoardPasser,
            scholarshipGrants,
            partnerSchools,
            totalHiredScholars,
            companyFields,
            timestamp: serverTimestamp(),
          });
          console.log('Document written with ID: ', docRef.id);
          setYear('');
          setAnnualTargetScholar('');
          setGraduatedWithHonor('');
          setGraduateWhoAreBoardPassers('');
          setScholarshipGrants('');
          setScholarsFTY('');
          setPartnerSchools('');
          setRemarks('');
          setCompanyFields([...companyFields, 
            {
              company:'',
              companyHired:'',
            }]);
          
          localStorage.setItem('alertSeverity', 'success');
          localStorage.setItem('alertMessage', 'Activity added successfully.');
          localStorage.setItem('alertopen', 'true');
        } catch (error) {
          console.error('Error adding document: ', error);
          localStorage.setItem('alertSeverity', 'error');
          localStorage.setItem('alertMessage', 'Adding activity failed.');
          localStorage.setItem('alertopen', 'false');
        }
        navigate('/scholarship');
        
      };

      const handleAddCompany = () => {
        setCompanyFields([
          ...companyFields,
          {
            company: '',
            companyHired: '',
          },
        ]);
      };

      const handleInputChange = (e) => {
        const { name, value } = e.target;
        const numericValue = value.replace(/\D/g, ''); // Remove non-numeric characters
        setScholars((prevState) => ({
          ...prevState,
          [name]: numericValue,
        }));
      };


      const handleRemoveCompany = (index) => {
        const newFields = [...companyFields];
        newFields.splice(index, 1);
        setCompanyFields(newFields);
      };
    
      const noScholar = (parseInt(scholars.graduated) || 0) + (parseInt(scholars.disqualified) || 0);
      const totalHiredScholars = companyFields.reduce((total, field) => {
        return total + (parseInt(field.companyHired) || 0);
      }, 0);

      return (
        <Box backgroundColor="white" padding="10px" margin="10px" borderRadius="15px">
            <form onSubmit={handleSubmit}>
              <Typography variant="h4" fontWeight="bold" margin="5px"pb='20px'>
                SCHOLARSHIP YEARLY DATA
              </Typography>

              <Grid container spacing={2}>
      
                <Grid item xs={3}>
                  <FormControl fullWidth required>
                    <InputLabel>Year</InputLabel>
                    <Select 
                    label="Year"  
                    value = {year}
                    MenuProps={{
                      MenuListProps: {
                        style: {
                          maxHeight: 200, // Set the maximum height of the dropdown
                        },
                      },
                    }}
                    onChange ={(e) => setYear(e.target.value) }>
                      {generateYearOptions().map((option)=> (
                        <MenuItem key={option} value ={option}>  {option}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        label="Scholarship Grants"
                        required
                        name="Scholarship Grants"
                        value={scholarshipGrants}
                        onChange={(e) =>{
                          const input = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                          setScholarshipGrants(input);
                          setGrantsError(false); // Reset error state on change
                        }}
                      />
                      {isSubmitted && grantsError && (
                          <Typography variant="body2" color="error">
                            Scholarship grants should be greater than the scholars for the year.
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        label="Annual Number of Target Scholar"
                        required
                        name="Annual Number of Target Scholar"
                        value={annualTargetScholar}
                        onChange={(e) =>{
                          const input = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                          setAnnualTargetScholar(input);
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        label="Scholars for the year"
                        required
                        name="Scholars FTY"
                        value={scholarsFTY}
                        onChange={(e) =>{
                          const input = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                          setScholarsFTY(input);
                        }}
                      />
                      <Typography variant="body2" color="#5A5A5A">
                           *Based on December plantilia 
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            value={noScholar}
                            fullWidth
                            label="No. Scholars"
                            required
                          />
                      </Grid>

                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        label="Graduated Scholars"
                        required
                        name="graduated"
                        value={scholars.graduated}
                        onChange={(e) => {
                          const input = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                          setScholars(prevState => ({
                            ...prevState,
                            graduated: input,
                          }));
                          setGraduateError(false); // Reset error state on change
                        }}
                      />
                        {isSubmitted && graduateError && (
                          <Typography variant="body2" color="error">
                            Graduated scholars should be less than the scholars for the year.
                          </Typography>
                        )}
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        label="Disqualified Scholars"
                        required
                        name="disqualified"
                        value={scholars.disqualified}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        label="Graduated with Honors"
                        required
                        name="Graduated with Honors"
                        value={graduatedWithHonor}
                         onChange={(e) =>{
                          const input = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                          setGraduatedWithHonor(input);
                          setGraduatedWithHonorError(false); // Reset error state on change
                        }}
                      />
                        {isSubmitted && graduatedWithHonorError && (
                          <Typography variant="body2" color="error">
                            Graduated with honors should be less than the graduated scholars
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          fullWidth
                          label="Graduates who are Board Passers"
                          required
                          name="Graduates who are Board Passers"
                          value={graduateWhoAreBoardPasser}
                          onChange={(e) =>{
                            const input = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                            setGraduateWhoAreBoardPassers(input);
                          }}
                        />
                      </Grid>
                    
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        label="Partner Schools"
                        required
                        name="Partner Schools"
                        value={partnerSchools}
                        onChange={(e) =>{
                          const input = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                          setPartnerSchools(input);
                        }}
                      />
                    </Grid>
                      <Grid item xs={3}>
                        <TextField
                          fullWidth
                          label="Total Hired Scholars"
                          required
                          name="Total Hired Scholars"
                          value={totalHiredScholars}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid>
                        {companyFields.map((company, index) => (
                          <Grid container spacing={2} pt="10px" pb="5px" key={index}>
                            <Grid item xs={4.5}>
                            <FormControl fullWidth>
                              <InputLabel>Company</InputLabel>
                              <Select
                                label ="Company"
                                value={company.company}
                                required
                                MenuProps={{
                                  MenuListProps: {
                                    style: {
                                      maxHeight: 200, // Set the maximum height of the dropdown
                                    },
                                  },
                                }}
                                onChange={(e) => {
                                  const newFields = [...companyFields];
                                  newFields[index].company = e.target.value;
                                  setCompanyFields(newFields);
                                }}>     
                                  {getCompanyOptions()
                                    .filter(option => !companyFields.some(field => field.company === option && field !== company))
                                    .map(option => (
                                      <MenuItem key={option} value={option}>{option}</MenuItem>
                                    ))}
                              </Select>
                            </FormControl>
                          </Grid>
                            <Grid item xs={4.5}>
                              <TextField
                                fullWidth
                                label="Number of Hired Scholars: "
                                value={company.companyHired}
                                required
                                onChange={(e) => {
                                  const newFields = [...companyFields];
                                  const numericValue = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                                  newFields[index].companyHired = numericValue;
                                  setCompanyFields(newFields);
                                }}
                              />   
                            </Grid>
                          <Grid item xs={0.5} display='flex' mb ="10px" ml="1px">
                            {index === 0 && (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAddCompany}
                              >
                                <AddIcon style={{ fontWeight: 'bold' }} />
                              </Button>
                            )}
                            
                            {index !== 0 && (
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => handleRemoveCompany(index)}
                              >
                                <RemoveIcon style={{ fontWeight: 'bold' }} />
                              </Button>
                            )}    
                            </Grid>
                          </Grid>
                        ))} 
                          <Grid item xs={12} marginTop="5px">
                            <TextField
                              fullWidth
                              multiline
                              rows={4}
                              label="Remarks"
                              value={remarks}
                              onChange={(e) => setRemarks(e.target.value)}
                            />
                          </Grid>
                  </Grid>
                  <Box marginTop="20px">
                    <Button variant="contained" color="primary" type="submit">
                        Submit
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={handleCancel} style={{ marginLeft: '10px', color: '#000000', borderColor: '#000000' }}>
                        Cancel
                    </Button>
                  </Box>
              </form>
             
          </Box>
        );
          
  };



  export default Schoform;