import React, { useEffect, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { useNavigate } from "react-router-dom";
import { firestoredb } from "../../firebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import UserProfile from "../../Users/UserProfile";

const Topbar = ({ setIsAuthenticated }) => {
  const navigate = useNavigate();
  const [displayName, setDisplayName] = useState("");
  const [firstName, setfirstName] = useState("");

  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);

  useEffect(() => {
    const fetchUserDisplayName = async () => {
      // Retrieve the user's UID from session storage
      const userId = sessionStorage.getItem("userId");
      if (userId) {
        try {
          // Get the user document from Firestore
          const userDocRef = doc(firestoredb, "users", userId);
          const userDocSnapshot = await getDoc(userDocRef);
          if (userDocSnapshot.exists()) {
            // Extract the display name from the user document
            const userData = userDocSnapshot.data();
            setDisplayName(userData.displayName);
            setfirstName(userData.firstName);
          }
        } catch (error) {
          console.error("Error fetching user data:", error.message);
        }
      }
    };

    fetchUserDisplayName();
  }, []);

  const handleProfile = () => {
    // Open the profile modal
    setIsProfileModalOpen(true);
  };

  const handleCloseProfileModal = () => {
    // Close the profile modal
    setIsProfileModalOpen(false);
  };
  const handleLogout = () => {
    // Clear authentication status from session storage
    sessionStorage.removeItem("isAuthenticated");
    sessionStorage.removeItem("userId");

    // Update authentication state to false
    setIsAuthenticated(false);
    document.cookie = "userId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    // Navigate to the login page
    navigate("/");
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      backgroundColor="white"
      p={"9px"}
    >
      {/* SEARCH BAR */}
      <Box></Box>

      {/* ICONS */}
      <Box display="flex">
        <Typography
          fontSize="18px"
          fontWeight="600"
          marginTop="5px"
          marginRight="5px"
          color="#174A55"
        >
          Hi, {firstName}!
        </Typography>
        <IconButton onClick={handleProfile}>
          <PersonOutlinedIcon />
        </IconButton>

        <IconButton onClick={handleLogout}>
          <LogoutOutlinedIcon />
        </IconButton>
      </Box>
      <UserProfile
        open={isProfileModalOpen}
        handleClose={handleCloseProfileModal}
      />
    </Box>
  );
};

export default Topbar;
