import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "../../components/Header";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, getDoc, doc } from "firebase/firestore";
import { firestoredb } from "../../firebaseConfig";
import CustomAlert from "../../components/alert";

const PartnershipActivity = () => {
  const navigate = useNavigate();
  const [yearfrom, setYearfrom] = useState("");
  const [yearto, setYearto] = useState("");
  const [activityType, setactivityType] = useState("");
  const [sdg, setsdg] = useState("");
  const [user, setUser] = useState("");
  const [alertOpen, setAlertOpen] = useState(false); // State for alert visibility
  const [alertMessage, setAlertMessage] = useState(""); // State for alert message
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [PartnershipData, setPartnershipData] = useState([]);

  useEffect(() => {
    const severity = localStorage.getItem("alertSeverity");
    const message = localStorage.getItem("alertMessage");
    const open = localStorage.getItem("alertopen") === "true";

    if (open) {
      setAlertSeverity(severity);
      setAlertMessage(message);
      setAlertOpen(open);

      // Clear localStorage items
      localStorage.removeItem("alertSeverity");
      localStorage.removeItem("alertMessage");
      localStorage.removeItem("alertopen");
    }
  }, []);

  useEffect(() => {
    const fetchPartnershipData = async () => {
      try {
        const PartnershipCollection = collection(
          firestoredb,
          "PartnershipActivities",
        );
        const PartnershipSnapshot = await getDocs(PartnershipCollection);

        // Define variables to store filtered and sorted data
        const filteredData = PartnershipSnapshot.docs.map((doc) => ({
          timestamp: parseInt(doc.id),
          id: doc.id,
          ...doc.data(),
        }));

        // Filter data based on selected criteria
        const filteredAndSortedData = filteredData.filter(
          (item) =>
            (!yearfrom || yearfrom === "" || item.year >= yearfrom) &&
            (!yearto || item.year <= yearto) &&
            (!activityType || item.activityType === activityType) &&
            (!sdg || item.sdgName === sdg),
        );

        // Sort data based on timestamp in descending order
        filteredAndSortedData.sort((a, b) => b.timestamp - a.timestamp);

        // Set the filtered and sorted data to the state variable
        setPartnershipData(filteredAndSortedData);
      } catch (error) {
        console.error("Error fetching Partnership Activities:", error);
      }
    };

    // Call the function to fetch, filter, and sort data
    fetchPartnershipData();
  }, [yearfrom, yearto, activityType, sdg]); // Update data when filter options change

  useEffect(() => {
    const fetchUser = async () => {
      try {
        // Get the userId from sessionStorage
        const userId = sessionStorage.getItem("userId");
        if (!userId) {
          console.log("User ID not found in sessionStorage");
          return;
        }

        // Get the user document from Firestore
        const userDocRef = doc(firestoredb, "users", userId);
        const userDocSnapshot = await getDoc(userDocRef);
        if (userDocSnapshot.exists()) {
          // Extract the user data from the user document
          const userData = userDocSnapshot.data();
          setUser(userData);
        } else {
          console.log("User not found");
        }
      } catch (error) {
        console.error("Error fetching user data:", error.message);
      }
    };

    fetchUser();
  }, []);

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear(); // Get the current year

    const startYear = 1997; // Start year
    const yearOptions = [];

    // Loop from the start year to the current year
    for (let i = currentYear; i >= startYear; i--) {
      yearOptions.push(i.toString()); // Push each year as a string to the options array
    }

    return yearOptions; // Return the array of year options
  };

  const handleYearFromChange = (e) => {
    const selectedYearFrom = e.target.value;

    // Set yearfrom based on the selected value
    setYearfrom(selectedYearFrom);

    // If "Overall" is selected, clear both yearfrom and yearto
    if (selectedYearFrom === "") {
      setYearto(""); // Clear the value in yearto
    } else if (yearto && selectedYearFrom > yearto) {
      // If the selected value of "Year To" is smaller than "Year From", update "Year To" to match "Year From"
      setYearto(selectedYearFrom);
    }
  };

  // Event handler for "Year To" change
  const handleYearToChange = (e) => {
    // If the selected value of "Year To" is smaller than "Year From", prevent updating "Year To"
    if (yearfrom && e.target.value < yearfrom) {
      return;
    }
    setYearto(e.target.value);
  };

  function calculateTotals(locationFields = []) {
    let totalExpenses = 0;
    let totalBudget = 0;

    locationFields.forEach((location) => {
      totalExpenses += parseFloat(location.expenses) || 0;
      totalBudget += parseFloat(location.budget) || 0;
    });

    return { totalExpenses, totalBudget };
  }

  const handleShowonCLick = (params) => {
    const documentId = params.id;

    navigate(`/PartActivityShowForm/${documentId}`);
  };

  const columns = [
    { field: "year", headerName: "Year", flex: 0.12 },
    {
      field: "sdgName",
      headerName: "SDG",
      flex: 0.15,
      cellClassName: "forcenter1",
    },
    { field: "activityType", headerName: "Activity Type", flex: 0.25 },
    {
      field: "projectName",
      headerName: "Activity Name",
      flex: 0.4,
      cellClassName: "name-column--cell",
    },
    {
      field: "partners",
      headerName: "Partner Organization",
      flex: 0.4,
      valueGetter: (params) => {
        const locationFields = params.row.locationFields;
        if (locationFields) {
          const partners = locationFields.reduce((acc, location) => {
            if (location.partners && location.partners.length > 0) {
              acc.push(...location.partners);
            }
            return acc;
          }, []);
          if (partners.length === 1) {
            return partners[0]; // Return the partner's name if there's only one partner
          } else if (partners.length > 1) {
            return "Multiple Partners"; // Return "Multiple Partners" if there are multiple partners
          }
        }
        return "No Partner"; // Return "No Partner" if no partners are found
      },
    },

    { 
      field: "beneficiaries",
      headerName: "Beneficiaries",
      flex: 0.2,
      valueGetter: (params) => {
        const locationFields = params.row.locationFields;
        let totalBeneficiaries = 0;

        if (locationFields) {
          locationFields.forEach((location) => {
            if (location.beneficiaries) {
              totalBeneficiaries += parseInt(location.beneficiaries);
            }
          });
        }
        return totalBeneficiaries;
      },
    },

    {
      field: "budget",
      headerName: "Budget",
      flex: 0.2,
      valueGetter: (params) => {
        const locationFields = params.row.locationFields;
        if (!locationFields) return "P 0";

        let totalBudget = 0;
        locationFields.forEach((location) => {
          if (location.budget) {
            totalBudget += parseFloat(location.budget);
          }
        });
        return `P ${totalBudget.toLocaleString()}`;
      },
    },

    {
      field: "expenses",
      headerName: "Expenses",
      flex: 0.25,
      valueGetter: (params) => {
        const { totalExpenses } = calculateTotals(params.row.locationFields);
        return `P ${totalExpenses.toLocaleString()}`;
      },
      cellClassName: (params) => {
        const { totalExpenses, totalBudget } = calculateTotals(
          params.row.locationFields,
        );

        if (totalExpenses > totalBudget) {
          return "red-text";
        } else if (totalExpenses < totalBudget) {
          return "green-text";
        } else {
          return ""; // No class for equal expenses and budget
        }
      },
    },

    {
      field: "actions",
      headerName: "Actions",
      flex: 0.15,
      headerAlign: "center",
      cellClassName: "actionbuttons",
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton
            variant="contained"
            onClick={() => handleShowonCLick(params)}
          >
            <VisibilityOutlinedIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const tableStyles = {
    "& .MuiDataGrid-root": { border: "none", overflow: "auto" },
    "& .MuiDataGrid-cell": { borderBottom: "none", fontSize: "14px" },
    "& .name-column--cell": { color: "darkgreen" },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#e1e6de", // Set the background color of the column headers
      borderBottom: "none", // Remove the bottom border
      borderRadius: "15px 15px 0px 0px", // Apply rounded corners to the top
      fontSize: "18px", // Set the font size
    },
    "& .MuiDataGrid-virtualScroller": { backgroundColor: "#f6f6f6" },
    "& .MuiDataGrid-footerContainer": {
      display: "flex",
      justifyContent: "center", // Center horizontally
      alignItems: "center", // Center vertically
      borderTop: "none",
      backgroundColor: "#e1e6de",
      borderRadius: "0px 0px 15px 15px",
    },
    "& .MuiCheckbox-root": { color: `#e1e6de !important` },
    "& .actionbuttons": {
      display: "flex !important",
      alignItems: "center !important",
      justifyContent: "center !important",
    },
    "& .MuiDataGrid-cell:focus": {
      outline: "none !important", // Remove the outline when cell is focused (clicked)
      border: "none !important",
      boxShadow: "none !important", // Remove box shadow when cell is focused
    },
    "& .MuiDataGrid-row:nth-of-type(even)": {
      backgroundColor: "#f7f7f7", // Alternate color for even rows
    },
    "& .MuiDataGrid-row:nth-of-type(odd)": {
      backgroundColor: "#FFFFFF", // Default color for odd rows
    },
    "& .MuiDataGrid-row:hover": {
      backgroundColor: "#F0F0F0", // Hover color for both even and odd rows
    },
    "& .green-text": { color: "blue !important" }, //#55a53d
    "& .red-text": { color: "red !important" },
    "& .forcenter": { paddingLeft: "30px !important" },
    "& .forcenter1": { paddingLeft: "20px !important" },
  };

  const handleAddNewClick = () => {
    navigate("/PartActivityAddForm");
  };

  return (
    <Box margin="20px">
      <Box display="flex ">
        <Header title="Partnership Building - Activity" />
        {user && (user.role === "Owner" || user.role === "Administrator") && (
          <Button
            variant="contained"
            onClick={handleAddNewClick}
            sx={{
              backgroundColor: "#4B6856",
              color: "white",
              "&:hover": { backgroundColor: "#849F77" },
              display: "flex",
              flexDirection: "flex-end",
              marginLeft: "auto",
              mb: "10px",
            }}
          >
            <AddOutlinedIcon />
            Add New
          </Button>
        )}
      </Box>

      <Box
        marginTop="10px"
        marginBottom="30px"
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <FormControl
          style={{ width: "150px", height: "25px", marginRight: "15px" }}
        >
          <InputLabel>Activity Type:</InputLabel>
          <Select
            style={{ height: "35px" }}
            label="Activity Type:"
            value={activityType}
            MenuProps={{
              MenuListProps: {
                style: {
                  maxHeight: 150, // Set the maximum height of the dropdown
                },
              },
            }}
            onChange={(e) => setactivityType(e.target.value)}
          >
            <MenuItem value="">Overall</MenuItem>
            <MenuItem value="Brigada Eskwela"> Brigada Eskwela</MenuItem>
            <MenuItem value="Eskwela ng Bayan"> Eskwela ng Bayan</MenuItem>
            <MenuItem value="Feeding Program"> Feeding Program</MenuItem>
            <MenuItem value="Medical-Dental Mission">
              {" "}
              Medical-Dental Mission
            </MenuItem>
            <MenuItem value="Santa Scholars"> Santa Scholars</MenuItem>
            <MenuItem value="Tree Planting"> Tree Planting</MenuItem>
            <MenuItem value="Other Project"> Other Activity</MenuItem>
          </Select>
        </FormControl>

        <FormControl
          style={{ width: "150px", height: "25px", marginRight: "15px" }}
        >
          <InputLabel>SDG</InputLabel>
          <Select
            style={{ height: "35px" }}
            label="SDG:"
            value={sdg}
            MenuProps={{
              MenuListProps: {
                style: {
                  maxHeight: 150, // Set the maximum height of the dropdown
                },
              },
            }}
            onChange={(e) => setsdg(e.target.value)}
          >
            <MenuItem value="">Overall</MenuItem>
            <MenuItem value="1"> 1. No Poverty</MenuItem>
            <MenuItem value="2"> 2. Zero Hunger</MenuItem>
            <MenuItem value="3"> 3. Good Health and Well-being</MenuItem>
            <MenuItem value="4"> 4. Quality Education</MenuItem>
            <MenuItem value="5"> 5. Gender Equality</MenuItem>
            <MenuItem value="6"> 6. Clean Water and Sanitation</MenuItem>
            <MenuItem value="7"> 7. Affordable and Clean Energy</MenuItem>
            <MenuItem value="8"> 8. Decent Work and Economic Growth</MenuItem>
            <MenuItem value="9">
              {" "}
              9. Industry, Innovation and Infrastructure
            </MenuItem>
            <MenuItem value="10">10. Reduced Inequalities</MenuItem>
            <MenuItem value="11">
              11. Sustainable Cities and Communities
            </MenuItem>
            <MenuItem value="12">
              12. Responsible Consumption and Production
            </MenuItem>
            <MenuItem value="13">13. Climate Action</MenuItem>
            <MenuItem value="14">14. Life Below Water</MenuItem>
            <MenuItem value="15">15. Life on Land</MenuItem>
            <MenuItem value="16">
              16. Peace, Justice and Strong Institutions
            </MenuItem>
            <MenuItem value="17">17. Partnerships for the Goals</MenuItem>
          </Select>
        </FormControl>

        <FormControl style={{ width: "100px", height: "25px" }}>
          <InputLabel>Year From:</InputLabel>
          <Select
            style={{ height: "35px" }}
            label="Year From"
            value={yearfrom}
            MenuProps={{
              MenuListProps: {
                style: {
                  maxHeight: 150, // Set the maximum height of the dropdown
                },
              },
            }}
            onChange={handleYearFromChange}
          >
            <MenuItem value="">Overall</MenuItem>
            {generateYearOptions().map((option) => (
              <MenuItem key={option} value={option}>
                {" "}
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {yearfrom &&
          yearfrom !== new Date().getFullYear() &&
          yearfrom !== "" && (
            <FormControl
              style={{ marginLeft: "20px", width: "100px", height: "25px" }}
            >
              <InputLabel>Year to</InputLabel>
              <Select
                style={{ height: "35px" }}
                label="Year to"
                value={yearto}
                MenuProps={{
                  MenuListProps: {
                    style: {
                      maxHeight: 150, // Set the maximum height of the dropdown
                    },
                  },
                }}
                onChange={handleYearToChange}
              >
                {generateYearOptions().map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
      </Box>

      <Box margin="5px 0 0 0" height="78vh" width="100%" sx={tableStyles}>
        <DataGrid
          rows={PartnershipData}
          columns={columns.map((column) => ({
            ...column,
            flex: column.flex || 1, // Apply a default flex if not already set
          }))}
          cellClassName="data-grid-cell"
          autoPageSize
          pageSize={15}
          rowsPerPage={15}
          sx={{
            maxWidth: "1610px", // Ensure the grid does not exceed the container's width
            width: "100%",
          }}
        />
      </Box>

      <CustomAlert
        open={alertOpen}
        severity={alertSeverity}
        message={alertMessage}
      />
    </Box>
  );
};

export default PartnershipActivity;
