import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Typography,
  Grid,
  InputAdornment,
  Modal,
  Autocomplete,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { doc, deleteDoc, updateDoc, getDoc } from "firebase/firestore";
import { firestoredb } from "../../../firebaseConfig"; // Replace with your Firebase configuration file
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import { useParams } from "react-router-dom";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { townshipLocationsMapping } from "../../../Data/TownshipLocationsMapping";
import Chip from "@mui/material/Chip";
import { PartnerMapping } from "../../../Data/PartnerMapping";

const PartActform = () => {
  //Initialized Variables
  const { documentId } = useParams();
  const navigate = useNavigate();
  const [sdgName, setSdgName] = useState("");
  const [activityType, setActivityType] = useState("");
  const [projectName, setProjectName] = useState("");
  const [year, setYear] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [isFocused1, setIsFocused1] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [user, setUser] = useState("");
  const [locationFields, setLocationFields] = useState([
    {
      date: null,
      township: "",
      location: "",
      beneficiaries: "",
      packed: "",
      partners: [],
      typebene: "",
      mealsserved: "",
      budget: "",
      expenses: "",
      description: "",
    },
  ]);
  //Creates array for LocationFields

  //Initialization of Added Data
  const handleAddLocation = () => {
    setLocationFields([
      ...locationFields,
      {
        date: null, // Initialize with the current date or a default date.
        township: "",
        location: "",
        beneficiaries: "",
        packed: "",
        partners: [],
        typebene: "",
        mealsserved: "",
        budget: "",
        expenses: "",
        description: "",
      },
    ]);
  };
  //Set the year
  useEffect(() => {
    if (activityType === "Santa Scholars" && projectName === "") {
      setProjectName(activityType);
    }
  }, [activityType, projectName]);

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear(); // Get the current year
    const startYear = 1997; // Start year
    const yearOptions = [];

    // Loop from the start year to the current year
    for (let i = currentYear; i >= startYear; i--) {
      yearOptions.push(i.toString()); // Push each year as a string to the options array
    }

    return yearOptions; // Return the array of year options
  };

  const handleRemoveLocation = (index) => {
    const newFields = [...locationFields];
    newFields.splice(index, 1);
    setLocationFields(newFields);
  };

  const handleCancel = () => {
    // Use history.goBack() to navigate back to the previous screen
    navigate("/partnershipActivity");
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const PartnershipDocRef = doc(
          firestoredb,
          "PartnershipActivities",
          documentId,
        );
        const partnershipDocSnapshot = await getDoc(PartnershipDocRef);

        if (partnershipDocSnapshot.exists()) {
          const data = partnershipDocSnapshot.data();
          setSdgName(data.sdgName || "");
          setActivityType(data.activityType || "");
          setProjectName(data.projectName || "");
          setYear(data.year || "");
          setLocationFields(data.locationFields || []);

          console.log("Data fetched successfully:", data);
        } else {
          console.warn(`Document with ID ${documentId} does not exist.`);
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    fetchData();
  }, [documentId]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        // Get the userId from sessionStorage
        const userId = sessionStorage.getItem("userId");
        if (!userId) {
          console.log("User ID not found in sessionStorage");
          return;
        }

        // Get the user document from Firestore
        const userDocRef = doc(firestoredb, "users", userId);
        const userDocSnapshot = await getDoc(userDocRef);
        if (userDocSnapshot.exists()) {
          // Extract the user data from the user document
          const userData = userDocSnapshot.data();
          setUser(userData);
        } else {
          console.log("User not found");
        }
      } catch (error) {
        console.error("Error fetching user data:", error.message);
      }
    };

    fetchUser();
  }, []);
  const handleUpdate = async (documentId, updateData) => {
    try {
      const docRef = doc(firestoredb, "PartnershipActivities", documentId);
      await updateDoc(docRef, updateData);
      console.log("Document updated successfully.");
      localStorage.setItem("alertSeverity", "success");
      localStorage.setItem("alertMessage", "Activity updated successfully.");
      localStorage.setItem("alertopen", "true");
    } catch (error) {
      console.error("Error updating document:", error);
      localStorage.setItem("alertSeverity", "error");
      localStorage.setItem("alertMessage", "Updating activity failed.");
      localStorage.setItem("alertopen", "false");
    }
    navigate("/partnershipActivity");
  };

  const handleButtonClick = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleDelete = async () => {
    try {
      const PartnershipDocRef = doc(
        firestoredb,
        "PartnershipActivities",
        documentId,
      );
      await deleteDoc(PartnershipDocRef);
      handleCloseModal();
      navigate("/partnershipActivity");
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  return (
    <Box
      backgroundColor="white"
      padding="10px"
      margin="10px"
      borderRadius="15px"
    >
      <form
        onSubmit={(e) => {
          e.preventDefault(); // Prevent default form submission behavior
          const updateData = {
            // Create an object containing updated data
            sdgName,
            activityType,
            projectName,
            year,
            locationFields,
          };
          handleUpdate(documentId, updateData); // Call handleUpdate with documentId and updateData
        }}
      >
        <Typography variant="h4" fontWeight="bold" margin="5px" pb="20px">
          Partnership Activity Profile
        </Typography>

        <Grid container spacing={2}>
          {/* Other form fields */}

          <Grid item xs={activityType !== "Santa Scholars" ? 3 : 4}>
            <FormControl fullWidth>
              <InputLabel>SDG Name</InputLabel>
              <Select
                required
                value={sdgName}
                label="SDG Name"
                MenuProps={{
                  MenuListProps: {
                    style: {
                      maxHeight: 200, // Set the maximum height of the dropdown
                    },
                  },
                }}
                onChange={(e) => setSdgName(e.target.value)}
              >
                <MenuItem value="1"> 1. No Poverty</MenuItem>
                <MenuItem value="2"> 2. Zero Hunger</MenuItem>
                <MenuItem value="3"> 3. Good Health and Well-being</MenuItem>
                <MenuItem value="4"> 4. Quality Education</MenuItem>
                <MenuItem value="5"> 5. Gender Equality</MenuItem>
                <MenuItem value="6"> 6. Clean Water and Sanitation</MenuItem>
                <MenuItem value="7"> 7. Affordable and Clean Energy</MenuItem>
                <MenuItem value="8">
                  {" "}
                  8. Decent Work and Economic Growth
                </MenuItem>
                <MenuItem value="9">
                  {" "}
                  9. Industry, Innovation and Infrastructure
                </MenuItem>
                <MenuItem value="10">10. Reduced Inequalities</MenuItem>
                <MenuItem value="11">
                  11. Sustainable Cities and Communities
                </MenuItem>
                <MenuItem value="12">
                  12. Responsible Consumption and Production
                </MenuItem>
                <MenuItem value="13">13. Climate Action</MenuItem>
                <MenuItem value="14">14. Life Below Water</MenuItem>
                <MenuItem value="15">15. Life on Land</MenuItem>
                <MenuItem value="16">
                  16. Peace, Justice and Strong Institutions
                </MenuItem>
                <MenuItem value="17">17. Partnerships for the Goals</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={activityType !== "Santa Scholars" ? 3 : 4}>
            <FormControl fullWidth>
              <InputLabel>Activity Type</InputLabel>
              <Select
                value={activityType}
                required
                label="Type of Activity"
                onChange={(e) => setActivityType(e.target.value)}
              >
                <MenuItem value="Santa Scholars"> Santa Scholars</MenuItem>
                <MenuItem value="Hapag Saya sa Kapaskuhan">
                  {" "}
                  Hapag Saya sa Kapaskuhan
                </MenuItem>
                <MenuItem value="Feeding Activity"> Feeding Activity</MenuItem>
                <MenuItem value="Other Activity"> Other Activity</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <TextField
              fullWidth
              required
              label="Activity Name"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
            />
          </Grid>

          <Grid item xs={activityType !== "Santa Scholars" ? 3 : 4}>
            <FormControl fullWidth required>
              <InputLabel>Year</InputLabel>
              <Select
                label="Year"
                value={year}
                MenuProps={{
                  MenuListProps: {
                    style: {
                      maxHeight: 200, // Set the maximum height of the dropdown
                    },
                  },
                }}
                onChange={(e) => setYear(e.target.value)}
              >
                {generateYearOptions().map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {locationFields.map((location, index) => (
            <Grid
              container
              spacing={2}
              pt="10px"
              pl="18px"
              pb="5px"
              key={index}
            >
              <Grid
                item
                xs={activityType === "Hapag Saya sa Kapaskuhan" ? 3 : 4}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ width: "100%" }}
                    fullWidth
                    label="Date"
                    value={location.date ? dayjs(location.date) : null}
                    required
                    onChange={(date) => {
                      const dateString = date
                        ? dayjs(date).format("YYYY-MM-DD")
                        : null; // Format the date as a string
                      const newFields = [...locationFields];
                      newFields[index].date = dateString;
                      setLocationFields(newFields);
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid
                item
                xs={activityType === "Hapag Saya sa Kapaskuhan" ? 3 : 4}
              >
                <FormControl fullWidth>
                  <InputLabel>Township</InputLabel>
                  <Select
                    label="Township"
                    value={location.township}
                    required
                    MenuProps={{
                      MenuListProps: {
                        style: {
                          maxHeight: 200, // Set the maximum height of the dropdown
                        },
                      },
                    }}
                    onChange={(e) => {
                      const newFields = [...locationFields];
                      newFields[index].township = e.target.value;
                      setLocationFields(newFields);
                    }}
                  >
                    {/* Add MenuItem components for each  in the dropdown */}
                    <MenuItem value="1"> Alabang West</MenuItem>
                    <MenuItem value="2">Arcovia City</MenuItem>
                    <MenuItem value="3">Arden Botanical Estate</MenuItem>
                    <MenuItem value="4">Baytown Palawan</MenuItem>
                    <MenuItem value="5">Boracay Newcoast</MenuItem>
                    <MenuItem value="6">Capital Town</MenuItem>
                    <MenuItem value="7">Davao Park District</MenuItem>
                    <MenuItem value="8">Eastland Heights </MenuItem>
                    <MenuItem value="9">Eastwood City</MenuItem>
                    <MenuItem value="10">Forbes Town</MenuItem>
                    <MenuItem value="11">Highland City</MenuItem>
                    <MenuItem value="12">Ilo-ilo Business Park</MenuItem>
                    <MenuItem value="13">Lucky Chinatown</MenuItem>
                    <MenuItem value="14">Maple Grove</MenuItem>
                    <MenuItem value="15">Mckinley Hill</MenuItem>
                    <MenuItem value="16">Mckinley West</MenuItem>
                    <MenuItem value="17">Newport City</MenuItem>
                    <MenuItem value="18">Northill Gateway</MenuItem>
                    <MenuItem value="19">Northwin Global City</MenuItem>
                    <MenuItem value="20">Paragua Coastown</MenuItem>
                    <MenuItem value="21">Sherwood Hills</MenuItem>
                    <MenuItem value="22">Southwoods City</MenuItem>
                    <MenuItem value="23">Sta Barbara Heights</MenuItem>
                    <MenuItem value="24">Suntrust Ecotown</MenuItem>
                    <MenuItem value="25">The Hamptons Caliraya</MenuItem>
                    <MenuItem value="26">The Mactan Newtown</MenuItem>
                    <MenuItem value="27">The Upper East</MenuItem>
                    <MenuItem value="28">Twin Lakes</MenuItem>
                    <MenuItem value="29">Uptown Bonifacio</MenuItem>
                    <MenuItem value="30">Westside City</MenuItem>
                    <MenuItem value="31">Winford Resort Estate</MenuItem>
                    <MenuItem value="32">Others</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid
                item
                xs={activityType === "Hapag Saya sa Kapaskuhan" ? 3 : 4}
              >
                {/* Render a TextField for Location if Township is "Others" */}
                {location.township === "4" ||
                location.township === "21" ||
                location.township === "31" ||
                location.township === "32" ? (
                  <TextField
                    fullWidth
                    label="Barangay"
                    value={location.location}
                    required
                    onChange={(e) => {
                      const newFields = [...locationFields];
                      newFields[index].location = e.target.value;
                      setLocationFields(newFields);
                    }}
                  />
                ) : (
                  <FormControl fullWidth>
                    <InputLabel>Barangay</InputLabel>
                    <Select
                      label="Barangay"
                      value={location.location}
                      required
                      MenuProps={{
                        MenuListProps: {
                          style: {
                            maxHeight: 200, // Set the maximum height of the dropdown
                          },
                        },
                      }}
                      onChange={(e) => {
                        const newFields = [...locationFields];
                        newFields[index].location = e.target.value;
                        setLocationFields(newFields);
                      }}
                    >
                      {townshipLocationsMapping[location.township]?.map(
                        (loc, locIndex) => (
                          <MenuItem key={locIndex} value={loc}>
                            {loc}
                          </MenuItem>
                        ),
                      )}
                      <MenuItem value="Others">Others</MenuItem>
                    </Select>
                  </FormControl>
                )}
              </Grid>

              <Grid item xs={activityType !== "Other Activity" ? 3 : 4}>
                <TextField
                  fullWidth
                  label="Number of Beneficiaries"
                  value={location.beneficiaries}
                  required
                  onChange={(e) => {
                    const newFields = [...locationFields];
                    const numericValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                    newFields[index].beneficiaries = numericValue;
                    setLocationFields(newFields);
                  }}
                />
              </Grid>
              {activityType === "Santa Scholars" && (
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    label="Number of Packages Packed and Distributed"
                    value={location.packed}
                    required
                    onChange={(e) => {
                      const newFields = [...locationFields];
                      const numericValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                      newFields[index].packed = numericValue;
                      setLocationFields(newFields);
                    }}
                  />
                </Grid>
              )}
              {activityType === "Hapag Saya sa Kapaskuhan" && (
                <Grid item xs={3}>
                  <Autocomplete
                    freeSolo
                    options={["Children", "Elderly", "Person with Disability"]}
                    inputValue={locationFields[index].typebene} // Use the value from locationFields
                    onInputChange={(event, newValue) => {
                      const newFields = [...locationFields];
                      newFields[index].typebene = newValue;
                      setLocationFields(newFields);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Type of Beneficiaries" />
                    )}
                  />
                </Grid>
              )}
              {(activityType === "Hapag Saya sa Kapaskuhan" ||
                activityType === "Feeding Activity") && (
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    label="Number of Meals Served"
                    value={location.mealsserved}
                    required
                    onChange={(e) => {
                      const newFields = [...locationFields];
                      const numericValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                      newFields[index].mealsserved = numericValue;
                      setLocationFields(newFields);
                    }}
                  />
                </Grid>
              )}

              <Grid item xs={activityType !== "Other Activity" ? 3 : 4}>
                <TextField
                  fullWidth
                  label="Proposed Budget"
                  value={location.budget}
                  required
                  onChange={(e) => {
                    const input = e.target.value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters
                    const newFields = [...locationFields];
                    newFields[index].budget = input;

                    setLocationFields(newFields);
                  }}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  InputProps={{
                    startAdornment: location.budget && !isFocused && (
                      <InputAdornment position="start">
                        <Typography variant="h6" color="textSecondary">
                          P
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={activityType !== "Other Activity" ? 3 : 4}>
                <TextField
                  fullWidth
                  label="Actual Expense"
                  value={location.expenses}
                  required
                  onChange={(e) => {
                    const input = e.target.value.replace(/[^0-9.]/g, "");
                    const newFields = [...locationFields];
                    newFields[index].expenses = input;

                    setLocationFields(newFields);
                  }}
                  onFocus={() => setIsFocused1(true)}
                  onBlur={() => setIsFocused1(false)}
                  InputProps={{
                    startAdornment: location.expenses && !isFocused1 && (
                      <InputAdornment position="start">
                        <Typography variant="h6" color="textSecondary">
                          P
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <Autocomplete
                  options={PartnerMapping}
                  freeSolo
                  onChange={(_, newValue) => {
                    const newFields = [...locationFields];
                    const existingPartners = newFields[index].partners;
                    if (!existingPartners.includes(newValue) && newValue) {
                      newFields[index].partners.push(newValue);
                      setLocationFields(newFields);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Partners"
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                        }
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={8} container alignItems="center">
                {location.partners.map((partner, partnerIndex) => (
                  <Chip
                    key={partnerIndex}
                    label={partner}
                    onDelete={() => {
                      const newFields = [...locationFields];
                      const updatedPartners = newFields[index].partners.filter(
                        (p, i) => i !== partnerIndex,
                      );
                      newFields[index].partners = updatedPartners;
                      setLocationFields(newFields);
                    }}
                    style={{ margin: "4px", fontSize: "16px" }}
                    variant="outlined"
                    color="success"
                  />
                ))}
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Description"
                  value={location.description}
                  onChange={(e) => {
                    const newFields = [...locationFields];
                    newFields[index].description = e.target.value;
                    setLocationFields(newFields);
                  }}
                />
              </Grid>

              <Grid item xs={0.5} display="flex" mb="10px" ml="1px">
                {index === 0 && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddLocation}
                  >
                    <AddIcon style={{ fontWeight: "bold" }} />
                  </Button>
                )}

                {index !== 0 && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleRemoveLocation(index)}
                  >
                    <RemoveIcon style={{ fontWeight: "bold" }} />
                  </Button>
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: "10px",
            marginLeft: "3px",
            marginTop: "5px",
          }}
        >
          <div>
            {user &&
              (user.role === "Owner" || user.role === "Administrator") && (
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{ marginRight: "10px" }}
                >
                  Update
                </Button>
              )}
            <Button variant="outlined" color="primary" onClick={handleCancel}>
              Go Back
            </Button>
          </div>
          {user && user.role === "Owner" && (
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#eb584d",
                color: "#fff",
                "&:hover": { backgroundColor: "#cc180a" },
                mr: "3px",
              }}
              onClick={handleButtonClick}
            >
              <DeleteIcon />
            </Button>
          )}
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-40%, -60%)",
                borderRadius: "5px",
                width: 400,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 2,
              }}
            >
              <Typography
                id="modal-modal-title"
                variant="h4"
                sx={{ fontWeight: "600", marginTop: "10px" }}
              >
                Delete
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                Are you sure you want to delete this activity?
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "right",
                  marginTop: "5px",
                }}
              >
                <Button
                  onClick={handleCloseModal}
                  sx={{
                    border: "1px solid #c2c2c2",
                    textTransform: "none",
                    marginRight: "10px",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleDelete}
                  sx={{
                    textTransform: "none",
                    backgroundColor: "#eb584d",
                    color: "#fff",
                    "&:hover": { backgroundColor: "#cc180a" },
                  }}
                >
                  Yes, Delete
                </Button>
              </div>
            </Box>
          </Modal>
        </div>
      </form>
    </Box>
  );
};

export default PartActform;
